import { getMyPermissions } from './getMyPermissions';
import { getRescueNetCompanyPermissions } from './getRescueNetCompanyPermissions';
import { getMyPermissionsForRescueNetApplicant } from './getMyPermissionsForRescueNetApplicant';
import { getProductPermissions } from './getProductPermissions';

export const permissions = {
  getMyPermissions,
  getRescueNetCompanyPermissions,
  getMyPermissionsForRescueNetApplicant,
  getProductPermissions,
};
