import clone from 'clone';
import { operations } from 'src/constants/operations';
import { getProfileLayoutById } from 'src/store/getters/profiles/getProfileLayoutById';
import { getActiveProfile } from 'src/store/getters/profiles/getActiveProfile';

/**
 * pushes to the profiles.data.layouts[]
 */
export const createProfileLayout = (state, { id, data, status, error }) => {
  let layout = getProfileLayoutById(state)(id);
  // need to push to the store
  if (!layout) {
    layout = {
      id,
      columns: [],
      operations: {
        [operations.create]: {},
        [operations.update]: {},
        [operations.delete]: {},
      },
    };
    getActiveProfile(state)?.layouts.push(layout);
  }

  // when data is provided (should be upon success)
  // add that to the profile
  if (data) {
    layout.id = data.id;
    layout.columns = clone(data.columns);
  }

  // always update status/error
  layout.operations[operations.create] = {
    status,
    error,
  };
};
