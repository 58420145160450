/**
 * Sets the loading status of insurance discovery and
 * saves any errors that occured
 */
export const runInsuranceDiscovery = (state, { error = {}, status }) => {
  state.applicant.insuranceDiscovery.status = status;
  state.applicant.insuranceDiscovery.retrieved = true;
  if (Object.keys(error).length > 0) {
    state.applicant.insuranceDiscovery.error = error;
  }
};
