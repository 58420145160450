import appService from 'src/pl.service';
import { GET_APPLICANT } from 'src/store/actions/types';

/**
 * retrieves the applicant from the API and
 * commits the data to the store using the GET_APPLICANT action type
 */
export const getApplicant = async ({ commit, state }, applicantId) =>
  commit(
    GET_APPLICANT,
    await appService.getApplicant(applicantId ?? state.applicant.id)
  );
