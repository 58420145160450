<template>
  <b-container fluid>
    <!-- User Interface controls -->
    <b-row>
      <b-col>
        <b-form-group label-cols-sm="2" label="Filter" class="mb-0">
          <b-input-group>
            <b-form-input
              v-model="filter"
              placeholder="Type to Search"
            ></b-form-input>
            <b-input-group-append>
              <b-button variant="primary" :disabled="!filter" @click="filter = ''"
                >Clear</b-button
              >
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label-cols-sm="2" label="Per page" class="mb-0">
          <b-form-select
            v-model="perPage"
            :options="pageOptions"
          ></b-form-select>
        </b-form-group>
      </b-col>
      <b-col v-if="isRescueNet">
        <b-form-group label-cols-sm="2" label="" class="mb-0">
          <b-form-checkbox
            v-model="includeRescueNetPayors"
            name="checkbox-includeRescueNetPayors"
          >
            Include RescueNet Payors
          </b-form-checkbox>
        </b-form-group>
      </b-col>
    </b-row>
    <br />

    <!-- Main table element -->
    <b-table
      show-empty
      stacked="md"
      :items="items"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      @filtered="onFiltered"
    >
      <template slot="isRescueNet" slot-scope="row">
        {{ row.value ? 'Yes' : 'No' }}
      </template>

      <template #cell(actions)="row">
        <!-- <b-button size="sm" @click="info(row.item, row.index, $event.target)" class="mr-1">Info modal</b-button> -->
        <!-- {{ row.detailsShowing ? 'Hide' : 'Show' }} Details -->
        <b-button size="sm" variant="primary" @click="row.toggleDetails">Remap</b-button>
      </template>

      <template slot="row-details" slot-scope="row">
        <b-card>
          <b-container class="bv-example-row">
            <b-row>
              <b-col>Master Payor Name</b-col>
              <b-col cols="8">
                <label-autocomplete
                  label="Map Payor:"
                  :payor-data="payorData"
                  v-model="payorId"
                  @selected="selected"
                >
                </label-autocomplete>
              </b-col>
              <b-col>
                <b-button
                  size="sm"
                  @click="save(row.item, row.index, $event.target)"
                  >Save</b-button
                >
              </b-col>
            </b-row>
          </b-container>
        </b-card>
      </template>
    </b-table>

    <b-row>
      <b-col md="6" class="my-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          class="my-0"
        ></b-pagination>
      </b-col>
    </b-row>

    <!-- Info modal -->
    <b-modal
      :id="infoModal.id"
      :title="infoModal.title"
      ok-only
      @hide="resetInfoModal"
    >
      <pre>{{ infoModal.content }}</pre>
    </b-modal>
  </b-container>
</template>

<script>
import appService from 'src/pl.service';
import labelAutoComplete from 'src/components/controls/LabelAndAutoCompleteControl';

export default {
  props: {
    isRescueNet: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    'label-autocomplete': labelAutoComplete,
  },
  data() {
    return {
      includeRescueNetPayors: false,
      rescueNetPayors: null,
      payorData: null,
      payorId: null,
      payorMap: {},
      text: [],
      items: null,
      fields: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15],
      sortBy: null,
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
    };
  },
  watch: {
    includeRescueNetPayors: function(newVal) {
      if (newVal === true) {
        this.fields = this.getFieldList(true);
        this.sortBy = 'isRescueNet';
        this.sortDesc = true;
      } else {
        this.fields = this.getFieldList(false);
      }
      this.loadPayorList();
    },
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key };
        });
    },
  },
  async mounted() {
    console.log('mounted');
    this.fields = this.getFieldList(false);
    if (this.isRescueNet) {
      await this.loadRescueNetPayors();
    }

    // Set the initial number of items
    var allPayors = await appService.getAllPayors();
    var payors = [];
    for (var i = 0; i < allPayors.length; i++) {
      this.payorMap[allPayors[i].payorId] = allPayors[i].payorName;
      var json = {
        payorName: allPayors[i].payorName,
        id: allPayors[i].payorId,
      };
      payors[i] = json;
    }
    this.payorData = payors;

    var plCompanyPayors = await appService.getAllCompanyPayors();
    for (var j = 0; j < plCompanyPayors.length; j++) {
      plCompanyPayors[j].masterPayorName = this.payorMap[
        plCompanyPayors[j].payorId
      ];
    }
    this.items = plCompanyPayors;

    this.loadPayorList();
  },
  methods: {
    loadPayorList() {
      console.log('loadPayorList', this.includeRescueNetPayors);
      if (this.includeRescueNetPayors === true) {
        for (var j = 0; j < this.rescueNetPayors.length; j++) {
          var existsInPl = false;
          for (var i = 0; i < this.items.length; i++) {
            if (this.items[i].companyPayorName === this.rescueNetPayors[j]) {
              this.items[i].isRescueNet = true;
              existsInPl = true;
              break;
            }
          }
          if (!existsInPl) {
            var rnPayor = {};
            rnPayor.companyPayorName = this.rescueNetPayors[j];
            rnPayor.payorCode = this.rescueNetPayors[j];
            rnPayor.isRescueNet = true;
            rnPayor._rowVariant = 'danger';
            this.items.push(rnPayor);
          }
        }
      } else {
        this.items = this.items.filter(function(value) {
          return (
            !value.isRescueNet || (value.isRescueNet && value.masterPayorName)
          );
        });
      }

      this.totalRows = this.items.length;
      console.log('1', this.totalRows);
    },
    getFieldList(includeRescueNet) {
      if (includeRescueNet) {
        return [
          {
            key: 'companyPayorName',
            label: 'Payor',
            sortable: true,
            sortDirection: 'desc',
          },
          { key: 'payorCode', label: 'Code', sortable: true },
          { key: 'masterPayorName', label: 'Master Payor', sortable: true },
          { key: 'isRescueNet', label: 'RescueNet Payor', sortable: true },
          { key: 'actions', label: 'Actions' },
        ];
      } else {
        return [
          {
            key: 'companyPayorName',
            label: 'Payor',
            sortable: true,
            sortDirection: 'desc',
          },
          { key: 'payorCode', label: 'Code', sortable: true },
          { key: 'masterPayorName', label: 'Master Payor', sortable: true },
          { key: 'actions', label: 'Actions' },
        ];
      }
    },
    async loadRescueNetPayors() {
      if (!this.rescueNetPayors) {
        this.rescueNetPayors = ['RescueNet Payor1', 'RescueNet Payor2', 'AARP'];

        try {
          console.log('loadRescueNetPayors');
          var dataFromPLRNApp = null;
          try {
            // eslint-disable-next-line
            await CefSharp.BindObjectAsync(
              'cefSharpBoundAsyncPayorList',
              'bound'
            );
            console.log('cefSharpBoundAsyncPayorList.bound');
            // eslint-disable-next-line
            dataFromPLRNApp = await cefSharpBoundAsyncPayorList.getPayorList();
          } catch (error) {
            console.log(
              'CefSharpLaunch Error: (most likely not running within RN->PL app)',
              error
            );
          }
          if (dataFromPLRNApp) {
            var payorList = JSON.parse(dataFromPLRNApp);
            console.log('loadRescueNetPayors', payorList);
            this.rescueNetPayors = payorList;
          }
        } catch (error) {
          console.log('ALSTriggersModal.mounted', error);
        }
      }
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit('bv::show::modal', this.infoModal.id, button);
    },
    async save(item, index) {
      console.log('save', item, index, this.payorId);
      if (item._rowVariant === 'danger') {
        var addCompanyPayorSuccess = await appService.addCompanyPayor(
          this.payorId,
          item.companyPayorName
        );
        if (addCompanyPayorSuccess === true) {
          item.masterPayorName = this.payorMap[this.payorId];
          item._showDetails = false;
          item._rowVariant = null;
        }
      } else {
        var updateCompanyPayorsMasterPayorIdReturnStatusCode = await appService.updateCompanyPayorMasterPayorId(
          item.companyPayorId,
          this.payorId
        );
        if (updateCompanyPayorsMasterPayorIdReturnStatusCode === 200) {
          item.masterPayorName = this.payorMap[this.payorId];
          item._showDetails = false;
        }
      }
    },
    resetInfoModal() {
      this.infoModal.title = '';
      this.infoModal.content = '';
    },
    selected(data) {
      console.log('selected', data);
      this.payorId = data.id;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    // loadData () {
    //   console.log('loadData')
    // }
  },
};
</script>
