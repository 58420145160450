import { readProfiles } from './readProfiles';
import { updateProfile } from './updateProfile';
import { createProfile } from './createProfile';
import { resetProfile } from './resetProfile';
import { readActiveProfile } from './readActiveProfile';
import { updateActiveProfile } from './updateActiveProfile';
import { widgets } from './widgets/index';
import { layouts } from './layouts/index';
import { expandOptions } from './expandOptions/index';

export const profiles = {
  readProfiles,
  updateProfile,
  createProfile,
  resetProfile,
  readActiveProfile,
  updateActiveProfile,
  ...widgets,
  ...layouts,
  ...expandOptions,
};
