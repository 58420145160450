import { insuranceTypes } from 'src/constants/insurance';
import { statuses } from 'src/constants/statuses';

/**
 * mutates the applicant.data.insurance[index].verifiedStatus property with
 * the status given
 * TODO: Move the gurantor mutation out of the applicant
 *  will need to wait for the gurantor code to be merged
 */
export const validateInsurance = (
  state,
  { status, index, type, error = {} }
) => {
  state.applicant.data.insuranceStatus = status;
  if (Object.keys(error).length > 0) {
    state.applicant.validateInsurance.error = error;
  }
  if (status !== statuses.pending) {
    state.applicant.validateInsurance.verifyInsurance = true;
    if (insuranceTypes.applicant == type) {
      state.applicant.data.insurance[index].verifiedStatus = status;
    } else if (insuranceTypes.guarantor == type) {
      state.guarantor.data.insurance[index].verifiedStatus = status;
    } else if (insuranceTypes.pcr == type) {
      state.applicant.epcrJsonData.insurance_pcrpayors[
        index
      ].verifiedStatus = status;
    }
  }
};
