import Vue from 'vue';
import clone from 'clone';
export const setBillingNextOfKinValidatedAddress = (
  state,
  { validatedAddress, status, error }
) => {
  if (validatedAddress && state.applicant.data) {
    Vue.set(
      state.applicant.data,
      'nextOfKinValidatedAddress',
      clone(validatedAddress)
    );
    if (state.applicant.data.nextOfKinValidatedAddress) {
      state.applicant.data.nextOfKinValidatedAddress.status = status;
      state.applicant.data.nextOfKinValidatedAddress.error = error;
    }
  }
};
