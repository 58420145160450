import { applicant } from './applicant/index';
import { guarantor } from './guarantor/index';
import { widgets } from './widgets/index';
import { permissions } from './permissions/index';
import { profiles } from './profiles/index';
import { alsConfig } from './alsConfig/index';

export const mutations = {
  ...applicant,
  ...guarantor,
  ...widgets,
  ...permissions,
  ...profiles,
  ...alsConfig,
};
