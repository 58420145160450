/**
 * returns the permissions depending on the applicant visits.
 * it returns RescueNetCompanyPermissions, MyPermissions or an empty array
 */
export const getPermissions = state => {
  if (state.applicant.visits.retrieved) {
    if (state.applicant.visits.data.length) {
      return state.permissions.rna;
    }
    return state.permissions.my;
  }
  return [];
};
