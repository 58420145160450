import { cellTypes } from 'src/constants/grid';

/**
 * computes if the normal cells have been mounted or not
 * @returns {boolean}
 */
export const normalCellsMounted = ({ columnsMounted, columns, widgets }) => {
  if (columnsMounted) {
    const mountedCells = columns.reduce(
      (colAcc, column) =>
        colAcc +
        column.cells
          .filter(cell => cell.type === cellTypes.normal.type)
          .reduce(
            (cellAcc, cell) => (cell.componentInstance ? ++cellAcc : cellAcc),
            0
          ),
      0
    );
    return mountedCells >= widgets.length;
  }
  return false;
};
