import Vue from 'vue';
import clone from 'clone';
export const setPcrHomeValidatedAddress = (
  state,
  { homeValidatedAddress, status, error }
) => {
  if (homeValidatedAddress && state.applicant.epcrJsonData) {
    Vue.set(
      state.applicant.epcrJsonData,
      'haddrValidatedAddress',
      clone(homeValidatedAddress)
    );
    if (state.applicant.epcrJsonData.haddrValidatedAddress) {
      state.applicant.epcrJsonData.haddrValidatedAddress.status = status;
      state.applicant.epcrJsonData.haddrValidatedAddress.error = error;
    }
  }
};
