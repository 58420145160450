
/**
 * mutates the location for the map modal
 */
export const setMapLocation = (state, {latitude, longitude, address1, address2, 
  city, addressState, zip}) => {
      state.overviewMapCoordinates = {
        lat: latitude,
        lng: longitude,
      };
      state.overviewMapDescription =
        address1 +
        ' ' + address2
        ' ' + city +
        ', ' +
        addressState +
        ' ' +
        zip;
    }
