import clone from 'clone';
import { operations } from 'src/constants/operations';
import { getProfileLayoutById } from 'src/store/getters/profiles/getProfileLayoutById';

/**
 * update the profiles.data.layouts[]
 */
export const updateProfileLayout = (state, { id, data, status, error }) => {
  const layout = getProfileLayoutById(state)(id);
  if (layout) {
    if (data) {
      layout.columns = clone(data.columns);
    }

    // always update status/error
    layout.operations[operations.update] = {
      status,
      error,
    };
  }
};
