import appService from 'src/pl.service';
import { FLAG_ELIGIBILITY_INCORRECT } from 'src/store/actions/types';
import { statuses } from 'src/constants/statuses';

/**
 * sets the incorrectHit in the database and commits to the state
 */
export const flagEligibilityIncorrect = async (
  { commit },
  { eligibilityId, incorrectHit }
) => {
  commit(FLAG_ELIGIBILITY_INCORRECT, {
    status: statuses.pending,
  });
  try {
    await appService.flagCoverageAsIncorrect(eligibilityId, !incorrectHit);
    commit(FLAG_ELIGIBILITY_INCORRECT, {
      data: !incorrectHit,
      eligibilityId,
      status: statuses.success,
    });
  } catch (error) {
    commit(FLAG_ELIGIBILITY_INCORRECT, {
      status: statuses.failure,
    });
  }
};
