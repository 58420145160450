import { cellTypes } from 'src/constants/grid';
import { createCell } from 'src/components/FullPatientData/PatientWidgetBody/Grid/utils/createCell';
import { removeCellById } from 'src/components/FullPatientData/PatientWidgetBody/Grid/utils/removeCellById';
import { removeMirrors } from 'src/components/FullPatientData/PatientWidgetBody/Grid/utils/removeMirrors';

/**
 * parses the widgets and places them in columns, by creating cells.
 * it synchronizes all the widgets/cells in one go.
 *
 * this gets invoked when:
 *  - all the columns have been mounted
 *  - columns get reduced
 *  - widgets get added or removed
 */
export const syncWidgets = ({ columnsMounted, widgets, columns }) => {
  // in case this gets invoked before the columns have been mounted, exit
  if (!columnsMounted) {
    return;
  }

  // first, remove from the columns any cells/widgets that are not present in the widgets list
  // make a list of the cell ids we need to remove
  columns.forEach(column =>
    column.cells.forEach(cell => {
      if (
        cell.type !== cellTypes.mirror.type &&
        !widgets.find(widget => widget.meta.type === cell.meta.type)
      ) {
        removeCellById({ columns, cellId: cell.id });
        removeMirrors({ columns, cellId: cell.id });
      }
    })
  );

  // last, add any to the columns any cells/widgets that are not present in the columns list
  let lastColumnIndex = 0;
  widgets.forEach(widget => {
    // look if this widget has been added to a column yet
    // by comparing column.cell.meta.type with widget.meta.type
    const widgetColumnIndex = columns.findIndex(column =>
      column.cells.find(cell => cell.meta.type === widget.meta.type)
    );
    if (widgetColumnIndex === -1) {
      // we need to add it
      // find the column index to add the cell+widget to
      let widgetColumnIndex =
        lastColumnIndex < columns.length - 1 &&
        columns[lastColumnIndex].componentInstance.wrapperEl.clientHeight >=
          window.innerHeight
          ? lastColumnIndex + 1
          : lastColumnIndex;
      columns[widgetColumnIndex].cells.push(
        createCell({ type: cellTypes.normal.type, widget })
      );
    } else {
      // already exists, keep the column index
      lastColumnIndex = widgetColumnIndex;
    }
  });
};
