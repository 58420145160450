import clone from 'clone';
import appService from 'src/pl.service';
import { UPDATE_PROFILE } from 'src/store/actions/types';
import { getActiveProfile } from 'src/store/getters/profiles/getActiveProfile';
import { statuses } from 'src/constants/statuses';
import { cellTypes } from 'src/constants/grid';

/**
 * deletes the widget (using its type) from the active profile
 */
export const deleteProfileWidget = async ({ commit, state }, { type }) => {
  // find the profile we need to update in the state
  const uProfile = clone(getActiveProfile(state));
  if (uProfile) {
    // remove the widget from the profile
    uProfile.widgets = uProfile.widgets.filter(
      widget => widget.meta.type !== type
    );

    // remove the widget from the layouts as well
    uProfile.layouts = uProfile.layouts.map(layout => ({
      ...layout,
      columns: layout.columns.map(column => {
        // find the cell to remove
        const cellIndexToRemove = column.cells.findIndex(
          cell => cell.meta.type === type
        );
        if (cellIndexToRemove !== -1) {
          // keep its cell id so that we can remove its mirrors
          const cellId = column.cells[cellIndexToRemove].id;

          // now remove the cell and its mirrors
          column.cells = column.cells.filter(
            cell =>
              cell.id !== cellId &&
              (cell.type !== cellTypes.mirror.type ||
                cell.meta.sourceId !== cellId)
          );
        }
        return column;
      }),
    }));

    try {
      // update
      commit(UPDATE_PROFILE, {
        id: uProfile.id,
        status: statuses.pending,
      });
      await appService.updateProfile(uProfile);
      commit(UPDATE_PROFILE, {
        id: uProfile.id,
        data: uProfile,
        status: statuses.success,
      });
    } catch (error) {
      commit(UPDATE_PROFILE, {
        id: uProfile.id,
        status: statuses.failure,
        error,
      });
    }
  }
};
