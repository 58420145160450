import { layouts } from 'src/constants/grid';
import { createColumn } from 'src/components/FullPatientData/PatientWidgetBody/Grid/utils/createColumn';

/**
 * checks if the number of columns needs to change and it yes,
 * it adds/removes columns accordingly.
 *
 * this should get invoked when the number of widgets changes (ie. user hid/showed one)
 * but also when the window width changes...
 *
 * @returns {boolean} true if the layout changed
 */
export const checkLayoutColumns = ({ columns, handleColumnResizeObserver }) => {
  // find and set, the number of columns we should use for this window width
  let columnCount = 0;
  for (const layoutEntry of Object.entries(layouts)) {
    if (window.innerWidth < layoutEntry[1].maxWidth) {
      columnCount = layoutEntry[1].columns;
      break;
    }
  }

  // only if is a need for more or less columns
  // than what currently exists
  if (columnCount !== columns.length) {
    if (columns.length > columnCount) {
      // we need to remove column(s)
      columns.splice(columnCount);
    } else {
      // we need to add a column(s)
      for (let i = 0; i < columnCount; i++) {
        if (columns.length < i + 1) {
          columns.push(
            createColumn({
              id: i,
              handleResizeObserver: handleColumnResizeObserver,
            })
          );
        }
      }
    }
    // the layout just changed
    return true;
  }
  // no layout change
  return false;
};
