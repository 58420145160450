import clone from 'clone';

/**
 * mutates the widgetView property with the data given
 */
export const getWidgetViews = (state, data) => {
  state.widgetView.id = data.id;
  state.widgetView.name = data.name;
  state.widgetView.order = data.order;
  state.widgetView.widgets = clone(data.widgets);
};
