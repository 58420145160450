import { getApplicant } from './getApplicant';
import { getApplicantVisits } from './getApplicantVisits';
import { setApplicantId } from './setApplicantId';
import { setMapLocation } from './setMapLocation';
import { setApplicantCredit } from './setApplicantCredit';
import { getPayorEligibilities } from './getPayorEligibilities';
import { getDemographic } from './getDemographic';
import { getGuarantor } from './getGuarantor';
import { flagDemographicsIncorrect } from './flagDemographicsIncorrect';
import { flagDemographicsIncorrectGuarantor } from './flagDemographicsIncorrectGuarantor';
import { runDemographicsTest } from './runDemographicsTest';
import { setApplicantHomeValidatedAddress } from './addresses/setApplicantHomeValidatedAddress';
import { setGuarantorHomeValidatedAddress } from './addresses/setGuarantorHomeValidatedAddress';
import { setPcrHomeValidatedAddress } from './addresses/setPcrHomeValidatedAddress';
import { setPcrPaddrValidatedAddress } from './addresses/setPcrPaddrValidatedAddress';
import { setPcrNokAddrValidatedAddress } from './addresses/setPcrNokAddrValidatedAddress';
import { setBillingMailingValidatedAddress } from './addresses/setBillingMailingValidatedAddress';
import { setBillingNextOfKinValidatedAddress } from './addresses/setBillingNextOfKinValidatedAddress';
import { setPayorIsMapped } from './setPayorIsMapped';
import { getAllPayors } from './getAllPayors';
import { validateInsurance } from './validateInsurance';
import { getServiceTypes } from './getServiceTypes';
import { runInsuranceDiscovery } from './runInsuranceDiscovery';
import { getHistoryInitialData } from './getHistoryInitialData';
import { getHistoryEligibility } from './getHistoryEligibility';
import { getHistoryPdf } from './getHistoryPdf';
import { setTripDistance } from './setTripDistance';
import { getInsuranceFinder } from './getInsuranceFinder';
import { flagEligibilityIncorrect } from './flagEligibilityIncorrect';

export const applicant = {
  getApplicant,
  getApplicantVisits,
  setApplicantId,
  getPayorEligibilities,
  setApplicantCredit,
  getDemographic,
  setMapLocation,
  getGuarantor,
  flagDemographicsIncorrect,
  flagDemographicsIncorrectGuarantor,
  runDemographicsTest,
  setApplicantHomeValidatedAddress,
  setGuarantorHomeValidatedAddress,
  setPcrHomeValidatedAddress,
  setPcrPaddrValidatedAddress,
  setPcrNokAddrValidatedAddress,
  setBillingMailingValidatedAddress,
  setBillingNextOfKinValidatedAddress,
  setPayorIsMapped,
  getAllPayors,
  validateInsurance,
  getServiceTypes,
  runInsuranceDiscovery,
  getHistoryInitialData,
  getHistoryEligibility,
  getHistoryPdf,
  setTripDistance,
  getInsuranceFinder,
  flagEligibilityIncorrect,
};
