import appService from 'src/pl.service';
import { GET_ALS_CONFIG } from 'src/store/actions/types';

export const getAlsConfig = async ({ commit }) => {
  commit(GET_ALS_CONFIG, {
    loading: true,
  });

  const existingTriggers = await appService.getALSTriggers();
  const alsColors = await appService.getALSColors();
  commit(GET_ALS_CONFIG, {
    triggers: existingTriggers,
    colors: alsColors,
    loading: false,
    retrieved: true
  });
};
