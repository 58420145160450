import { createCell } from 'src/components/FullPatientData/PatientWidgetBody/Grid/utils/createCell';
import { getWidgetByType } from 'src/utils/getWidgetByType';

/**
 * attempts to load the user layout by creating and applying cells
 * to the existing columns. (if there is one available).
 */
export function loadLayout() {
  // check if there is a user defined layout for the current layout
  const layout = this.layouts.find(
    layout => layout.columns.length === this.columns.length
  );

  if (layout) {
    // mark as user defined layout
    // we purposely do not want to set it to false
    // in case there is no layout and only set it to true.
    // that way, if the user resizes the window a bigger layout and
    // there is no user defined layout for that size, it will still
    // be userDefinedLayout:true and NOT cause a spreadCells...
    this.userDefinedLayout = true;

    this.columns.forEach((column, colIndex) => {
      if (layout.columns[colIndex]) {
        column.cells = layout.columns[colIndex].cells.map(cell =>
          createCell({
            id: cell.id,
            type: cell.type,
            meta: cell.meta,
            widget: {
              colSpan: cell.colSpan,
              component: getWidgetByType(cell.meta.type)?.component,
            },
          })
        );
      }
    });
  }
}
