import { getActiveProfile } from 'src/store/getters/profiles/getActiveProfile';

/**
 * finds the profile layout in the active profile, using the columns length,
 * since there can only be one layout per column length.
 *
 * returns the profile layout or undefined, if there are no profiles/layouts
 */
export const getProfileLayoutByColumnsLength = state => columnsLength =>
  getActiveProfile(state)?.layouts.find(
    layout => layout.columns.length === columnsLength
  );
