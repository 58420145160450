import clone from 'clone';
import { statuses } from 'src/constants/statuses';
import { RESET_PROFILE } from 'src/store/actions/types';
import { getActiveProfile } from 'src/store/getters/profiles/getActiveProfile';
import appService from 'src/pl.service';
import { getDefaultWidgets } from 'src/utils/getDefaultWidgets';
import { expandOptions } from 'src/constants/expandOptions';

/**
 * resets the active profile's layouts and widgets
 */
export const resetProfile = async ({ commit, state }) => {
  // find the profile we need to reset in the state
  const uProfile = clone(getActiveProfile(state));
  if (uProfile) {
    uProfile.layouts = [];
    uProfile.widgets = getDefaultWidgets(state.widgetView.name);
    uProfile.expandOptions = expandOptions;
    try {
      // update
      commit(RESET_PROFILE, {
        status: statuses.pending,
      });
      await appService.updateProfile(uProfile);
      commit(RESET_PROFILE, {
        data: uProfile,
        status: statuses.success,
      });
    } catch (error) {
      commit(RESET_PROFILE, {
        status: statuses.failure,
        error,
      });
    }
  }
};
