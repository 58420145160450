import { insuranceTypes } from 'src/constants/insurance';
/**
 * mutates the applicant.data.insurance[index].isMapped property with
 * the data (true/false) given
 * TODO: Move the gurantor mutation out of the applicant
 *  will need to wait for the gurantor code to be merged
 */
export const setPayorIsMapped = (state, { index, type, data, status }) => {
  if (insuranceTypes.applicant == type) {
    state.applicant.data.insurance[index].isMapped = data;
    state.applicant.data.insurance[index].mappedStatus = status;
  } else if (insuranceTypes.guarantor == type) {
    state.guarantor.data.insurance[index].isMapped = data;
    state.guarantor.data.insurance[index].mappedStatus = status;
  } else if (insuranceTypes.pcr == type) {
    state.applicant.epcrJsonData.insurance_pcrpayors[index].isMapped = data;
    state.applicant.epcrJsonData.insurance_pcrpayors[
      index
    ].mappedStatus = status;
  }
};
