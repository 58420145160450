/**
 * Updates the given eligibilities incorrectHit attribute
 * @param {*} state
 * @param {*} param1
 */
export const flagEligibilityIncorrect = (
  state,
  { status, eligibilityId, data = undefined }
) => {
  if (state.applicant.eligibilities.data?.length) {
    var eligibility = state.applicant.eligibilities.data.find(
      item => item.eligibilityId === eligibilityId
    );

    if (eligibility && data !== undefined) {
      //The status is not currently getting set
      //This mutation is also not showing up in the vue tab
      //Take a look at this later
      eligibility.flagStatus = status;
      eligibility.incorrectHit = data;
    }
  }
};
