import { v4 as uuidv4 } from 'uuid';
import { cellTypes } from 'src/constants/grid';

/**
 * creates and returns a new cell object for the grid
 */
export const createCell = ({ id, type, widget, meta }) => {
  const cell = {
    id: id ?? uuidv4(), // generate a unique id because we can have mirror cells, etc.
    type: type, // cell type. normal/mirror
    component: widget?.component ?? cellTypes[type].component,
    componentInstance: null, // the instance of the rendered component within
    componentIsLoaded: cellTypes[type].componentIsLoaded, // if true, the component contained within the cell, must be fully loaded and rendered
    colSpan: widget?.colSpan ?? cellTypes[type].colSpan,
    meta: widget?.meta ?? meta,
  };

  // bind methods
  cell.loadedCallback = function(loaded) {
    this.componentIsLoaded = loaded;
  }.bind(cell);
  cell.mountedCallback = function(componentInstance) {
    this.componentInstance = componentInstance;
  }.bind(cell);
  cell.unmountedCallback = function() {
    this.componentInstance = null;
  }.bind(cell);

  return cell;
};
