<template>
  <div style="height: 100vh; overflow-y: scroll">
    <b-container fluid>
      <b-row>
        <b-col lg="6" class="my-1">
          <b-form-group
            label="Company:"
            label-cols-sm="2"
            label-align-sm="right"
          >
            <b-form-select
              v-model="selectedBillingCompanyIds"
              :options="billingCompanies"
              value-field="billingCompanyId"
              text-field="companyName"
              multiple
              :select-size="10"
            ></b-form-select>
          </b-form-group>
        </b-col>
        <b-col lg="6" class="my-1">
          <b-form-group
            label="Date Range:"
            label-cols-sm="2"
            label-align-sm="right"
          >
            <b-form-datepicker
              id="start-datepicker"
              v-model="startDate"
              class="mb-2"
            ></b-form-datepicker>
            <b-form-datepicker
              id="end-datepicker"
              v-model="endDate"
              class="mb-2"
            ></b-form-datepicker>
          </b-form-group>
        </b-col>
        <b-col lg="12" class="my-1">
          <b-button variant="success" @click="getData" :disabled="loadingData">
            {{ loadButton }}
            <b-spinner small v-if="loadingData"></b-spinner>
          </b-button>
        </b-col>
        <b-col lg="6" class="my-1">
          <b-form-group
            label="Date Range:"
            label-cols-sm="2"
            label-align-sm="right"
          >
            <b-form-datepicker
              id="start-datepicker"
              v-model="startDate"
              class="mb-2"
            ></b-form-datepicker>
            <b-form-datepicker
              id="end-datepicker"
              v-model="endDate"
              class="mb-2"
            ></b-form-datepicker>
          </b-form-group>
        </b-col>
      </b-row>
      <br />

      <b-tabs content-class="mt-3">
        <b-tab title="Reconciliation Details" active>
          <b-table
            :items="invoiceGroupItems"
            :fields="invoiceGroupFields"
            striped
            responsive="sm"
          >
            <template #cell(show_details)="row">
              <b-button
                size="sm"
                @click="sjktoggleDetails(row.item)"
                class="mr-2"
              >
                {{ row.detailsShowing ? 'Hide' : 'Show' }} Details
              </b-button>
            </template>

            <template #row-details="row">
              <b-card>
                <b-table
                  :items="invoiceItems"
                  :fields="invoiceFields"
                  striped
                  responsive="sm"
                  head-row-variant="success"
                >
                </b-table>
                <b-table
                  :items="request2ProductGroupItems"
                  :fields="request2ProductGroupFields"
                  :busy="isRequest2ProductGroupItemsBusy"
                  striped
                  responsive="sm"
                  head-row-variant="success"
                >
                  <template #table-busy>
                    <div class="text-center text-danger my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong>Loading...</strong>
                    </div>
                  </template>
                </b-table>
                <b-table
                  :items="discrepancyItems"
                  :fields="discrepancyFields"
                  :busy="isDiscrepancyBusy"
                  striped
                  responsive="sm"
                  head-row-variant="success"
                >
                  <template #table-busy>
                    <div class="text-center text-danger my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong>Loading...</strong>
                    </div>
                  </template>
                  <!-- <template #cell(requestIds)="data">
                    <b-container class="bv-example-row">
                      <b-row
                        v-for="(abc, index) in data.value"
                        :key="`${index}`"
                      >
                        <b-col>
                          <b-link
                            :href="
                              `https://portal.payorlogic.com/Applicant/ApplicantDetail?applicantId=${abc.applicantId}&amp;companyId=${abc.companyId}`
                            "
                            >View In Portal</b-link
                          >{{ abc }}
                        </b-col>
                      </b-row>
                    </b-container>
                  </template> -->
                </b-table>
                <b-button size="sm" @click="row.toggleDetails"
                  >Hide Details</b-button
                >
              </b-card>
            </template>
          </b-table>
          <b-button variant="success" @click="getData" :disabled="loadingData">
            {{ loadButton }}
            <b-spinner small v-if="loadingData"></b-spinner>
          </b-button>
        </b-tab>
        <b-tab title="Eligibity Aggregate">
          <eligibility-aggregate
            :companyIds="selectedCompanyIds"
            :start="startDate"
            :end="endDate"
          ></eligibility-aggregate>
        </b-tab>
        <b-tab title="Demographic Aggregate">
          <demographic-aggregate
            :companyIds="selectedCompanyIds"
            :start="startDate"
            :end="endDate"
          ></demographic-aggregate>
        </b-tab>
      </b-tabs>

      <div class="mt-3">
        Selected Billing Company Id(s):
        <strong>{{ selectedBillingCompanyIds }}</strong>
      </div>
      <div class="mt-3">
        Selected Company Id(s): <strong>{{ selectedCompanyIds }}</strong>
      </div>
    </b-container>
  </div>
</template>

<script>
import appService from 'src/billing.service';
import EligibilityAggregate from 'src/components/EligibilityAggregate';
import DemographicAggregate from 'src/components/DemographicAggregate';

export default {
  components: {
    'eligibility-aggregate': EligibilityAggregate,
    'demographic-aggregate': DemographicAggregate,
  },
  computed: {
    selectedCompanyIds: function() {
      var list = [];
      for (var i = 0; i < this.selectedBillingCompanyIds.length; i++) {
        list.push(
          this.billingCompanyToCompanyMap[this.selectedBillingCompanyIds[i]]
        );
      }
      console.log('selectedCompanyIds', list);
      return list;
    },
  },
  data() {
    return {
      loadButton: 'Load Reconciliation Data',
      loadingData: false,
      startDate: '2021-2-3',
      endDate: '2021-2-4',
      // selectedCompanyIds: [],
      selectedBillingCompanyIds: [0],
      invoiceData: [],
      billingCompanies: [],
      billingProductsMap: {},
      invoiceGroupFields: [
        'productName',
        'billingTransactionCount',
        'billingRecallCount',
        'requestCount',
        'show_details',
      ],
      invoiceGroupItems: [],
      invoiceFields: [
        'companyName',
        'orderDate',
        'source',
        'category',
        'transactionCount',
        'recallCount',
      ],
      invoiceItems: [],
      request2ProductGroupFields: [
        'companyName',
        'product',
        'subProduct',
        'transactionCount',
        'bulkSubmissionTransactionCount',
      ],
      discrepancyFields: [
        'discrepancy',
        'cause',
        { key: 'requestIds', label: 'Request Ids' },
      ],
      discrepancyItems: [],
      request2ProductGroupItems: [],
      expandedInvoice: null,
      billingCompanyToCompanyMap: {},
      companyIdToCompanyNameMap: {},
      isRequest2ProductGroupItemsBusy: false,
      isDiscrepancyBusy: false,
    };
  },
  methods: {
    getTheDays() {
      var dt = new Date();
      dt.setDate(1);
      dt.setMonth(dt.getMonth() - 1);

      // GET THE MONTH AND YEAR OF THE SELECTED DATE.
      var month = dt.getMonth();
      var year = dt.getFullYear();

      // GET THE FIRST AND LAST DATE OF THE MONTH.
      this.startDate = new Date(year, month, 1).toISOString().slice(0, 10);
      this.endDate = new Date(year, month + 1, 0).toISOString().slice(0, 10);
    },
    async getData() {
      var i;
      this.loadButton = 'Loading...';
      this.loadingData = true;
      try {
        this.invoiceGroupItems = [];

        var invoiceDataGrouped = await appService.getGroupedInvoiceData(
          this.selectedBillingCompanyIds,
          this.startDate,
          this.endDate
        );
        var invoiceGroupMap = {};
        for (i = 0; i < invoiceDataGrouped.length; i++) {
          invoiceGroupMap[invoiceDataGrouped[i].billingProductId] =
            invoiceDataGrouped[i];
        }

        var productDataGrouped = await appService.getGroupedProductData(
          this.selectedCompanyIds,
          this.startDate,
          this.endDate
        );

        var data = {};
        data.productName = 'Demographic Verifier';
        this.generateDataElement(
          data,
          1,
          1,
          invoiceGroupMap,
          productDataGrouped,
          [2, 5, 6, 9]
        );
        this.invoiceGroupItems.push(data);

        data = {};
        data.productName = 'Self Pay Analyzer';
        this.generateDataElement(
          data,
          2,
          2,
          invoiceGroupMap,
          productDataGrouped
        );
        this.invoiceGroupItems.push(data);
        data = {};
        data.productName = 'Insurance Verifier';
        this.generateDataElement(
          data,
          3,
          3,
          invoiceGroupMap,
          productDataGrouped,
          [0]
        );
        // if (productGroupMap[8]) {
        //   data.requestCount += productGroupMap[8].requestCount
        // }
        this.invoiceGroupItems.push(data);
        data = {};
        data.productName = 'Insurance Discovery';
        this.generateDataElement(
          data,
          5,
          5,
          invoiceGroupMap,
          productDataGrouped,
          [5]
        );
        this.invoiceGroupItems.push(data);
        data = {};
        data.productName = 'Retro Check';
        this.generateDataElement(
          data,
          6,
          6,
          invoiceGroupMap,
          productDataGrouped
        );
        this.invoiceGroupItems.push(data);
        data = {};
        data.productName = 'MBI Discovery';
        this.generateDataElement(
          data,
          12,
          9,
          invoiceGroupMap,
          productDataGrouped
        );
        this.invoiceGroupItems.push(data);
        data = {};
        data.productName = 'Deductible Management';
        this.generateDataElement(
          data,
          9,
          10,
          invoiceGroupMap,
          productDataGrouped,
          [0]
        );
        this.invoiceGroupItems.push(data);
      } catch (error) {
        console.error(error);
        this.displayErrorToast('Error Loading Data: ' + error.message);
      }
      this.loadButton = 'Load Data';
      this.loadingData = false;
    },
    generateDataElement(
      data,
      billingProductId,
      productId,
      invoiceGroupMap,
      productDataGrouped,
      excludeList
    ) {
      data._showDetails = false;
      data.invoiceItems = [];
      data.billingProductId = billingProductId;
      data.productId = productId;
      if (invoiceGroupMap[billingProductId]) {
        data.billingTransactionCount =
          invoiceGroupMap[billingProductId].transactionCount;
        data.billingRecallCount = invoiceGroupMap[billingProductId].recallCount;
      }
      var requestCount = 0;
      for (var i = 0; i < productDataGrouped.length; i++) {
        if (productId === productDataGrouped[i].productId) {
          var include = true;
          if (excludeList) {
            if (excludeList.includes(productDataGrouped[i].subProductId)) {
              include = false;
            }
          }
          if (include) {
            requestCount += productDataGrouped[i].requestCount;
          }
        }
        // productGroupMap[productDataGrouped[i].productId] = productDataGrouped[i]
      }
      if (requestCount !== 0) {
        data.requestCount = requestCount;
      }
      //  if (productGroupMap[productId]) {
      //     data.requestCount = productGroupMap[productId].requestCount
      //   }
    },
    async sjktoggleDetails(data) {
      var i, j;
      console.log('toggleDetails', data);
      data._showDetails = !data._showDetails;
      if (data._showDetails) {
        if (
          this.expandedInvoice !== null &&
          this.expandedInvoice.billingProductId !== data.billingProductId
        ) {
          this.expandedInvoice._showDetails = false;
        }
        this.expandedInvoice = data;

        this.invoiceItems = [];
        this.discrepancyItems = [];
        this.isRequest2ProductGroupItemsBusy = true;
        this.isDiscrepancyBusy = true;
        this.request2ProductGroupItems = [];
        var productId = 0;
        if (data.billingProductId === 1) {
          productId = 1;
        } else if (data.billingProductId === 2) {
          productId = 2;
        } else if (data.billingProductId === 3) {
          productId = 3;
        } else if (data.billingProductId === 5) {
          productId = 5;
        } else if (data.billingProductId === 6) {
          productId = 6;
        } else if (data.billingProductId === 12) {
          productId = 9;
        } else if (data.billingProductId === 9) {
          productId = 10;
        }
        for (i = 0; i < this.selectedBillingCompanyIds.length; i++) {
          var companyId = this.billingCompanyToCompanyMap[
            this.selectedBillingCompanyIds[i]
          ];
          console.log(
            'billingCompanyId, companyId',
            this.selectedBillingCompanyIds[i],
            companyId
          );
          var billingCompanyInvoiceData = await appService.getInvoiceData(
            this.selectedBillingCompanyIds[i],
            this.startDate,
            this.endDate,
            data.billingProductId
          );
          for (j = 0; j < billingCompanyInvoiceData.length; j++) {
            billingCompanyInvoiceData[
              j
            ].companyName = this.companyIdToCompanyNameMap[companyId];
            this.invoiceItems.push(billingCompanyInvoiceData[j]);
          }

          if (companyId === undefined) {
            companyId = -1;
          }
          var request2ProductCountPerCompany = await appService.getRequest2ProductCounts(
            companyId,
            this.startDate,
            this.endDate,
            productId
          );
          for (j = 0; j < request2ProductCountPerCompany.length; j++) {
            request2ProductCountPerCompany[j].product = this.getProductName(
              request2ProductCountPerCompany[j].productId
            );
            request2ProductCountPerCompany[j].subProduct = this.getProductName(
              request2ProductCountPerCompany[j].subProductId
            );
            request2ProductCountPerCompany[
              j
            ].companyName = this.companyIdToCompanyNameMap[
              request2ProductCountPerCompany[j].companyId
            ];

            this.request2ProductGroupItems.push(
              request2ProductCountPerCompany[j]
            );
          }
          this.isRequest2ProductGroupItemsBusy = false;

          var discrepancyData = await appService.getDiscrepancyData(
            companyId,
            this.selectedBillingCompanyIds[i],
            this.startDate,
            this.endDate,
            productId
          );
          // if (discrepancyData.inBillingNotInPayorLogic.length > 0) {
          //   this.discrepancyItems.push({
          //     discrepancy: 'inBillingNotInPayorLogic',
          //     requestIds: discrepancyData.inBillingNotInPayorLogicRecords,
          //     cause: '',
          //   });
          // }
          // if (discrepancyData.inPayorLogicNotInBilling.length > 0) {
          //   this.discrepancyItems.push({
          //     discrepancy: 'inPayorLogicNotInBilling',
          //     requestIds: discrepancyData.inPayorLogicNotInBillingRecords,
          //     cause: '',
          //   });
          // }
          for (j = 0; j < discrepancyData.discrepancyRequestIds.length; j++) {
            this.discrepancyItems.push({
              discrepancy: discrepancyData.discrepancyRequestIds[j].reason,
              requestIds: discrepancyData.discrepancyRequestIds[
                j
              ].requestIds.join(', '),
              cause: '',
            });
          }
          console.log(' this.discrepancyItems', this.discrepancyItems);
        }

        this.isDiscrepancyBusy = false;
      }
    },
    getProductName(productId) {
      if (productId === 1) {
        return 'Demographic Verifier';
      } else if (productId === 2) {
        return 'Self Pay Analyzer';
      } else if (productId === 3) {
        return 'Insurance Verifier';
      } else if (productId === 5) {
        return 'Insurance Discovery';
      } else if (productId === 6) {
        return 'Retro Check';
      } else if (productId === 9) {
        return 'MBI Discovery';
      } else if (productId === 10) {
        return 'Deductible Management';
      } else if (productId === 0) {
        return 'Free';
      }
    },
  },
  async mounted() {
    console.log('mounted');
    this.getTheDays();
    this.billingCompanies = await appService.getBillingCompanies();
    this.billingCompanyToCompanyMap[-1] = -1;
    for (var i = 0; i < this.billingCompanies.length; i++) {
      this.billingCompanyToCompanyMap[
        this.billingCompanies[i].billingCompanyId
      ] = this.billingCompanies[i].companyId;
      this.companyIdToCompanyNameMap[
        this.billingCompanies[i].companyId
      ] = this.billingCompanies[i].companyName;
    }
    console.log('map', this.billingCompanyToCompanyMap);
    this.billingCompanies.unshift({
      billingCompanyId: 0,
      companyName: 'Please Select Company',
    });
    this.billingCompanies.unshift({
      billingCompanyId: -1,
      companyName: '-- All --',
    });
    var billingProducts = await appService.getBillingProducts();
    for (i = 0; i < billingProducts.length; i++) {
      this.billingProductsMap[billingProducts[i].billingProductId] =
        billingProducts[i].productName;
    }
  },
};
</script>
