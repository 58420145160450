import appService from 'src/pl.service';
import { GET_PAYOR_ELIGIBILITIES } from 'src/store/actions/types';
import { deductibleSort } from './deductibleSort';

/**
 * retrieves the payor eligibilities from the API and
 * commits the data to the store using the GET_PAYOR_ELIGIBILITIES action type
 */
export const getPayorEligibilities = async ({ commit, state }, applicantId) => {
  commit(GET_PAYOR_ELIGIBILITIES, { loading: true });

  const data = await appService.getPayorEligibilities(
    applicantId ?? state.applicant.id
  );

  const errors = await appService.getEligibilityErrorList(
    applicantId ?? state.applicant.id
  );

  if (data.responses) {
    for (var n = 0; n < data.responses.length; n++) {
      // TODO: refactor patient (left pane) widget to use vuex instead of this
      // if (this.responses[n].createdBy !== 'Realtime Insurance Finder') {
      //   EventBus.$emit('ivStatusRefresh');
      // }

      // TODO: move to template
      // this.responses[n].asOf = this.dateStr(this.responses[n].createdOn);

      var deductibles = [];
      for (var m = 0; m < data.responses[n].benefitInfoes.length; m++) {
        var ebi = data.responses[n].benefitInfoes[m];
        var ebiUsed = false;
        if (ebi.infoType === 'C' && ebi.timePeriod === 'Remaining') {
          for (var o = 0; o < ebi.serviceTypeCodes.length; o++) {
            if (ebi.serviceTypeCodes[o] === '30' && ebiUsed === false) {
              var fullInfoTypeDescription = '';
              if (ebi.insuranceTypeDescription) {
                if (ebi.insuranceType === 'MA') {
                  fullInfoTypeDescription = fullInfoTypeDescription + 'Part A';
                } else if (ebi.insuranceType === 'MB') {
                  fullInfoTypeDescription = fullInfoTypeDescription + 'Part B';
                } else {
                  fullInfoTypeDescription =
                    fullInfoTypeDescription + ebi.insuranceTypeDescription;
                }
              }
              if (ebi.coverageLevelDescription) {
                fullInfoTypeDescription =
                  fullInfoTypeDescription + ebi.coverageLevelDescription;
              }
              var deductible = {};
              deductible.id = ebi.eligibilityBenefitInfoId;
              deductible.amount = '$' + ebi.amount;
              deductible.description = fullInfoTypeDescription;
              deductible.inPlanNetwork = ebi.inPlanNetwork;
              if (
                !deductibles.some(
                  e =>
                    e.amount == deductible.amount &&
                    e.description == deductible.description &&
                    e.inPlanNetwork == deductible.inPlanNetwork
                )
              ) {
                deductibles.push(deductible);
              }
              ebiUsed = true;
            }
          }
        }
      }
      deductibles.sort(deductibleSort('inPlanNetwork'));
      deductibles.sort(deductibleSort('description'));
      data.responses[n].deductibles = deductibles;
    }
  }

  commit(GET_PAYOR_ELIGIBILITIES, {
    loading: false,
    retrieved: true,
    data: data.responses,
    serviceTypes: data.serviceTypes,
    errors: errors,
  });
};
