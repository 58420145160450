import appService from 'src/pl.service';
import { SET_PAYOR_IS_MAPPED } from 'src/store/actions/types';
import { statuses } from 'src/constants/statuses';

/**
 * Checks a given payor name to see if
 * the user will need to map it
 */
export const checkPayorIsMapped = async (
  { commit },
  { payorName, arrayIndex, insuranceType }
) => {
  commit(SET_PAYOR_IS_MAPPED, {
    index: arrayIndex,
    type: insuranceType,
    status: statuses.pending,
  });
  try {
    const data = await appService.isPayorNameValid(payorName);
    commit(SET_PAYOR_IS_MAPPED, {
      index: arrayIndex,
      type: insuranceType,
      data: data,
      status: statuses.success,
    });
  } catch (error) {
    commit(SET_PAYOR_IS_MAPPED, {
      index: arrayIndex,
      type: insuranceType,
      status: statuses.failure,
    });
  }
};
