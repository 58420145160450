<template>
  <!-- used for printing -->
  <div class="content">
    <div id="right-panel"></div>
    <p style="page-break-before: always"></p>
    <div id="map"></div>
  </div>
</template>

<script>
import gmapsInit from 'src/gmaps';

export default {
  data() {
    return {
      zoomed: false,
    };
  },
  methods: {
    async loadDirections(start, end) {
      const google = await gmapsInit();
      var map = new google.maps.Map(document.getElementById('map'), {
        zoom: 13,
        center: start,
      });
      map.addListener('tilesloaded', function() {
        if (this.zoomed) {
          window.print();

          // https://stackoverflow.com/questions/6460630/close-window-automatically-after-printing-dialog-closes
          // test 1
          // window.onfocus = function () {
          //   window.close()
          // }

          // test 2
          // setTimeout(function () { window.print() }, 500)
          // window.onfocus = function () {
          //   setTimeout(function () {
          //     window.close()
          //   }, 5000)
          // }

          // test 3
          // window.close()
        }
        var currentZoom = map.getZoom();
        if (!this.zoomed) {
          map.setZoom(currentZoom - 1);
          this.zoomed = true;
        }
        console.log('tilesloaded');
      });
      console.log('loadDirections', map);
      var directionsDisplay = new google.maps.DirectionsRenderer();
      var directionsService = new google.maps.DirectionsService();

      directionsDisplay.setMap(map);
      var rightPanelDirectionList = document.getElementById('right-panel');
      while (rightPanelDirectionList.hasChildNodes()) {
        rightPanelDirectionList.removeChild(rightPanelDirectionList.lastChild);
      }
      directionsDisplay.setPanel(document.getElementById('right-panel'));
      directionsService.route(
        {
          origin: start,
          destination: end,
          travelMode: 'DRIVING',
          provideRouteAlternatives: true,
        },
        function(response, status) {
          if (status === 'OK') {
            directionsDisplay.setDirections(response);
          } else {
            window.alert('Directions request failed due to ' + status);
          }
        }
      );
    },
  },
  async mounted() {
    var start = JSON.parse(this.$route.query.start);
    var end = JSON.parse(this.$route.query.end);
    await this.loadDirections(start, end);
  },
};
</script>
<style>
/* Always set the map height explicitly to define the size of the div
       * element that contains the map. */
#map {
  height: 500px;
  /* margin-right: 400px; */
  /* display: block !important; */
}

#right-panel {
  font-family: 'Roboto', 'sans-serif';
  line-height: 30px;
  padding-left: 10px;
}

#right-panel select,
#right-panel input {
  font-size: 15px;
}

#right-panel select {
  width: 100%;
}

#right-panel i {
  font-size: 12px;
}
#right-panel {
  height: 100%;
  float: right;
  width: 390px;
  overflow: auto;
}
@media print {
  #map {
    height: 500px;
    /* margin: 0; */
    display: block;
  }
  #right-panel {
    /* margin-top: 500px; */
    float: none;
    width: auto;
  }
}
</style>
