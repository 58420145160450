import appService from 'src/pl.service';
import { GET_HISTORY_PDF } from 'src/store/actions/types';
import { statuses } from 'src/constants/statuses';

/**
 * retrieves the historic pdf from the API for given the eligibilityId and
 * commits the data to the store using the GET_HISTORY_PDF action type
 */
export const getHistoryPdf = async ({ commit }, eligibilityId) => {
  commit(GET_HISTORY_PDF, {
    status: statuses.pending,
  });
  try {
    var pdf = await appService.getEligibilityPdf(eligibilityId);
    commit(GET_HISTORY_PDF, {
      data: pdf,
      eligibilityId,
      status: statuses.success,
    });
  } catch (error) {
    commit(GET_HISTORY_PDF, {
      status: statuses.failure,
    });
  }
};
