const configService = {
  async getWebApiUrl() {
    var url = `${window.location.origin}/app_config.json`;
    var localStorageWebApiUrl = window.localStorage.getItem(
      "appConfig.WebApiUrl"
    );
    if (localStorageWebApiUrl) {
      return localStorageWebApiUrl;
    }

    console.log("app_config.json url", url);
    let response = await fetch(url);

    if (response.ok) {
      let json = await response.json();
      console.log("app_config.json fetch json", json);
      window.localStorage.setItem("appConfig.WebApiUrl", json.WebApiUrl);
      console.log(
        "appConfig.WebApiUrl",
        window.localStorage.getItem("appConfig.WebApiUrl")
      );
      return json.WebApiUrl;
    } else {
      console.error("HTTP-Error: ", response.status);
    }
  },
};

export default configService;
