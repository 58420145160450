import { cellTypes } from 'src/constants/grid';

/**
 * removes all the mirror type cells
 * that are related to the cellId provided
 */
export const removeMirrors = ({ columns, cellId }) => {
  columns.forEach(
    column =>
      (column.cells = column.cells.filter(
        cell =>
          cell.type !== cellTypes.mirror.type || cell.meta.sourceId !== cellId
      ))
  );
};
