import Vue from 'vue';
import clone from 'clone';
export const setTripDistance = (state, { data, status, error }) => {
  var applicantVisits = state.applicant.visits.data;
  if (data && applicantVisits.length) {
    Vue.set(state.applicant.visits.data[0], 'tripDistance', clone(data));
    if (applicantVisits.length && state.applicant.visits.data[0].tripDistance) {
      state.applicant.visits.data[0].tripDistance.status = status;
      state.applicant.visits.data[0].tripDistance.error = error;
    }
  }
};
