import { SET_APPLICANT_HOME_VALIDATED_ADDRESS } from 'src/store/actions/types';
import appService from 'src/pl.service';
import { statuses } from 'src/constants/statuses';

/**
 * commits the id to the store using the SET_VALIDATED_ADDRESSES action type
 */
 export const setApplicantHomeValidatedAddress = async ({ commit, state }) => {
  try{    
    var validatedAddress = null;
    if (state.applicant.homeAddress !== null && state.applicant.homeAddress !== 'undefined')
    {
      validatedAddress = await appService.getValidatedAddress(
        state.applicant.homeAddress.address1 ?? '',
        state.applicant.homeAddress.address2 ?? '',
        state.applicant.homeAddress.city ?? '',
        state.applicant.homeAddress.state ?? '',
        state.applicant.homeAddress.zip ?? ''
      );
    }
    if(validatedAddress)
    {
    commit(
      SET_APPLICANT_HOME_VALIDATED_ADDRESS,
      {
          homeValidatedAddress: validatedAddress,
          status: 'success',
        }
      );
    }
    } catch (error) {
  commit(SET_APPLICANT_HOME_VALIDATED_ADDRESS, {
    status: statuses.failure,
    error,
  });    
}
};