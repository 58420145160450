/**
 * mutates the insuranceDiscovery.lastRunDate
 *  property with the data given, as-is
 */
export const getInsuranceFinder = (
  state,
  { status, data, error = {}, retrieved = false }
) => {
  state.applicant.insuranceDiscovery.retrieved = retrieved;
  state.applicant.insuranceDiscovery.lastRunDate.status = status;
  state.applicant.insuranceDiscovery.lastRunDate.error = error;
  if (data?.length) {
    state.applicant.insuranceDiscovery.lastRunDate.data =
      data[data.length - 1].createdOn;
  }
};
