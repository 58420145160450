<template>
  <b-container fluid>
    <!-- User Interface controls -->
    <b-row>
      <b-col>
        <b-form-group label-cols-sm="3" label="Date Range" class="mb-0">
          <date-range-picker
            :opens="opens"
            @update="dateSelected"
            :locale-data="locale"
            v-model="dateRange"
          >
            <!--Optional scope for the input displaying the dates -->
            <div slot="input">
              {{ dateRange.startDate }} - {{ dateRange.endDate }}
            </div>
          </date-range-picker>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label-cols-sm="2" label="Filter" class="mb-0">
          <b-input-group>
            <b-form-input
              v-model="filter"
              placeholder="Type to Search"
            ></b-form-input>
            <b-input-group-append>
              <b-button variant="primary" :disabled="!filter" @click="filter = ''"
                >Clear</b-button
              >
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <br />

    <!-- Main table element -->
    <b-table
      show-empty
      stacked="md"
      :items="items"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :striped="isStriped"
      :filter="filter"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :busy="isBusy"
      @filtered="onFiltered"
    >
      <template v-if="!isRescueNet" slot="lastname" slot-scope="row">
        <a :href="`/FullPatientData/${row.item.applicantId}`"
          >{{ row.item.lastname }}, {{ row.item.firstname }}</a
        >
      </template>
      <template v-if="isRescueNet" slot="lastname" slot-scope="row">
        {{ row.item.lastname }}, {{ row.item.firstname }}
      </template>

      <template slot="loadedOnEpoch" slot-scope="row">
        {{ row.item.loadedOnLocalString }}
      </template>

      <template v-slot:table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>
    </b-table>

    <b-row>
      <b-col md="10" class="my-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          class="my-0"
        ></b-pagination>
      </b-col>
      <b-col align-self="end">
        <a align="right" class="arboost-link" :href="csvUrl">Export to Excel</a>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import appService from 'src/pl.service';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';

export default {
  props: {
    isRescueNet: {
      required: true,
    },
  },
  components: {
    DateRangePicker,
  },
  data() {
    return {
      opens: 'right',
      dateRange: {
        startDate: '2017-09-05',
        endDate: '2019-09-15',
      },
      locale: {
        direction: 'ltr', // direction of text
        format: 'MM/DD/YYYY', // fomart of the dates displayed
        separator: ' - ', // separator between the two ranges
        applyLabel: 'Apply',
        cancelLabel: 'Cancel',
        weekLabel: 'W',
        customRangeLabel: 'Custom Range',
        // daysOfWeek: moment.weekdaysMin(), // array of days - see moment documenations for details
        // monthNames: moment.monthsShort(), // array of month names - see moment documenations for details
        firstDay: 1, // ISO first day of week - see moment documenations for details
      },
      isStriped: true,
      payorData: null,
      payorId: null,
      payorMap: {},
      text: [],
      items: null,
      tripHistoryList: null,
      fields: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15],
      sortBy: 'loadedOnEpoch',
      sortDesc: true,
      sortDirection: 'desc',
      filter: null,
      isBusy: true,
    };
  },
  watch: {},
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key };
        });
    },
    baseUrl: function() {
      console.log('baseUrl', appService.getBaseUrl());
      return appService.getBaseUrl();
    },
    csvUrl: function() {
      console.log('baseUrl', appService.getBaseUrl());
      var startEpoch = Math.floor(
        Date.parse(this.dateRange.startDate) / 1000.0
      );
      var endEpoch =
        Math.floor(Date.parse(this.dateRange.endDate) / 1000.0) + 86399;
      return `${
        this.baseUrl
      }api/v1.0/rescueNetToPlTripHistory/csvAsBytes?startDateEpoch=${startEpoch}&endDateEpoch=${endEpoch}&isRescueNet=${
        this.isRescueNet
      }&${appService.getToken()}`;
    },
  },
  created() {
    console.log('PatientHistoryTable.created', this.isRescueNet);
    var today = new Date();
    var yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    this.dateRange.startDate = this.getFormattedDate(yesterday);
    this.dateRange.endDate = this.getFormattedDate(today);
  },
  async mounted() {
    console.log('PatientHistoryTable.mounted');
    this.fields = this.getFieldList();

    await this.loadTableData();
  },
  methods: {
    async loadTableData() {
      this.isBusy = true;
      this.tripHistoryList = await this.getTripHistory();
      for (var i = 0; i < this.tripHistoryList.length; i++) {
        this.tripHistoryList[i].dos = this.dateStr(
          this.tripHistoryList[i].serviceDateBegin
        );
        this.tripHistoryList[
          i
        ].loadedOnLocalString = this.epochToLocalStringFormatter(
          this.tripHistoryList[i].loadedOnEpoch
        );
        if (this.tripHistoryList[i].jsonDataJson) {
          var runNumber = this.tripHistoryList[i].jsonDataJson.runNumber;
          var tdate = this.tripHistoryList[i].jsonDataJson.tdate;
          this.tripHistoryList[i].runNumberWithDate = 'undefined';
          if (tdate && runNumber) {
            var runNumberWithDate = tdate.substring(2, 4) + '-' + runNumber;
            this.tripHistoryList[i].runNumberWithDate = runNumberWithDate;
          }
        }
      }
      this.items = this.tripHistoryList;
      this.totalRows = this.tripHistoryList.length;
      this.isBusy = false;
    },
    async getTripHistory() {
      console.log('PatientHistoryTable.getTripHistory');
      var startEpoch = Math.floor(
        Date.parse(this.dateRange.startDate) / 1000.0
      );
      var endEpoch =
        Math.floor(Date.parse(this.dateRange.endDate) / 1000.0) + 86399;
      var fullTripHistory = await appService.getRescueNetToPlTripHistory(
        startEpoch,
        endEpoch
      );
      return fullTripHistory;
    },
    async dateSelected(value) {
      console.log('PatientHistoryTable.dateSelected', value);
      this.dateRange.startDate = this.getFormattedDate(
        this.dateRange.startDate
      );
      this.dateRange.endDate = this.getFormattedDate(this.dateRange.endDate);
      console.log('PatientHistoryTable.dateSelected.end', this.dateRange);
      await this.loadTableData();
    },
    epochToLocalStringFormatter(value, key, item) {
      console.log('epochToLocalStringFormatter', value, key, item);
      var a = new Date();
      a.setTime(value * 1000);
      return a.toLocaleString();
    },
    getFieldList() {
      console.log('PatientHistoryTable.getFieldList', this.isRescueNet);
      if (!this.isRescueNet) {
        return [
          { key: 'lastname', label: 'Patient', sortable: true },
          { key: 'dos', label: 'DOS', sortable: true },
          { key: 'visitNumber', label: 'Visit Number', sortable: true },
          { key: 'loadedOnEpoch', label: 'Accessed', sortable: true },
        ];
      } else {
        return [
          { key: 'lastname', label: 'Patient', sortable: true },
          { key: 'dos', label: 'DOS', sortable: true },
          { key: 'jsonDataJson.company', label: 'Company', sortable: true },
          { key: 'runNumberWithDate', label: 'Run #', sortable: true },
          { key: 'loadedOnEpoch', label: 'Accessed', sortable: true },
        ];
      }
    },
    // async myProvider (ctx) {
    //   console.log('PatientHistoryTable.myProvider', ctx)
    //   let items = []

    //   // Perform any items processing needed
    //   var startEpoch = Math.floor(Date.parse(this.dateRange.startDate) / 1000.0)
    //   var endEpoch = Math.floor(Date.parse(this.dateRange.endDate) / 1000.0) + 86399
    //   var xxx = await appService.getRescueNetToPlTripHistory(startEpoch, endEpoch)
    //   if (ctx.currentPage === 1) {
    //     items.push(xxx[0], xxx[1], xxx[2], xxx[3], xxx[4])
    //   } else {
    //     items.push(xxx[5], xxx[6])
    //   }

    //   // Must return an array
    //   return items || []
    // },
    getFormattedDate(date) {
      console.log('PatientHistoryTable.getFormattedDate', date);
      let year = date.getFullYear();
      let month = (1 + date.getMonth()).toString().padStart(2, '0');
      let day = date
        .getDate()
        .toString()
        .padStart(2, '0');

      return month + '/' + day + '/' + year;
    },
    selected(data) {
      console.log('PatientHistoryTable.selected', data);
      console.log('selected', data);
      this.payorId = data.id;
    },
    onFiltered(filteredItems) {
      console.log('PatientHistoryTable.onFiltered', filteredItems);
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
