<template>
  <div class="bootstrap">
    <b-card no-body>
      <b-row class="mt-2" style="margin-left: 1px">
        <b-col>
          <b-button variant="primary" @click="$router.go(-1)">
            <div style="display: flex; justify-content: center; align-content: center;">
              <font-awesome-icon style="font-size: 25px;" icon="arrow-circle-left" />
              <span class="ml-1">Back to Patient Details</span>
            </div>
          </b-button>
        </b-col>
      </b-row>
      
      <b-tabs card @input="tabenter" class="arboost-link">
        <b-tab title="Company Payors" class="arboost-link">
          <payor-mapping
            v-if="tab2 && rn2PlStatusDetermined"
            ref="tab2"
            :isRescueNet="isRN2PLApp"
            active
          >
          </payor-mapping>
        </b-tab>
        <b-tab title="Payors" v-if="isAdmin">
          <payor-admin v-if="tabPayorAdmin" isRescueNet="false"> </payor-admin>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import RescueNetPayorMapping from 'src/components/RescueNetPayorMapping2';
import PayorAdmin from 'src/components/PayorAdmin';

export default {
  components: {
    'payor-mapping': RescueNetPayorMapping,
    'payor-admin': PayorAdmin,
  },
  data() {
    return {
      name: 'BootstrapVue',
      show: true,
      tab2: false,
      isRN2PLApp: false,
      tabPayorAdmin: false,
      isAdmin: false,
      rn2PlStatusDetermined: false,
    };
  },
  watch: {
    show(newVal) {
      console.log('Alert is now ' + (newVal ? 'visible' : 'hidden'));
    },
  },
  async created() {
    await this.determineRN2PLAppStatus();
    this.rn2PlStatusDetermined = true;
    var roleId = window.localStorage.getItem('roleId');
    if (roleId === '1') {
      this.isAdmin = true;
    }
    console.log('isAdmin', roleId, this.isAdmin);
  },
  methods: {
    toggle() {
      console.log('Toggle button clicked');
      this.show = !this.show;
    },
    async determineRN2PLAppStatus() {
      console.log('determineRN2PLAppStatus');
      try {
        // eslint-disable-next-line
        await CefSharp.BindObjectAsync('CefSharpMessageBox', 'bound');
        this.isRN2PLApp = true;
      } catch (error) {
        console.log(
          'testCefMessageBox.CefSharpLaunch Error: (most likely not running within RN->PL app)',
          error
        );
      }
      console.log('determineRN2PLAppStatus', this.isRN2PLApp);
    },

    dismissed() {
      console.log('Alert dismissed');
    },
    tabenter(index) {
      console.log('tabenter', index);
      if (index === 0) {
        this.tab2 = true;
        // var tab2 = this.$refs.tab2
        // tab2.loadData()
      }
      if (index === 1) {
        this.tabPayorAdmin = true;
      }
    },
  },
};
</script>
