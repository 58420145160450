import { operations } from 'src/constants/operations';

/**
 * updates and sets the active profile
 */
export const updateActiveProfile = (state, { id, status, error }) => {
  state.profiles.active.id = id;
  state.profiles.active.operations[operations.update] = {
    status,
    error,
  };
};
