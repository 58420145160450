import Vue from 'vue';
import Vuex from 'vuex';
import { actions } from './actions/index';
import { getters } from './getters/index';
import { mutations } from './mutations/index';
import { plugins } from './plugins';
import { initialState } from './initialState/index';

Vue.use(Vuex);

const state = {
  ...initialState,
};

const store = new Vuex.Store({
  state,
  getters,
  actions,
  mutations,
  plugins,
});

export default store;
