/**
 * a temporary patch for LogRocket's SDK bug that improperly uninstalls itself and
 * leaves behind wrapped event listeners that were got added while it was initializing.
 *
 * If/When LR releases a fix within their SDK, please remove this file along with its import.
 * @see src/main.js
 */
const _window =
  typeof window !== 'undefined'
    ? window
    : typeof global !== 'undefined'
    ? global
    : typeof self !== 'undefined'
    ? self
    : {};
const eventTargets = [
  'EventTarget',
  'Window',
  'Node',
  'ApplicationCache',
  'AudioTrackList',
  'ChannelMergerNode',
  'CryptoOperation',
  'EventSource',
  'FileReader',
  'HTMLUnknownElement',
  'IDBDatabase',
  'IDBRequest',
  'IDBTransaction',
  'KeyOperation',
  'MediaController',
  'MessagePort',
  'ModalWindow',
  'Notification',
  'SVGElementInstance',
  'Screen',
  'TextTrack',
  'TextTrackCue',
  'TextTrackList',
  'WebSocket',
  'WebSocketWorker',
  'Worker',
  'XMLHttpRequest',
  'XMLHttpRequestEventTarget',
  'XMLHttpRequestUpload',
];
for (var i = 0; i < eventTargets.length; i++) {
  const global = eventTargets[i];
  const proto = _window[global] && _window[global].prototype;
  if (
    proto &&
    proto.hasOwnProperty &&
    // eslint-disable-next-line no-prototype-builtins
    proto.hasOwnProperty('addEventListener')
  ) {
    _window[global].prototype.removeEventListener = (function(_super) {
      return function() {
        try {
          if (
            arguments[1].__lr_wrapper__ &&
            typeof arguments[1].__lr_wrapper__ === 'function'
          ) {
            arguments[1] = arguments[1].__lr_wrapper__;
          }
        } catch (e) {
          // ignore, accessing __lr_wrapper__ will throw in some Selenium environments
        }

        return _super.apply(this, arguments);
      };
    })(_window[global].prototype.removeEventListener);
  }
}
