import { applicant } from './applicant';
import { guarantor } from './guarantor';
import { widgetView } from './widgetView';
import { permissions } from './permissions';
import { profiles } from './profiles';
import { alsConfig } from './alsConfig';

export const initialState = {
  applicant,
  guarantor,
  widgetView,
  permissions,
  profiles,
  alsConfig,
  // legacy
  start: null,
  end: null,
  overviewMapDescription: 'unknown',
  // overviewMapCoordinates: {lat: 39.612742, lng: -84.15901500000001} // payorlogic
  // overviewMapCoordinates: {lat: 40.1194322, lng: -83.0221438} // 7714 cloister dr
  overviewMapCoordinates: { lat: 37.869085, lng: -122.254775 }, // berkley
};
