export const applicant = {
  id: null,
  visits: {
    retrieved: false,
    data: [],
  },
  eligibilities: {
    data: [],
    errors: [],
    loading: false,
    retrieved: false,
  },
  credit: {
    data: [],
    loading: false,
    retrieved: false,
    // if true, we already run the self pay analyzer once
    selfPayAnalyzer: false,
  },
  demographic: {
    loading: false,
    retrieved: false,
    data: {},
  },
  //Gets all payors for the payor map modal
  payors: {
    data: [],
    retrieved: false,
  },
  serviceTypes: {
    //An object is used so the st codes can be easily referenced
    //e.g. serviceTypes.data['30']
    data: {},
    retrieved: false,
  },
  insuranceDiscovery: {
    status: undefined,
    retrieved: false,
    //This will only be set if ID has been run from somewhere else
    //is set by the GET_INSURANCE_FINDER action
    lastRunDate: {
      status: undefined,
      data: undefined,
      error: {},
    },
    error: {},
  },
  validateInsurance: {
    //If true we have already run validateInsurance before
    verifyInsurance: false,
    error: {},
  },
  history: {
    data: [],
    status: undefined,
  },
  homeValidatedAddress: undefined,
  guarantorHomeValidatedAddress: undefined,
};
