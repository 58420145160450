import appService from 'src/pl.service';

export async function setApplicantVisitTripAddresses(applicantVisits) {
  if (applicantVisits) {
    var visit = applicantVisits[0];
    if (visit?.data?.pickupAddress) {
      visit.pickupValidatedAddress = await appService.getValidatedAddress(
        visit.data.pickupAddress.address1 ?? '',
        visit.data.pickupAddress.address2 ?? '',
        visit.data.pickupAddress.city ?? '',
        visit.data.pickupAddress.state ?? '',
        visit.data.pickupAddress.zip ?? ''
      );
    }
    if (visit?.data?.destinationAddress) {
      visit.destinationValidatedAddress = await appService.getValidatedAddress(
        visit.data.destinationAddress.address1 ?? '',
        visit.data.destinationAddress.address2 ?? '',
        visit.data.destinationAddress.city ?? '',
        visit.data.destinationAddress.state ?? '',
        visit.data.destinationAddress.zip ?? ''
      );
    }
    if (visit?.epcrJsonData?.trip) {
      visit.epcrPickupValidatedAddress = await appService.getValidatedAddress(
        visit.epcrJsonData.trip.puaddr ?? '',
        visit.epcrJsonData.trip.puaddr2 ?? '',
        visit.epcrJsonData.trip.pucity ?? '',
        visit.epcrJsonData.trip.pust ?? '',
        visit.epcrJsonData.trip.puzip ?? ''
      );

      visit.epcrDestinationValidatedAddress = await appService.getValidatedAddress(
        visit.epcrJsonData.trip.daddr ?? '',
        visit.epcrJsonData.trip.daddr2 ?? '',
        visit.epcrJsonData.trip.dcity ?? '',
        visit.epcrJsonData.trip.dst ?? '',
        visit.epcrJsonData.trip.dzip ?? ''
      );
    }
  }
}
