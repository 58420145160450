/**
 * removes any extraneous properties from the object
 * which is stored in vuex, but are not needed by the API.
 * (ie. operations)
 *
 * @param {object} profile
 * @returns {object}
 */
export const sanitizeProfile = profile => ({
  id: profile.id,
  name: profile.name,
  widgets: profile.widgets,
  expandOptions: profile.expandOptions,
  layouts: profile.layouts.map(layout => ({
    id: layout.id,
    columns: layout.columns,
  })),
});
