/**
 * Sets detailsEligibility status and, if it exists,
 * appends the eligibility object to
 * the data object in the history array
 * @param {*} state
 * @param {*} param1
 */
export const getHistoryEligibility = (
  state,
  { status, eligibilityId, data = {} }
) => {
  if (state.applicant.history.data?.length) {
    var historyItem = state.applicant.history.data.find(
      item => item.eligibilityId === eligibilityId
    );
    if (historyItem) {
      historyItem.detailsEligibility.status = status;
      historyItem.eligibility = data;
    }
  }
};
