/**
 * mutates the applicant.credit property with the data given
 */
export const setApplicantCredit = (
  state,
  { data, loading, selfPayAnalyzer }
) => {
  state.applicant.credit.data = data;
  state.applicant.credit.loading = loading;
  if (!loading) {
    state.applicant.credit.retrieved = true;
  }
  if (selfPayAnalyzer !== undefined) {
    state.applicant.credit.selfPayAnalyzer = selfPayAnalyzer;
  }
};
