<template>
  <b-container fluid>
    <!-- User Interface controls -->
    <b-row>
      <b-col lg="6" class="my-1">
        <b-form-group
          label="Status"
          label-for="status-select"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <b-form-select
            multiple
            id="status-select"
            v-model="selectedStatus"
            :options="[
              'Active - Already On File',
              'Active - COB Error',
              'Active - Dental Rule Exclusion',
              'Active - Guarantor',
              'Active - Low Confidence Excluded',
              'Active - Medicaid Rule Exclusion',
              'Active - Miscellaneous Exclusion',
              'Active - Miscellaneous Rule Exclusion',
              'Active - Recert In Progress',
              'Active - Rx Exclusion',
              'Active - Service Type Exclusion',
              'Active Coverage',
              'Age Requirment Not Met',
              'Duplicate Subscriber',
              'Entitled Inactive',
              'Error',
              'Inactive',
              'Inactive Coverage',
              'Limited Coverage',
              'Medicare Part A Only',
              'Not Found',
              'Pending',
              'Removed Due To Confidence Score',
              'Search Not Performed',
              'Search Not Performed - Search Options',
              'Search Not Performed - Timely Filing',
              'Unspecified',
              'Unspecified - Additional Coverage Detected',
              'Unspecified - Termed',
            ]"
            size="sm"
          ></b-form-select>
        </b-form-group>
      </b-col>

      <b-col lg="6" class="my-1">
        <b-form-group
          label="Payors"
          label-for="payor-select"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <b-form-select
            multiple
            id="payor-select"
            text-field="payorName"
            value-field="payorName"
            v-model="selectedPayors"
            :options="allPayors"
            size="sm"
          ></b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="6" class="my-1">
        <b-form-group
          label="Vendor"
          label-for="vendor-select"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <b-form-select
            id="vendor-select"
            v-model="selectedVendor"
            :options="vendorOptions"
            size="sm"
          ></b-form-select>
        </b-form-group>
      </b-col>

      <b-col lg="6" class="my-1">
        <b-form-group
          label="Filter"
          label-for="filter-input"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            ></b-form-input>

            <b-input-group-append>
              <b-button variant="primary" :disabled="!filter" @click="filter = ''"
                >Clear</b-button
              >
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="6" class="my-1">
        <b-form-group
          v-model="sortDirection"
          label="Group By"
          description="Group By determines columns shown in Aggregate Data table.  Group by 0-1 items to view Data Over Time."
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <b-form-checkbox-group v-model="groupBy" class="mt-1">
            <b-form-checkbox value="company">Company</b-form-checkbox>
            <b-form-checkbox value="payor">Payor</b-form-checkbox>
            <b-form-checkbox value="status">Status</b-form-checkbox>
            <b-form-checkbox value="npi">NPI</b-form-checkbox>
            <b-form-checkbox value="vendor">Vendor</b-form-checkbox>
            <b-form-checkbox value="searchOption"
              >Search Option</b-form-checkbox
            >
          </b-form-checkbox-group>
        </b-form-group>
      </b-col>
      <b-col lg="6" class="my-1">
        <b-row>
          <b-col class="my-1">
            <b-form-group
              label="Per page"
              label-for="per-page-select"
              label-cols-sm="6"
              label-cols-md="4"
              label-cols-lg="3"
              label-align-sm="right"
              label-size="sm"
              class="mb-0"
            >
              <b-form-select
                id="per-page-select"
                v-model="perPage"
                :options="pageOptions"
                size="sm"
              ></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="my-1">
            <b-form-group
              label="Confidence Score"
              label-for="confidence-score"
              label-cols-sm="3"
              label-align-sm="right"
              label-size="sm"
              class="mb-0"
            >
              <vue-slider
                :enable-cross="false"
                v-model="sliderValue"
                id="confidence-score"
              ></vue-slider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-row class="justify-content-center my-1">
      <b-col md="auto">
        <b-button class="mx-1" variant="success" @click="getData" :disabled="loadingData">
          {{ loadButton }}
          <b-spinner small v-if="loadingData"></b-spinner>
        </b-button>
        <b-button
          variant="success"
          @click="getDataOverTime"
          :disabled="loadingDataOverTime"
        >
          {{ loadButtonOverTime }}
          <b-spinner small v-if="loadingDataOverTime"></b-spinner>
        </b-button>
      </b-col>
    </b-row>

    <!-- Main table element -->
    <b-row>
      <b-table
        :items="items"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        :filter="filter"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        stacked="md"
        show-empty
        small
        @filtered="onFiltered"
      >
        <template #cell(name)="row">
          {{ row.value.first }} {{ row.value.last }}
        </template>

        <template #cell(actions)="row">
          <b-button
            size="sm"
            @click="info(row.item, row.index, $event.target)"
            class="mr-1"
          >
            Info modal
          </b-button>
          <b-button size="sm" @click="row.toggleDetails">
            {{ row.detailsShowing ? "Hide" : "Show" }} Details
          </b-button>
        </template>

        <template #row-details="row">
          <b-card>
            <ul>
              <li v-for="(value, key) in row.item" :key="key">
                {{ key }}: {{ value }}
              </li>
            </ul>
          </b-card>
        </template>
      </b-table>
    </b-row>
    <b-row>
      <b-col>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0"
        >
        </b-pagination>
      </b-col>
   </b-row>
   <b-row>
      <div v-show="show" id="collapse-3">
        <div id="chart">
          <apexchart
            type="area"
            height="350"
            :options="chartOptions"
            :series="series"
          ></apexchart>
        </div>
      </div>
    </b-row>
  </b-container>
</template>

<script>
import appService from "../pl.service.js";
import reportingService from "../reporting.service.js";
import VueApexCharts from "vue-apexcharts";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";

export default {
  props: {
    companyIds: {
      required: true,
    },
    start: {
      required: true,
    },
    end: {
      required: true,
    },
  },
  components: {
    apexchart: VueApexCharts,
    "vue-slider": VueSlider,
  },
  data() {
    return {
      sliderValue: [0, 100],
      show: false,
      loadButton: "Load Aggregate Data",
      loadingData: false,
      loadButtonOverTime: "Load Data Over Time",
      loadingDataOverTime: false,
      items: [],
      fields: [
        {
          key: "companyId",
          label: "Company",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "payorName",
          label: "Payor Name",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "status",
          label: "Status",
          sortable: true,
          class: "text-center",
        },
        { key: "npiCode", label: "NPI", sortable: true, class: "text-center" },
        { key: "npiCode", label: "NPI", sortable: true, class: "text-center" },
        {
          key: "vendor",
          label: "Vendor",
          sortable: true,
          class: "text-center",
        },
        {
          key: "searchOptionsShort",
          label: "Search Options",
          sortable: true,
          class: "text-center",
        },
        { key: "count", label: "Count", sortable: true, class: "text-center" },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 50, 100, 500],
      vendorOptions: [
        { value: 1, text: "TransUnion" },
        { value: 5, text: "Ability" },
        { value: 6, text: "HealthAxis" },
        { value: 9, text: "Waystar"},
      ],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      selectedStatus: [],
      selectedPayors: [],
      selectedVendor: null,
      allPayors: [],
      filter: null,
      groupBy: [],

      series: [],
      chartOptions: {
        chart: {
          type: "area",
          stacked: false,
          height: 350,
          zoom: {
            type: "x",
            enabled: true,
            autoScaleYaxis: true,
          },
          toolbar: {
            autoSelected: "zoom",
          },
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 0,
        },
        title: {
          text: "Eligibility Movement",
          align: "left",
        },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.5,
            opacityTo: 0,
            stops: [0, 90, 100],
          },
        },
        yaxis: {
          title: {
            text: "Eligibility Count",
          },
        },
        xaxis: {
          type: "datetime",
        },
        tooltip: {
          shared: true,
        },
      },
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  async mounted() {
    // Set the initial number of items
    this.allPayors = await appService.getAllPayors();
    this.allPayors.sort((a, b) =>
      a.payorName > b.payorName ? 1 : b.payorName > a.payorName ? -1 : 0
    );
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async getData() {
      this.loadButton = "Loading...";
      this.loadingData = true;
      try {
        this.fields = [];
        var groupByPayorName = false;
        if (this.groupBy.includes("payor")) {
          groupByPayorName = true;
          this.fields.push({
            key: "payorName",
            label: "Payor Name",
            sortable: true,
            sortDirection: "desc",
          });
        }
        var groupByCompany = false;
        if (this.groupBy.includes("company")) {
          groupByCompany = true;
          this.fields.push({
            key: "companyId",
            label: "Company",
            sortable: true,
            sortDirection: "desc",
          });
        }
        var groupByStatus = false;
        if (this.groupBy.includes("status")) {
          groupByStatus = true;
          this.fields.push({
            key: "status",
            label: "Status",
            sortable: true,
            class: "text-center",
          });
        }
        var groupByNpiCode = false;
        if (this.groupBy.includes("npi")) {
          groupByNpiCode = true;
          this.fields.push({
            key: "npiCode",
            label: "NPI",
            sortable: true,
            class: "text-center",
          });
        }
        var groupByVendor = false;
        if (this.groupBy.includes("vendor")) {
          groupByVendor = true;
          this.fields.push({
            key: "vendor",
            label: "Vendor",
            sortable: true,
            class: "text-center",
          });
        }
        var groupBySearchOptions = false;
        if (this.groupBy.includes("searchOption")) {
          groupBySearchOptions = true;
          this.fields.push({
            key: "searchOptionsShort",
            label: "Search Options",
            sortable: true,
            class: "text-center",
          });
        }
        this.fields.push({
          key: "count",
          label: "Count",
          sortable: true,
          class: "text-center",
        });
        this.items = await reportingService.getEligibilityAggregateData(
          this.companyIds,
          this.start,
          this.end,
          this.selectedStatus,
          this.selectedPayors,
          this.selectedVendor,
          this.sliderValue[0],
          this.sliderValue[1],
          groupByCompany,
          groupByPayorName,
          groupByStatus,
          groupByNpiCode,
          groupByVendor,
          groupBySearchOptions,
          false
        );
        this.totalRows = this.items.length;
      } catch (error) {
        console.error(error);
        this.displayErrorToast("Error Loading Data: " + error.message);
      }
      this.loadButton = "Load Aggregate Data";
      this.loadingData = false;
    },
    async getDataOverTime() {
      this.loadButtonOverTime = "Loading...";
      this.loadingDataOverTime = true;
      try {
        var chartData1 = await reportingService.getEligibilityAggregateData(
          this.companyIds,
          this.start,
          this.end,
          this.selectedStatus,
          this.selectedPayors,
          this.selectedVendor,
          this.sliderValue[0],
          this.sliderValue[1],
          this.groupBy.includes("company"),
          this.groupBy.includes("payor"),
          this.groupBy.includes("status"),
          this.groupBy.includes("npi"),
          this.groupBy.includes("vendor"),
          this.groupBy.includes("searchOption"),
          true
        );
        let seriesMap = new Map();
        for (var i = 0; i < chartData1.length; i++) {
          var label = "Total";
          if (this.groupBy.includes("company")) {
            label = chartData1[i].companyId;
          }
          if (this.groupBy.includes("payor")) {
            label = chartData1[i].payorName;
          }
          if (this.groupBy.includes("status")) {
            label = chartData1[i].status;
          }
          if (this.groupBy.includes("vendor")) {
            label = chartData1[i].vendor;
          }
          if (!seriesMap.has(label)) {
            seriesMap.set(label, []);
          }
          seriesMap
            .get(label)
            .push({ x: chartData1[i].requestDate, y: chartData1[i].count });
        }
        this.series = [];
        for (let [key, value] of seriesMap) {
          console.log(key + " = " + value);
          this.series.push({ name: key, data: value });
        }
        console.log("series", this.series);
      } catch (error) {
        console.error(error);
        this.displayErrorToast("Error Loading Data: " + error.message);
      }
      this.loadButtonOverTime = "Load Data Over Time";
      this.loadingDataOverTime = false;
      this.show = true;
    },
  },
};
</script>
