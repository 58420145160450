<template>
  <b-container fluid>
    <!-- User Interface controls -->
    <b-row>
      <b-col>
        <b-form-group label-cols-sm="2" label="Filter" class="mb-0">
          <b-input-group>
            <b-form-input
              v-model="filter"
              placeholder="Type to Search"
            ></b-form-input>
            <b-input-group-append>
              <b-button variant="primary" :disabled="!filter" @click="filter = ''"
                >Clear</b-button
              >
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label-cols-sm="2" label="Per page" class="mb-0">
          <b-form-select
            v-model="perPage"
            :options="pageOptions"
          ></b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
    <br />

    <!-- Main table element -->
    <b-table
      show-empty
      stacked="md"
      :items="items"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      @filtered="onFiltered"
    >
      <template #cell(actions)="row">
        <!-- <b-button size="sm" @click="info(row.item, row.index, $event.target)" class="mr-1">Info modal</b-button> -->
        <!-- {{ row.detailsShowing ? 'Hide' : 'Show' }} Details -->
        <b-button size="sm" variant="primary" @click="row.toggleDetails">Edit</b-button>
      </template>

      <template slot="row-details" slot-scope="row">
        <b-card>
          <b-container class="bv-example-row">
            <b-row class="my-1">
              <b-col sm="3">Validator Reg Ex</b-col>
              <b-col sm="9">
                <b-form-input
                  v-model="row.item.regExFormat"
                  placeholder="Enter RegEx Validator"
                ></b-form-input>
              </b-col>
            </b-row>
            <b-row class="my-1">
              <b-col sm="3">Member Id Prefix</b-col>
              <b-col sm="9">
                <b-form-input
                  v-model="row.item.prefix"
                  placeholder="Prefix"
                ></b-form-input>
              </b-col>
            </b-row>
            <b-row class="my-1">
              <b-col sm="3">Member Id Suffix</b-col>
              <b-col sm="9">
                <b-form-input
                  v-model="row.item.suffix"
                  placeholder="Suffix"
                ></b-form-input>
              </b-col>
            </b-row>
            <b-row class="my-1">
              <b-col sm="3">Member Id Length</b-col>
              <b-col sm="9">
                <b-form-input
                  v-model="row.item.length"
                  type="number"
                  placeholder="Length"
                ></b-form-input>
              </b-col>
            </b-row>
            <b-row class="my-1">
              <b-col sm="3"></b-col>
              <b-col sm="9" align-self="end">
                <b-button
                  size="sm"
                  @click="save(row.item, row.index, $event.target)"
                  >Save</b-button
                >
              </b-col>
            </b-row>
          </b-container>
        </b-card>
      </template>
    </b-table>

    <b-row>
      <b-col md="6" class="my-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          class="my-0"
        ></b-pagination>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import appService from 'src/pl.service';

export default {
  props: {},
  components: {},
  data() {
    return {
      payorData: null,
      payorId: null,
      payorMap: {},
      text: [],
      items: null,
      fields: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15],
      sortBy: 'payorName',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
    };
  },
  watch: {},
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key };
        });
    },
  },
  async mounted() {
    console.log('PayorAdmin.mounted');
    this.fields = this.getFieldList();

    // Set the initial number of items
    var allPayorValidatorFormatters = await appService.getAllPayorValidatorFormatters();
    var payorValidatorFormatterMap = {};
    for (var i = 0; i < allPayorValidatorFormatters.length; i++) {
      payorValidatorFormatterMap[allPayorValidatorFormatters[i].payorId] =
        allPayorValidatorFormatters[i];
    }

    var allPayors = await appService.getAllPayors();
    var payors = [];
    for (i = 0; i < allPayors.length; i++) {
      var validatorFormatterExists =
        allPayors[i].payorId in payorValidatorFormatterMap;
      var json = {
        payorName: allPayors[i].payorName,
        id: allPayors[i].payorId,
        regExFormat: null,
        prefix: null,
        suffix: null,
        length: null,
      };
      if (validatorFormatterExists) {
        var validatorFormatter =
          payorValidatorFormatterMap[allPayors[i].payorId];
        json.regExFormat = validatorFormatter.regExFormat;
        json.prefix = validatorFormatter.prefix;
        json.suffix = validatorFormatter.suffix;
        json.length = validatorFormatter.length;
        console.log(
          'PayorAdmin.dataExists',
          validatorFormatterExists,
          payorValidatorFormatterMap,
          allPayors[i].payorId,
          json
        );
      }
      payors[i] = json;
    }
    this.payorData = payors;

    this.items = this.payorData;

    this.loadPayorList();
  },
  methods: {
    loadPayorList() {
      console.log('PayorAdmin.loadPayorList');

      this.totalRows = this.items.length;
      console.log('1', this.totalRows);
    },
    getFieldList() {
      return [
        {
          key: 'payorName',
          label: 'Payor',
          sortable: true,
          sortDirection: 'desc',
        },
        { key: 'regExFormat', label: 'Validator Reg Ex', sortable: true },
        { key: 'prefix', label: 'Member Id Prefix', sortable: true },
        { key: 'suffix', label: 'Member Id Suffix', sortable: true },
        { key: 'length', label: 'Member Id Length', sortable: true },
        { key: 'actions', label: 'Actions' },
      ];
    },
    async save(item, index, button) {
      console.log('save', item, index, button);
      var result = await appService.addPayorValidatorFormatters(
        item.id,
        item.regExFormat,
        item.prefix,
        item.suffix,
        item.length
      );
      if (result) {
        item._showDetails = false;
      }
    },
    selected(data) {
      console.log('selected', data);
      this.payorId = data.id;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    // loadData () {
    //   console.log('loadData')
    // }
  },
};
</script>
