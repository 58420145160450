import { SET_GUARANTOR_HOME_VALIDATED_ADDRESS } from 'src/store/actions/types';
import appService from 'src/pl.service';
import { statuses } from 'src/constants/statuses';

/**
 * commits the id to the store using the SET_VALIDATED_ADDRESSES action type
 */
export const setGuarantorHomeValidatedAddress = async ({ commit, state }) => {
  try {
    var guarantorHome = null;
    // var guarantor = await appService.getApplicant(guarantorId ?? state.applicant.guarantorId
    // );
    if (state.guarantor && state.guarantor.homeAddress) {
      guarantorHome = await appService.getValidatedAddress(
        state.guarantor.homeAddress.address1,
        state.guarantor.homeAddress.address2,
        state.guarantor.homeAddress.city,
        state.guarantor.homeAddress.state,
        state.guarantor.homeAddress.zip
      );
    }

    if (guarantorHome !== null && guarantorHome !== 'undefined') {
      commit(SET_GUARANTOR_HOME_VALIDATED_ADDRESS, {
        homeValidatedAddress: guarantorHome,
        status: statuses.success,
      });
    }
  } catch (error) {
    commit(SET_GUARANTOR_HOME_VALIDATED_ADDRESS, {
      status: statuses.failure,
      error,
    });
  }
};
