import { FLAG_DEMOGRAPHICS_INCORRECT } from 'src/store/actions/types';
import appService from 'src/pl.service';
import { statuses } from 'src/constants/statuses';

/**
 * commits the id to the store using the GET_GUARANTOR action type
 */
 export const flagDemographicsIncorrect = async ({ commit, state }, {demographicId, checked}) => {
  try{
    await appService.flagDemographicsAsIncorrect(demographicId ?? state.applicant.demographic.demographicId, checked);
    commit(
      FLAG_DEMOGRAPHICS_INCORRECT,
      {
          status: statuses.success,
        }
      );
    } catch (error) {
  commit(FLAG_DEMOGRAPHICS_INCORRECT, {
    status: statuses.failure,
    error,
  });    
}
};