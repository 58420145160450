import appService from 'src/pl.service';
import { SET_PAYOR_IS_MAPPED } from 'src/store/actions/types';

/**
 * Tries to map a payor through the API and sets the isMapped
 * attribute on the appropriate payor using the
 * SET_PAYOR_IS_MAPPED action type.
 */
export const setPayorIsMapped = async (
  { commit },
  { payorId, companyPayorName, arrayIndex, insuranceType }
) => {
  const data = await appService.addCompanyPayor(payorId, companyPayorName);
  commit(SET_PAYOR_IS_MAPPED, {
    index: arrayIndex,
    type: insuranceType,
    data: data,
  });
};
