import appService from 'src/pl.service';

const QuickSightEmbedding = require("amazon-quicksight-embedding-sdk");
import { bearerTokenKey } from 'src/constants/localStorage';
export default {
    data() {
      return {
        companies: [],
        selectedCompany: -1,
        selectedProcedureCodes: [],
        selectedDashboard: '',
        selectedGeoZips: [],
        selectedGeoSegments: [],
        selectedGeoMsas: [],
        selectedGeoZipType: null,
        selectedTaxonomies: [],
        selectedClaimType: '',
        selectedStartDate: -1,
        selectedEndDate: -1,
        procedureCodes: [],
        baseGeoZips: [],
        geoZips: [],
        geoSegments: [],
        geoMsas: [],
        taxonomies: [],
        dashboards: [],
        claimTypes: [],
        dates: [],
        isInteractiveDashboardUser: false,
        enableEndDate: false,
        includeZeroAmounts: false,
        insuranceTypes: [],
        selectedInsuranceTypes: [],
        selectedModifiers: [],
        modifiers: [],
        selectedPlacesOfService: [],
        placesOfService: [],
        selectedClaimPayers: [],
        baseClaimPayers: [],
        claimPayers: [],
        selectedHierarchies: [],
        hierarchies: [],
        geoZipTypeOptions: [
          { text: 'Geo Zip', value: 'zip' },
          { text: 'Geo Cluster', value: 'segment' },
          //{ text: 'MSA', value: 'msa' } Commenting for possible future use
        ]
      };
    },
    methods: {
      resetIframe() {
        let container = document.getElementById("quicksight-dashboard");

        // replace children of the container so that we create new iframes, rather than adding many to the screen
        if(container !== null){
          container.replaceChildren();
        }
      },
      // Handle company settings result and set the model
      handleSettingsResult(settings) {

        this.selectedGeoZipType = 'zip';

        this.companies = settings.companies.map(x => {
          return {
            value: x.companyId,
            text: x.companyName
          }
        });
        this.procedureCodes = settings.procedureCodes.map(x => {
            return{
                value: x,
                text: x
            }
        })
        this.dashboards = settings.dashboards.map(x => {
            return {
                value: x.dashboardId,
                text: x.displayName
            }
        })
        this.baseGeoZips = settings.geoZips.map(x => {
          return {
              geoZip: x.geoZip,
              geoZipSegment: x.geoZipSegment,
              geoZipMsa: x.geoZipMsa
          }
        })

        let geoZipValues = [];
        let geoZipSegments = [];
        let geoZipMsas = [];
        this.baseGeoZips.forEach(x => {
          let zipFound = geoZipValues.find(zip => zip === x.geoZip);
          if(!zipFound){
            geoZipValues.push(x.geoZip);
          }

          let segmentFound = geoZipSegments.find(seg => seg === x.geoZipSegment)
          if(!segmentFound){
            geoZipSegments.push(x.geoZipSegment);
          }

          let msaFound = geoZipMsas.find(msa => msa === x.geoZipMsa)
          if(!msaFound){
            geoZipMsas.push(x.geoZipMsa);
          }
        });

        this.geoZips = geoZipValues.map(x => {
          return {
            value: x,
            text: x
          }
        })
        this.geoSegments = geoZipSegments.map(x => {
          return {
            value: x,
            text: x
          }
        })
        this.geoMsas = geoZipMsas.map(x => {
          return {
            value: x,
            text: x
          }
        })
        this.taxonomies = settings.taxonomies.map(x => {
          return {
              value: x.code,
              text: x.description
          }
        })
        this.claimTypes = settings.claimTypes.map(x => {
          return {
              value: x.code,
              text: x.code !== 'All' ? x.code + ' - ' + x.description : x.code
          }
        })
        this.dates = settings.dates.map(x => {
          return {
            value: x.id,
            text: x.displayName,
            startDate: x.startDate,
            endDate: x.endDate
          }
        })
        this.insuranceTypes = settings.insuranceTypes.map(x => {
          return {
              value: x.code,
              text: x.code !== 'All' ? x.code + ' - ' + x.description : x.code
          }
        })
        this.modifiers = settings.modifiers.map(x => {
          return {
              value: x.code,
              text: x.code
          }
        })
        this.placesOfService = settings.placesOfService.map(x => {
          return {
              value: x.code,
              text: x.code !== 'All' ? x.code + ' - ' + x.description : x.code
          }
        })
        this.baseClaimPayers = settings.claimPayers.map(x => {
          return {
              value: x.code,
              text: x.description,
              key: x.key
          }
        })
        
        this.claimPayers = this.baseClaimPayers.filter((val, index, self) => self.findIndex(x => x.value === val.value) === index);

        this.hierarchies = settings.payerHierarchies.map(x => {
          return {
              value: x.code,
              text: x.code !== 'All' ? x.code + ' - ' + x.description : x.code
          }
        })

        this.geoZips.unshift({value: 'All', text: 'All'});
        this.geoSegments.unshift({value: 'All', text: 'All'});
        this.geoMsas.unshift({value: 'All', text: 'All'});

        this.selectedCompany = this.companies.find(x => {return x.value === settings.companyId});
        this.selectedDashboard = this.dashboards.find(x => {return x.value === settings.dashboardId});
        this.selectedProcedureCodes = this.procedureCodes.find(x => {return x.value === 'All'});
        this.selectedGeoZips = this.geoZips.find(x => {return x.value === 'All'});
        this.selectedGeoSegments = this.geoSegments.find(x => {return x.value === 'All'});
        this.selectedGeoMsas = this.geoMsas.find(x => {return x.value === 'All'});
        this.selectedTaxonomies = this.taxonomies.find(x => {return x.value === 'All'});
        this.selectedInsuranceTypes = this.insuranceTypes.find(x => {return x.value === 'All'});
        this.selectedClaimType = this.claimTypes.find(x => {return x.value === 'All'});
        this.selectedModifiers = this.modifiers.find(x => {return x.value === 'All'});
        this.selectedPlacesOfService = this.placesOfService.find(x => {return x.value === 'All'});
        this.selectedClaimPayers = this.claimPayers.find(x => {return x.value === 'All'});
        this.isInteractiveDashboardUser = settings.isInteractiveDashboardUser;
        this.selectedStartDate = this.dates[0];
        this.selectedHierarchies = this.hierarchies.find(x => {return x.value === 'All'});

        let dateLength = this.dates.length
        // Only interactive users can select an end date
        if(this.isInteractiveDashboardUser && dateLength > 0){
          this.selectedEndDate = this.dates[0];
        }

        this.getDashboard();
      },
      handleMultiSelections(multi){
        let array = [];
        // Because the vue-multiselect is weird and returns an object if a single is selected
        if(typeof multi === 'object' && !Array.isArray(multi) && multi !== null) {
          array.push(multi.value);
        } else if(Array.isArray(multi)) {
            array = multi.map(x => x.value);
        }

        return array
      },
      handlePayerMulti(multi, baseClaimPayers){
        let array = [];
        // Because the vue-multiselect is weird and returns an object if a single is selected
        if(typeof multi === 'object' && !Array.isArray(multi) && multi !== null) {
          array.push(multi.value);

          if(multi.value === 'All') return array;

        } else if(Array.isArray(multi)) {
            array = multi.map(x => x.value);
        }

        // Filter the base payers based on
        var mappedArray = [];
        for (let i = 0; i < array.length; i++) {

          let filteredClaimPayers = baseClaimPayers.filter(x => { return x.value === array[i] }).map(x => { return x.key })

          if(filteredClaimPayers){
            mappedArray = mappedArray.concat(filteredClaimPayers);
          }

        }

        return mappedArray
      },
      async multiChange(codes){
        // Only run this check if more than one option is selected
        if(!codes || codes.length <= 1) return;

        // If all is selected with another option, remove it
        let index = codes.map(x => x.value).indexOf('All');
        if(index > -1){
          codes.splice(index, 1);
        }
      },
      async getDashboard() {
        let quickSightUrl = await appService.getQuickSightDashboardUrl(this.selectedDashboard.value);

        const embeddingContext = await QuickSightEmbedding.createEmbeddingContext();
        const {
          embedDashboard,
          // embedVisual
        } = embeddingContext;

        this.resetIframe();

        let frameOptions = {
          url: quickSightUrl.url,
          container: document.getElementById("quicksight-dashboard"),
          height: "700px",
          scrolling: "no",
          resizeHeightOnSizeChangedEvent: true,
          iframeResizeOnSheetChange: true,
          width: "97%",
          onChange: (changeEvent, metadata) => {
            console.log('metadata',metadata,changeEvent)
              switch (changeEvent.eventName) {
                  case 'FRAME_MOUNTED': {
                      console.log("Quicksight frame mounted.");
                      break;
                  }
                  case 'FRAME_LOADED': {
                      console.log("QuickSight frame loaded.");
                      document.getElementById("quicksight-dashboard").style.height = "85vh";
                      break;
                  }
              }
          }
        };

        let geoZipParam = [];
        let geoSegments = [];
        let geoMsas = [];
        let multiList = [];

        // Set Geo Zip Param based on the radio button that was selected
        switch (this.selectedGeoZipType) {
          case 'zip':
            multiList = this.handleMultiSelections(this.selectedGeoZips);

            // If All is selected, return all geoZips from the select list
            if(multiList.includes('All')){
              const zips = this.geoZips.slice(1);
              geoZipParam = zips.map(x => {return x.value});
            } else{
              geoZipParam = multiList;
            }
            break;
          case 'segment':
            multiList = this.handleMultiSelections(this.selectedGeoSegments);
            if(multiList.includes('All')){
              const zips = this.geoSegments.slice(1);
              geoSegments = zips.map(x => {return x.value});
            } else{
              geoSegments = multiList;
            }

            geoZipParam = this.baseGeoZips.filter(x => geoSegments.includes(x.geoZipSegment)).map(x => {return x.geoZip});
            break;
          case 'msa':
            multiList = this.handleMultiSelections(this.selectedGeoMsas);
            if(multiList.includes('All')){
              const zips = this.geoMsas.slice(1);
              geoMsas = zips.map(x => {return x.value});
            } else{
              geoMsas = multiList;
            }

            geoZipParam = this.baseGeoZips.filter(x => geoMsas.includes(x.geoZipMsa)).map(x => {return x.geoZip});
            break;
          default:
            break;
        }

        let startDate = '';
        let endDate = '';
        let selectedStart = this.dates.find(x => {
          return x.value === this.selectedStartDate.value;
        });

        if(selectedStart !== null){
          startDate = selectedStart.startDate;
        }

        // Deal with the end dates
        if(!this.isInteractiveDashboardUser && this.dates.length > 0){

          endDate = selectedStart.endDate;

        } else{

          let selectedEnd = this.dates.find(x => {
            return x.value === this.selectedEndDate.value;
          });
  
          endDate = this.enableEndDate && selectedEnd !== null ? selectedEnd.endDate : selectedStart.endDate;

          if(startDate > endDate) {
            this.displayErrorToast('End Date must be after the Start Date');
            return;
          }
        }

        let insuranceTypes = this.handleMultiSelections(this.selectedInsuranceTypes);
        let taxonomies = this.handleMultiSelections(this.selectedTaxonomies);
        let modifiers = this.handleMultiSelections(this.selectedModifiers);
        let placesOfService = this.handleMultiSelections(this.selectedPlacesOfService);
        let selectedPayers = this.handlePayerMulti(this.selectedClaimPayers, this.baseClaimPayers);
        let hierarchies = this.handleMultiSelections(this.selectedHierarchies);
        let procedureCodes = this.handleMultiSelections(this.selectedProcedureCodes);

        let contentOptions = {
          onMessage: async (messageEvent) => {
            console.log('a', messageEvent);
            switch (messageEvent.eventName) {
                case 'PARAMETERS_CHANGED': {
                    // messageEvent.message contains all parameters with active values
                    console.log(JSON.stringify(messageEvent.message));
                    break;
                }
            }
            },
            parameters: [
              {
                Name: 'ProcedureCode',
                Values: procedureCodes
              },
              {
                Name: 'ClaimType',
                Values: this.selectedClaimType.value
              },
              {
                Name: 'GeoZip',
                Values: geoZipParam
              },
              {
                Name: 'Taxonomy',
                Values: taxonomies
              },
              {
                Name: 'StartDate',
                Values: startDate
              },
              {
                Name: 'EndDate',
                Values: endDate
              },
              {
                Name: 'InsuranceTypeCode',
                Values: insuranceTypes
              },
              {
                Name: 'Modifier',
                Values: modifiers
              },
              {
                Name: 'PlaceOfService',
                Values: placesOfService
              },
              {
                Name: 'ZeroAllowed',
                Values: this.includeZeroAmounts ? 'Yes' : 'No'
              },
              {
                Name: 'Payers',
                Values: selectedPayers
              },
              {
                Name: 'ClaimStatus',
                Values: hierarchies
              }
            ]
        };

        await embedDashboard(frameOptions, contentOptions);
      },
    async logout() {
        window.localStorage.setItem(bearerTokenKey, null);
        window.localStorage.setItem('tokenExpiration', null);
        
        //portalUrl is created and stored in QuickLogin.vue
        //Re-route to home logout to remove session info
        let portalUrl = window.localStorage.getItem('portalUrl');
        if(portalUrl != null){
          window.location = `${portalUrl}/Home/Logout`
        }else{
          window.location = '/login?ReturnUrl=%2FClaimsClarity';
        }
      },
      async getSettingsForCompany() {
        let settings = await appService.getCompanyClaimsClaritySettingsForCompany(this.selectedCompany.value);    
        this.handleSettingsResult(settings);
      },
      async init() {
        this.resetIframe();
        // Load the companies and map to something the ui can understand
        let settings = await appService.getCompanyClaimsClaritySettings();
    
        this.handleSettingsResult(settings);
        } 
      },

    async created() {
        await this.init();
      },
}