import appService from 'src/pl.service';
import { GET_RNC_PERMISSIONS } from 'src/store/actions/types';

/**
 * retrieves the "getRescueNetCompanyPermissions" from the API and
 * commits the data to the store using the GET_RNC_PERMISSIONS action type
 */
export const getRescueNetCompanyPermissions = async ({ commit }) =>
  commit(
    GET_RNC_PERMISSIONS,
    await appService.getRescueNetCompanyPermissions()
  );
