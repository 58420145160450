import appService from 'src/pl.service';
import { GET_DEMOGRAPHIC } from 'src/store/actions/types';
import { statuses } from 'src/constants/statuses';

/**
 * retrieves the latest demographic from the API and
 * commits the data to the store using the GET_DEMOGRAPHIC action type
 */
export const getDemographic = async ({ commit, state }, applicantId) => {
  try{
    commit(
      GET_DEMOGRAPHIC,
      {
        data: await appService.getDemographics(applicantId ?? state.applicant.id, false), 
        loading:true,
        status:statuses.success,
      }
      );
    } 
    catch (error) {
    commit(GET_DEMOGRAPHIC, {
      status: statuses.failure,
      error,
    });
  }
};