import appService from 'src/pl.service';
import { GET_WIDGET_VIEWS } from 'src/store/actions/types';
import { getWidgetInitialState } from 'src/utils/getWidgetInitialState';

/**
 * retrieves the widget views from the API and
 * commits the widgets list to the store using the GET_WIDGET_VIEWS action type
 */
export const getWidgetViews = async ({ commit }) => {
  const data = await appService.getWidgetViews();
  if (data.length) {
    commit(GET_WIDGET_VIEWS, {
      ...data[0],
      widgets: data[0].widgets.map(widget => ({
        ...getWidgetInitialState(widget.type),
        ...widget,
      })),
    });
  }
};
