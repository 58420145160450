import { GET_GUARANTOR } from 'src/store/actions/types';
import appService from 'src/pl.service';
import { statuses } from 'src/constants/statuses';

/**
 * commits the id to the store using the GET_GUARANTOR action type
 */
 export const getGuarantor = async ({ commit, state }, guarantorId) => {
try{  
  if ((guarantorId !== null && guarantorId !== 'undefined') || (state.applicant.guarantorId !== null && state.applicant.guarantorId !== 'undefined')) 
  { 
    var guarantor = await appService.getApplicant(guarantorId ?? state.applicant.guarantorId);
    var receivedDemo = await appService.getDemographics(guarantorId ?? state.applicant.guarantorId, false);
    commit(
      GET_GUARANTOR,
      {
          data: guarantor, 
          demographic: receivedDemo,
          status: statuses.success,
        }
      );
  }
   } catch (error) {
    commit(GET_GUARANTOR, {
      status: statuses.failure,
      error,
    });
  }
  };