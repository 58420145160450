import { v4 as uuidv4 } from 'uuid';
import { expandOptions } from 'src/constants/expandOptions';

/**
 * builds and returns the list of default expand options,
 *
 * @returns {array}
 */
export const getDefaultExpandOptions =
  expandOptions?.map(option => ({
    id: uuidv4(),
    name: option.name,
    expandAll: option.expandAll,
  })) ?? [];
