import { SET_BILLING_NEXTOFKIN_VALIDATED_ADDRESS } from 'src/store/actions/types';
import appService from 'src/pl.service';
import { statuses } from 'src/constants/statuses';

/**
 * commits the id to the store using the SET_BILLING_MAILING_VALIDATED_ADDRESS action type
 */
export const setBillingNextOfKinValidatedAddress = async ({
  commit,
  state,
}) => {
  try {
    var validatedAddress = null;
    if (state.applicant.data && state.applicant.data.nextOfKinAddress) {
      validatedAddress = await appService.getValidatedAddress(
        state.applicant.data.nextOfKinAddress.address1 ?? '',
        state.applicant.data.nextOfKinAddress.address2 ?? '',
        state.applicant.data.nextOfKinAddress.city ?? '',
        state.applicant.data.nextOfKinAddress.state ?? '',
        state.applicant.data.nextOfKinAddress.zip ?? ''
      );
    }
    if (validatedAddress) {
      commit(SET_BILLING_NEXTOFKIN_VALIDATED_ADDRESS, {
        validatedAddress: validatedAddress,
        status: 'success',
      });
    }
  } catch (error) {
    commit(SET_BILLING_NEXTOFKIN_VALIDATED_ADDRESS, {
      status: statuses.failure,
      error,
    });
  }
};
