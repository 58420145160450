import clone from 'clone';
import { operations } from 'src/constants/operations';
import { getProfile } from 'src/store/getters/profiles/getProfile';

/**
 * updates the profile's name/widgets/layouts
 */
export const updateProfile = (state, { id, data, status, error }) => {
  let profile = getProfile(state)(id);
  // make sure the profile exists
  if (profile) {
    if (data) {
      profile.name = data.name;
      profile.widgets = clone(data.widgets);
      profile.layouts = clone(data.layouts);
      profile.expandOptions = clone(data.expandOptions);
    }
    profile.operations[operations.update] = {
      status,
      error,
    };
  }
};
