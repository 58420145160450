/**
 * find a cell by its id
 *
 * @returns {object} object {colIndex, cellIndex, cell} when found or {} otherwise
 */
export const findCellById = ({ columns, cellId }) => {
  let found = {};
  columns.every((column, colIndex) =>
    column.cells.every((cell, cellIndex) => {
      if (cell.id === cellId) {
        found = {
          colIndex,
          cellIndex,
          cell,
        };
        return false;
      }
      return true;
    })
  );

  return found;
};
