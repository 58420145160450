import appService from 'src/pl.service';
import { statuses } from 'src/constants/statuses';
import { RUN_DEMOGRAPHICS_TEST } from 'src/store/actions/types';

/**
 * commits the id to the store using the GET_GUARANTOR action type
 */
export const runDemographicsTest = async ({ commit, state }, applicantId) => {
  try {
    commit(RUN_DEMOGRAPHICS_TEST, {
      status: statuses.pending,
    });
    await appService.runDemographics(
      applicantId ?? state.applicant.applicantId
    );
    commit(RUN_DEMOGRAPHICS_TEST, {
      status: statuses.success,
    });
  } catch (error) {
    commit(RUN_DEMOGRAPHICS_TEST, {
      status: statuses.failure,
      error,
    });
  }
};
