import appService from 'src/pl.service';
import { GET_SERVICE_TYPES } from 'src/store/actions/types';

/**
 * Gets all service types from API and maps them so each service type
 * can be easiliy retrieved with the right code.  Then it commits
 * the newly mapped service types to the state using the
 * GET_SERVICE_TYPES action type.
 */
export const getServiceTypes = async ({ commit }) => {
  const data = await appService.getServiceTypes();

  if (data.length) {
    var convertedObject = data.reduce(
      (obj, item) => ({ ...obj, [item.code]: item }),
      {}
    );
    commit(GET_SERVICE_TYPES, convertedObject);
  } else {
    commit(GET_SERVICE_TYPES, {});
  }
};
