import { operations } from 'src/constants/operations';

/**
 * reads and sets the active profile
 */
export const readActiveProfile = (state, { data, status, error }) => {
  state.profiles.active.id = data;
  state.profiles.active.operations[operations.read] = {
    status,
    error,
  };
};
