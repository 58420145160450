import appService from 'src/pl.service';

export default {
  data() {
    return {
      fields: [
        { key: 'cache', sortable: true },
        { key: 'cache_key', sortable: true },
        { key: 'server', sortable: true },
        { key: 'buttons', sortable: false },
      ],
      data: [],
      seenDetails: {},
      timer: null,
      filter: null,
      sortBy: 'cache',
      sortDesc: false,
      lastUpdated: null,
      totalItems: 0,
      serverDetails: [],
      cacheDetails: [],
      perPage: 50,
      pageOptions: [25, 50, 100, 200],
      currentPage: 1,
      totalRows: 1,
    };
  },
  methods: {
    async doStuff() {
      this.seenDetails = {};
      await this.loadCacheData();
    },
    async loadCacheData() {
      this.totalItems = 0;
      this.serverDetails = [];
      this.cacheDetails = [];
      this.data = [];

      var cacheKeys = await appService.getCacheKeys();
      if (cacheKeys) {
        for (var cache of cacheKeys) {
          if (cache.keys) {
            this.lastUpdated = new Date().toLocaleString();

            this.serverDetails.push({
              name: cache.serverName,
              count: cache.keys.length,
            });

            for (var key of cache.keys) {
              var cacheType = this.getCacheType(key);
              if (this.cacheDetails.findIndex(c => c.name === cacheType) < 0) {
                this.cacheDetails.push({
                  name: cacheType,
                  count: 0,
                });
              }

              this.cacheDetails.find(x => x.name === cacheType).count++;

              this.data.push({
                cache: cacheType,
                cache_key: key,
                server: cache.serverName,
                details: {
                  isArray: false,
                  data: {},
                },
                errorMessage: '',
              });
            }
          }
        }
        this.totalItems = this.data.length;
        this.totalRows = this.data.length;
      }
    },
    async getCacheObject(cacheKey) {
      if (!(cacheKey in this.seenDetails)) {
        var cacheObject = await appService.getCacheObject(cacheKey);
        if (cacheObject) {
          try {
            var parsedStr = JSON.parse(cacheObject);
            this.seenDetails[cacheKey] = parsedStr;
            var rowData = this.data.find(x => x.cache_key === cacheKey);
            if (rowData) {
              rowData.details.isArray = Array.isArray(parsedStr);
              rowData.details.data = parsedStr;
            }
          } catch (error) {
            var errorRowData = this.data.find(x => x.cache_key === cacheKey);
            if (errorRowData) {
              errorRowData.errorMessage = cacheObject;
            }
          }
        }
      }
    },
    async toggleCacheDetails(data) {
      data.toggleDetails();
      if (!data.detailsShowing) {
        await this.getCacheObject(data.item.cache_key);
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getCacheType(key) {
      var cacheType = 'Unknown';
      if (key) {
        if (key.startsWith('ApplicantVisitCount')) {
          cacheType = 'ApplicantVisitCount';
        } else if (key.startsWith('Company')) {
          cacheType = 'Company';
        } else if (key.startsWith('CPayorCidPid')) {
          cacheType = 'CompanyPayor';
        } else if (key.startsWith('User')) {
          cacheType = 'User';
        } else if (key.startsWith('MedicaidPayor')) {
          cacheType = 'MedicaidPayor';
        } else if (
          key.startsWith('PayorSearhOptions') ||
          key.startsWith('PayorSearchOption')
        ) {
          cacheType = 'PayorSearchOptions';
        } else if (key.startsWith('PayorServiceType')) {
          cacheType = 'PayorServiceType';
        } else if (key.startsWith('ServiceType')) {
          cacheType = 'ServiceType';
        } else if (key.startsWith('s2p')) {
          cacheType = 'Subscription2Product';
        } else if (key.startsWith('Subscription')) {
          cacheType = 'Subscription';
        } else if (key.startsWith('VendorIdLookups')) {
          cacheType = 'VendorIdLookups';
        } else if (key.startsWith('Vendors')) {
          cacheType = 'Vendors';
        } else if (key.startsWith('Npi')) {
          cacheType = 'Npi';
        } else if (key.startsWith('Payor')) {
          cacheType = 'Payor';
        }
      }

      return cacheType;
    },
    hasDetails(details) {
      if (details.isArray) {
        return details.data.length !== 0;
      } else if (typeof details.data === 'object' && details.data !== null) {
        return Object.keys(details.data).length !== 0;
      }
      return details.data && details.data.length;
    },
    async clearCache() {
      var data = await appService.fullCacheFlush();
      if (data && data.length > 2) {
        this.displaySuccessToast('Cache Cleared Successfully');
      }
      this.doStuff();
    },
    getVariant(detailsShowing) {
      if (detailsShowing) {
        return 'warning';
      }
      return 'secondary';
    },
  },
  async created() {
    this.timer = setInterval(() => {
      this.doStuff();
    }, 300000);
    this.doStuff();
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  },
};
