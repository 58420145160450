import appService from 'src/pl.service';
import { GET_INSURANCE_FINDER } from 'src/store/actions/types';
import { statuses } from 'src/constants/statuses';

/**
 * retrieves the insurance finder from the API and
 * commits the data to the store using the GET_INSURANCE_FINDER action type
 */
export const getInsuranceFinder = async ({ commit, state }, applicantId) => {
  commit(GET_INSURANCE_FINDER, { status: statuses.pending });
  try {
    var data = await appService.getInsuranceFinder(
      applicantId ?? state.applicant.id
    );
    commit(GET_INSURANCE_FINDER, {
      status: statuses.success,
      data: data,
      retrieved: true,
    });
  } catch (error) {
    commit(GET_INSURANCE_FINDER, {
      status: statuses.failure,
      error: error,
      retrieved: true,
    });
  }
};
