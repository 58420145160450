<template>
  <div class="content">
    <div v-if="isAuthenticated">
      Hello authenticated user!
      <div>
        <input v-model="applicantId" placeholder="applicantId" />
        <p>ApplicantId to Load: {{ applicantId }}</p>

        <button v-on:click="loadApplicant()" class="button is-primary">
          Load Applicant
        </button>
      </div>
      <button v-on:click="logout()" class="button is-primary">
        Logout
      </button>
    </div>
    <div v-else>
      <h2>Login</h2>
      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">Username</label>
        </div>
        <div class="field-body">
          <div class="field">
            <div class="control">
              <input
                v-model="username"
                class="input"
                type="text"
                placeholder="Your username"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">Password</label>
        </div>
        <div class="field-body">
          <div class="field">
            <div class="control">
              <input
                v-model="password"
                class="input"
                type="password"
                placeholder="Your password"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="field is-horizontal">
        <div class="field-label">
          <!-- Left empty for spacing -->
        </div>
        <div class="field-body">
          <div class="field">
            <div class="control">
              <!-- <button v-on:click="login()" class="button is-primary">
                Login
              </button> -->
              <button
                class="btn btn-primary"
                variant="outlined"
                color="primary"
                v-on:click="login()"
                >Login
            </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { bearerTokenKey } from 'src/constants/localStorage';
// import { identify } from 'src/utils/logRocket/identify';
import appService from 'src/pl.service';

export default {
  components: {
  },
  data() {
    return {
      username: '',
      password: '',
      applicantId: '',
      isAuthenticated: false,
      profile: {},
    };
  },
  // watch: {
  //   isAuthenticated: function (val) {
  //     if (val) {
  //       appService.getProfile()
  //         .then(profile => {
  //           this.profile = profile
  //         })
  //     } else {
  //       this.profile = {}
  //     }
  //   }
  // },
  methods: {
    testClick() {
      console.log('clicked outlined button', Date.now());
    },
    login() {
      appService
        .login({
          username: this.username,
          password: this.password,
        })
        .then(data => {
          window.localStorage.setItem(bearerTokenKey, data.bearer);
          window.localStorage.setItem('tokenExpiration', data.tokenExpiry);
          window.localStorage.setItem('roleId', data.roleId);
          this.isAuthenticated = true;
          this.username = '';
          this.password = '';
          // identify();
          if (this.$route.query.ReturnUrl) {
            var absoluteUrl = new RegExp('^(?:[a-z+]+:)?//', 'i');
            if (absoluteUrl.test(this.$route.query.ReturnUrl))
            {
              window.location = '/login';
            } else {
              window.location = this.$route.query.ReturnUrl;
            }
          }
        })
        .catch(() => window.alert('Could not login!'));
    },
    logout() {
      window.localStorage.setItem(bearerTokenKey, null);
      window.localStorage.setItem('tokenExpiration', null);
      this.isAuthenticated = false;
    },
    loadApplicant() {
      window.location = '/fullPatientData/' + this.applicantId;
    },
  },
  created() {
    window.localStorage.removeItem('appConfig.WebApiUrl');
    let expiration = window.localStorage.getItem('tokenExpiration');
    var unixTimestamp = new Date().getTime() / 1000;
    if (expiration !== null && parseInt(expiration) - unixTimestamp > 0) {
      this.isAuthenticated = true;
    }
  },
};
</script>
