import { v4 as uuidv4 } from 'uuid';
import clone from 'clone';
import { statuses } from 'src/constants/statuses';
import { CREATE_PROFILE } from 'src/store/actions/types';
import appService from 'src/pl.service';

/**
 * retrieves the user profiles from the API and
 * commits the data using READ_PROFILES action type.
 */
export const createProfile = async ({ commit }, profile) => {
  // clone and assign a temporary ID so we can track its status...
  const newProfile = clone(profile);
  const id = uuidv4();
  commit(CREATE_PROFILE, { id, status: statuses.pending });

  try {
    newProfile.id = await appService.createProfile(profile);
    commit(CREATE_PROFILE, {
      id,
      data: newProfile,
      status: statuses.success,
    });
  } catch (error) {
    commit(CREATE_PROFILE, {
      id,
      status: statuses.failure,
      error,
    });
  }
};
