<template>
  <b-modal id="modal-userPermissions" size="sm" title="Edit Permissions" v-on:show="permissionsMounted">
    <div class="label">User</div>
    <div class="field">
      <div class="dropdownxx">
        <select v-model="selectedUser">
          <option value="-1" id="-1">Default</option>
          <option
            v-for="(users, index) in users"
            v-bind:value="index"
            v-bind:key="users.id"
          >
            {{ users.username }}
          </option>
        </select>
      </div>
    </div>
    <b-form-group class="mt-2" v-slot="{ ariaDescribedby }">
        <b-form-checkbox-group
          id="permissions-checkbox-group"
          :options="options"
          :aria-describedby="ariaDescribedby"
          v-model="checkedNames"
          stacked
        ></b-form-checkbox-group>
    </b-form-group>
    <template #modal-footer="{ cancel }">
      <b-button size="sm" variant="danger" @click="cancel()">
        Cancel
      </b-button>
      <b-button size="sm" variant="success" @click="save()">
        Save
      </b-button>
    </template>
  </b-modal>
</template>
<script>
import appService from 'src/pl.service';

export default {
  props: {},
  components: {},
  data: function() {
    return {
      users: null,
      selectedUser: -1,
      checkedNames: [],
      options: [
        { text: 'Run Demographics', value: 0},
        { text: 'Update from Demographics', value: 1},
        { text: 'Run Self Pay', value: 2},
        { text: 'Run Insurance Verifier', value: 3},
        { text: 'Run Insurance Discovery', value: 4},
        { text: 'View Applicant', value: 5},
        { text: 'Flag as Incorrect', value: 9},
        { text: 'Admin', value: 7},
      ]
    };
  },
  watch: {
    selectedUser: function() {
      console.log('selectedUser', this.selectedUser);
      this.getPermissions(this.getUserId());
    },
  },
  computed: {},
  methods: {
    getPermissions(userId) {
      appService
        .getPermissionsForUser(userId)
        .then(data => {
          this.checkedNames = [];
          for (var i = 0; i < data.length; i++) {
            this.checkedNames[i] = data[i].permission + '';
          }
          console.log('this.checkedNames', this.checkedNames);
          return data;
        })
        .catch(function(error) {
          console.error(error);
        });
    },
    getUserId() {
      var userId = -1;
      var selectedUserId = Number(this.selectedUser);
      if (selectedUserId !== -1) {
        var user = this.users[this.selectedUser];
        userId = user.userId;
      }
      console.log('getUserId', userId);
      return userId;
    },
    close() {
      this.$emit('close', null);
    },
    save() {
      console.log('this.checkedNames', this.checkedNames);
      var products = [];
      for (var i = 0; i < this.checkedNames.length; i++) {
        products[i] = Number(this.checkedNames[i]);
      }

      try {
        appService.updatePermissions(this.getUserId(), products);
      } catch (error) {
        console.error(error);
        this.displayErrorToast('UserPermissionsModal.ok: ' + error.message);
      }
      this.$bvModal.hide("modal-userPermissions");
    },
    async permissionsMounted() {
    try {
      console.log('UserPermissionsModal1.mounted');

      var allUsers = await appService.getAllUsersForCompany();
      console.log('UserPermissionsModal.allUsers', allUsers);
      this.users = allUsers;

      console.log('UserPermissionsModal.this.users.length', this.users.length);

      console.log('UserPermissionsModal.this.users', this.users);

      this.getPermissions(-1, -1);
    } catch (error) {
      console.log('Error in modal', error);
    }
  },
  },

};
</script>
