import appService from 'src/pl.service';
import { GET_MY_RNA_PERMISSIONS } from 'src/store/actions/types';

/**
 * retrieves the "getMyPermissionsForRescueNetApplicant" from the API and
 * commits the data to the store using the GET_MY_RNA_PERMISSIONS action type
 */
export const getMyPermissionsForRescueNetApplicant = async (
  { commit, state },
  rnCompanyId
) =>
  commit(
    GET_MY_RNA_PERMISSIONS,
    await appService.getMyPermissionsForRescueNetApplicant(
      rnCompanyId ?? state.applicant.visits.data[0].data?.companyId
    )
  );
