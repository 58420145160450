/**
 * the possible grid layouts,
 * depending on the current window.width
 */
export const layouts = {
  one: {
    columns: 1,
    maxWidth: 640,
  },
  two: {
    columns: 2,
    maxWidth: 1024,
  },
  three: {
    columns: 3,
    maxWidth: 1280,
  },
  four: {
    columns: 4,
    maxWidth: Number.POSITIVE_INFINITY,
  },
};

/**
 * cell types
 */
export const cellTypes = {
  // a regular cell, that contains a widget
  normal: {
    type: 'normal',
    colSpan: 1,
    componentIsLoaded: false,
    component: () => {},
  },
  /**
   * a mirror placeholder component, that syncs the height of
   * the cell it is supposed to mirror.
   *
   * its job is to reserve vertical space and
   * "push" content downwards or "pull" content upwards,
   * when its origin cell grows or shrinks in height (ie. due to expand/collapse of accordions)
   */
  mirror: {
    type: 'mirror',
    colSpan: 1,
    componentIsLoaded: true,
    component: () => ({
      component: import(
        'src/components/FullPatientData/PatientWidgetBody/MirrorWidget/MirrorWidget'
      ),
    }),
    meta: {
      // source cell id that this mirrors
      sourceId: undefined,
    },
  },
};
