import clone from 'clone';
import Vue from 'vue';
export const setGuarantorHomeValidatedAddress = (
  state,
  { homeValidatedAddress, status, error }
) => {
  if (state.guarantor && state.guarantor.data) {
    Vue.set(
      state.guarantor.data,
      'homeValidatedAddress',
      clone(homeValidatedAddress)
    );
  }
  if (state.guarantor.data.homeValidatedAddress) {
    state.guarantor.data.homeValidatedAddress.status = status;
    state.guarantor.data.homeValidatedAddress.error = error;
  }
};
