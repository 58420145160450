import clone from 'clone';

/**
 * maps to data that should be in the store
 *
 * @param {object} layout
 * @returns array
 */
export const parseLayoutColumns = columns =>
  columns.map(column => ({
    cells: column.cells.map(cell => ({
      id: cell.id,
      colSpan: cell.colSpan,
      type: cell.type,
      meta: clone(cell.meta),
    })),
  }));
