export const permissionTypes = {
  canRunDemographics: 0,
  canUpdateRnFromDemographics: 1,
  canRunSelfPay: 2,
  canRunInsuranceVerifier: 3,
  canRunInsuranceDiscovery: 4,
  canViewApplicant: 5,
  canViewPCR: 6,
  isAdmin: 7,
  canFlagAsIncorrect: 9,
  viewDemographics: 10,
  viewSelfPay: 11,
  viewInsuranceWidget: 12,
  viewTripDetails: 13,
  viewAttachments: 14,
};

export const productNames = {
  deductibleMonitoring: 'Deductible Monitoring',
};
