import { findElTop } from 'src/components/FullPatientData/PatientWidgetBody/Grid/utils/findElTop';
import { cellTypes } from 'src/constants/grid';

/**
 * handles the synchronization of all mirror related cells.
 * it makes sure they have the same height and are located
 * at the same height with each other.
 *
 * @see handleColumnResizeObserver
 */
export const synchronizeMirrors = ({ column, columns }) => {
  // find the cells that span multiple columns AND/OR
  // the mirror cells of this column that resized
  // basically, get all "mirror related" cells
  column.cells
    .filter(cell => cell.type === cellTypes.mirror.type || cell.colSpan > 1)
    .forEach(mCell => {
      // depending on what cell this is, determine the source cell id
      let sourceId =
        mCell.type === cellTypes.mirror.type ? mCell.meta.sourceId : mCell.id;

      // find its corresponding cells and get the height of the source cell
      //  - if its a mirror, find the source and other mirrors
      //  - if its a cell that spans multiple columns, find its mirrors
      let sourceHeight = 0;
      const cells = columns
        .map(column =>
          column.cells.filter(cell => {
            // find if this cell is related
            const related =
              cell.id === sourceId || cell.meta.sourceId === sourceId;
            // if related and that's the source, keep its height
            if (related && cell.type === cellTypes.normal.type) {
              sourceHeight = cell.componentInstance.$el.clientHeight;
            }
            return related;
          })
        )
        .flat();

      // calculate the max top between the widget and all its mirrors.
      // note that it will not really be the actual pos.top of those elements
      // but it will be the pos.bottom of their previous elements instead.
      // reason being, the pos.top will be a moving target as we start pushing
      // them down/up, whereas their previous element can be considered
      // as anchors.

      // find the max top
      let maxTop = 0;
      cells.forEach(cell => {
        const top = findElTop(cell.componentInstance.$el);
        if (top > maxTop) {
          maxTop = top;
        }
      });

      // apply the proper margin on top of each element and
      // set the height to all the mirrors (but not to the source)
      cells.forEach(cell => {
        const el = cell.componentInstance.$el;
        const top = maxTop - findElTop(el);
        if (top > 0) {
          // when we need to push down, add margin
          el.style.marginTop = `${top}px`;
        } else {
          // otherwise, just reset the top margin
          el.style.marginTop = 0;
        }
        if (cell.type === cellTypes.mirror.type) {
          el.style.height = `${sourceHeight}px`;
        }
      });
    });
};
