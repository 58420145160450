import appService from 'src/pl.service';
// import clone from 'clone';
import { setApplicantVisitSignatures } from './applicantVisitsHelpers/setApplicantVisitSignatures';
import { setApplicantVisitTripAddresses } from './applicantVisitsHelpers/setApplicantVisitTripAddresses';
import { GET_APPLICANT_VISITS } from 'src/store/actions/types';

/**
 * retrieves the applicant visits from the API and
 * commits the data to the store using the GET_APPLICANT_VISITS action type
 */
export const getApplicantVisits = async ({ commit, state }, applicantId) => {
  const data = await appService.getApplicantVisits(
    applicantId ?? state.applicant.id
  );

  await setApplicantVisitSignatures(data);
  await setApplicantVisitTripAddresses(data);

  commit(GET_APPLICANT_VISITS, data);
};
