/**
 * handles the drag start event of a cell.
 *
 * it must be called once from within the Grid component, so that it "binds" to each cell.
 * the returned function, should then be passed down to the widget component of the cell,
 * so that it is used on the drag start handle.
 *
 * @returns {function}
 */
export const dragStartHandler = cell => e => {
  e.dataTransfer.effectAllowed = 'move';
  e.dataTransfer.dropEffect = 'move';
  e.dataTransfer.setDragImage(
    cell.componentInstance.$el,
    e.pageX - cell.componentInstance.$el.offsetLeft,
    e.pageY - cell.componentInstance.$el.offsetTop
  );
  e.dataTransfer.setData('cellId', cell.id);
};
