import clone from 'clone';
export const setApplicantHomeValidatedAddress = (state, {homeValidatedAddress, status, error}) => {
    if(homeValidatedAddress !== null && homeValidatedAddress !== 'undefined')
    {
        state.applicant.homeValidatedAddress = clone(homeValidatedAddress);
        if(state.applicant.homeValidatedAddress)
        {
            state.applicant.homeValidatedAddress.status = status;
            state.applicant.homeValidatedAddress.error = error;
        }
    }    
};