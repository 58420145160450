/**
 * modifies the columns param, to not include the cell with the id provided.
 */
export const removeCellById = ({ columns, cellId }) => {
  columns.forEach(column => {
    const cellIndex = column.cells.findIndex(cell => cell.id === cellId);
    if (cellIndex !== -1) {
      column.cells.splice(cellIndex, 1);
    }
  });
};
