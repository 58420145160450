import clone from 'clone';
import { applicant } from 'src/store/initialState/applicant';

/**
 * mutates the applicant.id property with the id given
 * when this happens, all the applicant data needs to reset to default
 * since it is now irrelevant
 */
export const setApplicantId = (state, id) => {
  state.applicant = {
    ...clone(applicant),
    id,
  };
};
