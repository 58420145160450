import { readProfiles } from './readProfiles';
import { createProfile } from './createProfile';
import { updateProfile } from './updateProfile';
import { resetProfile } from './resetProfile';
import { updateActiveProfile } from './updateActiveProfile';
import { readActiveProfile } from './readActiveProfile';
import { layouts } from './layouts/index';

export const profiles = {
  readProfiles,
  createProfile,
  updateProfile,
  resetProfile,
  readActiveProfile,
  updateActiveProfile,
  ...layouts,
};
