import { calcAvgColHeight } from 'src/components/FullPatientData/PatientWidgetBody/Grid/utils/calcAvgColHeight';

/**
 * iterates the column(s) and their cell(s),
 * in an attempt to spread the cells equally in the columns.
 *
 * note that this will actually create new instances of the cells/widgets as they move columns
 * which means, depending on where/how that data are stored and retrieved (ie. vuex or component state)
 * it can cause a "reload" of the same data. we can optimize each widget to prevent this from happening
 * or try to move them, instead of coping them...
 */
export const spreadCells = ({ columns }) => {
  // first of all, we need to find out the average height of all the columns
  // so that we can try to evenly spread the cells across the columns
  const avgColHeight = calcAvgColHeight(columns);

  // next, iterate each column -> cell and
  // move the cells to right when they do not fit within the average Column Height
  columns.forEach((column, colIndex) => {
    let currentCellsHeight = 0;
    let cutoffCelIndex = undefined;
    column.cells.forEach((cell, celIndex) => {
      const cellHeight = cell.componentInstance?.$el?.clientHeight;

      // if adding the cell will overflow the column height OR
      // if have are already started pushing the cells to the next column... AND
      // this is NOT the first (and potentially the only) cell in the list
      if (
        (currentCellsHeight + cellHeight > avgColHeight ||
          cutoffCelIndex !== undefined) &&
        celIndex > 0
      ) {
        // move cell to the next column, if that's possible
        if (columns[colIndex + 1]) {
          // copy it to the next column...
          columns[colIndex + 1].cells.push(cell);

          // if this is the first cell for this column we are moving
          // keep the cutoff cell index for the cleanup later
          if (cutoffCelIndex === undefined) {
            cutoffCelIndex = celIndex;
          }
        }
      } else {
        // keep the cell here and increase height
        currentCellsHeight += cellHeight;
      }
    });
    // now that the cells have been copied
    // remove them from the old column
    if (cutoffCelIndex !== undefined) {
      columns[colIndex].cells.splice(cutoffCelIndex);
    }
  });
};
