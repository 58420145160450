<template>
    <footer class="footer">
      <div class="container">
        <ul class="footer-links">
          <li><a target="_blank" href="https://www.zolldata.com/customer-community" >Contact Support</a></li>
          <li><a target="_blank" href="/ReleaseNotes">Release Notes</a></li>
          <li><a target="_blank" href="https://help.zollonline.com/ARboost/Content/Home.htm">Help Guides</a></li>
        </ul>
      </div>
    </footer>
  </template>
  
  <style scoped>
  .footer {
    background-color: #f8f9fa;
    padding: 20px 0;
    text-align: center;
    border-top: 1px solid #e7e7e7;
  }
  .footer-links {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .footer-links li {
    display: inline;
    margin: 0 10px;
  }
  .footer-links a {
    color: #0A59A7;
    text-decoration: none;
  }
  .footer-links a:hover {
    text-decoration: underline;
  }
  </style>


<script>
export default {
    name: 'footer_view'
}
</script>