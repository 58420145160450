/**
 * calculates the average column height,
 * of all the columns and their cells by taking into consideration,
 * cells that span multiple columns.
 *
 * @param {array} columns
 * @returns {number}
 */
export const calcAvgColHeight = columns =>
  Math.floor(
    columns.reduce(
      (colAcc, column) =>
        colAcc +
        column.cells.reduce(
          (cellAcc, cell) =>
            cellAcc +
            cell.componentInstance?.$el?.clientHeight *
              (cell.colSpan > 1 ? cell.colSpan * 1.2 : cell.colSpan),
          0
        ),
      0
    ) / columns.length
  );
