import { VALIDATE_INSURANCE } from 'src/store/actions/types';
import { statuses } from 'src/constants/statuses';
import appService from 'src/pl.service';

/**
 * Runs insurance validation through the API and commits results to the state
 * using the VALIDATE_INSURANCE action type.
 */
export const validateInsurance = async (
  { commit, state },
  { applicantId, payorName, subscriberId, arrayIndex, insuranceType }
) => {
  commit(VALIDATE_INSURANCE, {
    status: statuses.pending,
  });
  try {
    var validated = false;
    var npi = undefined;
    const applicantVisit = state.applicant.visits?.data[0];
    if (applicantVisit.data && applicantVisit.data.npi) {
      npi = applicantVisit.data.npi;
    }
    validated = await appService.validateInsurance(
      applicantId ?? state.applicant.id,
      payorName,
      subscriberId,
      npi
    );
    commit(VALIDATE_INSURANCE, {
      status: validated ? statuses.success : statuses.failure,
      index: arrayIndex,
      type: insuranceType,
    });
  } catch (error) {
    commit(VALIDATE_INSURANCE, {
      status: statuses.failure,
      index: arrayIndex,
      type: insuranceType,
      error,
    });
  }
};
