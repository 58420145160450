export default {
  data() {
    return {
      /**
       * the wrapper element reference.
       * this element is used for calculating the height of each column.
       * we need this extra nested element, because the root column element
       * takes the height of its parent and not of its cell contents.
       */
      wrapperEl: null,
    };
  },
  props: {
    mountedCallback: {
      type: Function,
      required: true,
    },
    unmountedCallback: {
      type: Function,
      required: true,
    },
  },
  mounted() {
    // grab the wrapper element which changes its height,
    // according to its contents (cells/widgets)
    this.wrapperEl = this.$el.querySelector('.wrapper');

    // delegate
    this.mountedCallback(this);
  },
  beforeDestroy() {
    // delegate
    this.unmountedCallback(this);

    // reset after unmountedCallback because
    // the observer there needs it
    this.wrapperEl = null;
  },
};
