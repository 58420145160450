import { v4 as uuidv4 } from 'uuid';
import clone from 'clone';
import appService from 'src/pl.service';
import { UPDATE_PROFILE } from 'src/store/actions/types';
import { getActiveProfile } from 'src/store/getters/profiles/getActiveProfile';
import { statuses } from 'src/constants/statuses';
import { getWidgetByType } from 'src/utils/getWidgetByType';

/**
 * adds the widget (using its type) to the active profile
 */
export const addProfileWidget = async ({ commit, state }, { type }) => {
  // find the profile we need to update in the state and
  // the widget we need to add
  const uProfile = clone(getActiveProfile(state));
  const widget = getWidgetByType(type);

  if (uProfile && widget) {
    // add the widget to the profile
    uProfile.widgets.push({
      id: uuidv4(),
      colSpan: widget.colSpan,
      meta: {
        type,
      },
    });

    try {
      // update
      commit(UPDATE_PROFILE, {
        id: uProfile.id,
        status: statuses.pending,
      });
      await appService.updateProfile(uProfile);
      commit(UPDATE_PROFILE, {
        id: uProfile.id,
        data: uProfile,
        status: statuses.success,
      });
    } catch (error) {
      commit(UPDATE_PROFILE, {
        id: uProfile.id,
        status: statuses.failure,
        error,
      });
    }
  }
};
