<template>
  <div>
    <b-modal id="modal-downPayorList" size="lg" v-on:show="loadDownPayors" title="Unhealthy Payor List" hide-footer>
      <div>
        <b-container class="bv-example-row">
          <b-row>
            <b-col>
              <b-form-group label="Time Span" v-slot="{ ariaDescribedby }">
                <b-form-radio-group id="radio-group-2" v-model="selectedTimeSpan" :aria-describedby="ariaDescribedby"
                  name="radio-sub-component"  @change="refresh()">
                  <b-form-radio value="5">5 Min</b-form-radio>
                  <b-form-radio value="60">1 Hour</b-form-radio>
                  <b-form-radio value="1440">Today</b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </b-col>
            <b-col><label for="range-1">Error Threshold: {{errorThreshold}}%</label>
              <b-form-input id="range-1" v-model="errorThreshold" type="range" min="0" step="10" max="100" @change="refresh()">
              </b-form-input>
            </b-col>
          </b-row>
        </b-container>
        <ul>
          <li class="rowxx row__compact">
            <div class="label" style="font-weight:bold">Payor Name - Error Rate %</div>
          </li>
          <li class="rowxx row__compact" v-for="(downPayor, index) in downPayors" v-bind:value="index"
            v-bind:key="downPayor.id">
            <div class="label">
              {{ downPayor.payorName }} - {{ downPayor.errorRate }}%
            </div>
          </li>
        </ul>
      </div>
    </b-modal>
  </div>
</template>
<script>
  import appService from 'src/pl.service';

  export default {
    props: {},
    components: {},
    data: function () {
      return {
        downPayors: [],
        selectedTimeSpan: 60,
        errorThreshold: 50
      };
    },
    methods: {
      close() {
        this.$emit('close', null);
      },
      async refresh() {
        console.log('refresh')
           this.downPayors = await appService.getDownPayors(this.selectedTimeSpan, this.errorThreshold);
                     console.log('downPayors', this.downPayors);

      },
      async loadDownPayors() {
        try {
          //var allPayors = await appService.getAllPayors();
          this.downPayors = await appService.getDownPayors(this.selectedTimeSpan, this.errorThreshold);
          // var payorsDown = [];
          // for (var j = 0; j < downPayorList.length; j++) {
          //   var localPayor = downPayorList[j];
          //   for (var i = 0; i < allPayors.length; i++) {
          //     if (allPayors[i].payorId === downPayorList[j].payorId) {
          //       localPayor.payorName = allPayors[i].payorName;
          //     }
          //   }
          //   payorsDown[j] = localPayor;
          // }
          // this.downPayors = payorsDown;
          console.log('downPayors', this.downPayors);
        } catch (error) {
          console.log('Error in modal', error);
        }
      }
    },
    async created() {

    },
  };

</script>
