import axios from "axios";

const appService = {
  async getBillingCompanies() {
    const { data } = await axios.get(`/api/v1.0/billing/billingCompany`);
    console.log("billingService.getBillingCompanies:", data);
    return data;
  },
  async getBillingProducts() {
    const { data } = await axios.get(`/api/v1.0/billing/billingProduct`);
    console.log("billingService.getBillingProducts:", data);
    return data;
  },
  async getInvoiceData(billingCompanyId, start, end, billingProductId) {
    var url = `/api/v1.0/billing/invoiceData?billingCompanyId=${billingCompanyId}&start=${start}&end=${end}&billingProductId=${billingProductId}`;
    console.log("plService.getInvoiceData:", url);
    const { data } = await axios.get(url);
    console.log("plService.getInvoiceData:", data);
    return data;
  },
  async getGroupedInvoiceData(billingCompanyIds, start, end) {
    // var url = `/api/v1.0/billing/invoiceDataGrouped?billingCompanyId=${billingCompanyId}&start=${start}&end=${end}`
    var billingCompanyUrl = "";
    for (var i = 0; i < billingCompanyIds.length; i++) {
      billingCompanyUrl += `&bcId=${billingCompanyIds[i]}`;
    }
    var url = `/api/v1.0/billing/invoiceDataGrouped?start=${start}&end=${end}${billingCompanyUrl}`;
    console.log("plService.getGroupedInvoiceData:", url);
    const { data } = await axios.get(url);
    console.log("plService.getGroupedInvoiceData:", data);
    return data;
  },
  async getGroupedProductData(companyIds, start, end) {
    var companyUrl = "";
    for (var i = 0; i < companyIds.length; i++) {
      companyUrl += `&cId=${companyIds[i]}`;
    }
    var url = `/api/v1.0/billing/productDataGrouped?start=${start}&end=${end}${companyUrl}`;
    console.log("plService.getGroupedProductData:", url);
    const { data } = await axios.get(url);
    console.log("plService.getGroupedProductData:", data);
    return data;
  },
  // async buildRequest2ProductData (companyId, start, end) {
  //   var url = `/api/v1.0/billing/buildRequest2ProductData?companyId=${companyId}&start=${start}&end=${end}`
  //   console.log('plService.buildRequest2ProductData:', url)
  //   const { data } = await axios.get(url)
  //   console.log('plService.buildRequest2ProductData:', data)
  //   return data
  // },
  async getRequest2ProductCounts(companyId, start, end, productId) {
    var url = `/api/v1.0/billing/request2ProductCount?companyId=${companyId}&start=${start}&end=${end}&productId=${productId}`;
    console.log("plService.getRequest2ProductCounts:", url);
    const { data } = await axios.get(url);
    console.log("plService.getRequest2ProductCounts:", data);
    return data;
  },
  async getDiscrepancyData(companyId, billingCompanyId, start, end, productId) {
    var url = `/api/v1.0/billing/requestIdDiscrepancy?companyId=${companyId}&billingCompanyId=${billingCompanyId}&start=${start}&end=${end}&productId=${productId}`;
    console.log("plService.getDiscrepancyData:", url);
    const { data } = await axios.get(url);
    console.log("plService.getDiscrepancyData:", data);
    return data;
  },
};

export default appService;
