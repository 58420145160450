/**
 * mutates the guarantor.credit property with the data given
 */
 import clone from 'clone';
export const setGuarantorCredit = (state, { data, loading }) => {
  state.guarantor.credit.data = clone(data);
  state.guarantor.credit.loading = loading;
  if (!loading) {
    state.guarantor.credit.retrieved = true;
  }
};
