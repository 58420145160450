<template>
  <v-autocomplete
    :items="items"
    v-model="item"
    :get-label="getLabel"
    :component-item="template"
    @update-items="updateItems"
    :min-len="0"
    @item-selected="itemSelected"
    :input-attrs="{ name: 'input-test', id: 'v-my-autocomplete' }"
  >
  </v-autocomplete>
</template>
<script>
import ItemTemplate from 'src/components/controls/ItemTemplate';
import Autocomplete from 'v-autocomplete'; // https://github.com/paliari/v-autocomplete

export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {},
    payorData: {
      payorName: 'Loading',
      id: -1,
    },
  },
  data() {
    return {
      name: '',
      item: null,
      items: [],
      template: ItemTemplate,
    };
  },
  components: {
    'v-autocomplete': Autocomplete,
  },
  computed: {
    filteredDataObj() {
      if (this.payorData) {
        return this.payorData.filter(option => {
          return (
            option.payorName
              .toString()
              .toLowerCase()
              .indexOf(this.name.toLowerCase()) >= 0
          );
        });
      }
      return null;
    },
  },
  methods: {
    itemSelected(item) {
      this.$emit('selected', item);
    },
    getLabel(item) {
      if (item) {
        return item.payorName;
      }
      return '';
    },
    updateItems(text) {
      this.name = text;
      this.items = this.filteredDataObj;
    },
  },
};
</script>

<style>
.v-autocomplete .v-autocomplete-input-group .v-autocomplete-input {
  font-size: 1.5em;
  padding: 10px 15px;
  box-shadow: none;
  border: 1px solid #157977;
  width: calc(100% - 32px);
  outline: none;
  background-color: #eee;
}
.v-autocomplete
  .v-autocomplete-input-group.v-autocomplete-selected
  .v-autocomplete-input {
  color: #008000;
  background-color: #f2fff2;
}
.v-autocomplete .v-autocomplete-list {
  width: calc(100% - 32px);
  text-align: left;
  border: none;
  border-top: none;
  max-height: 350px;
  overflow-y: auto;
  border-bottom: 1px solid #157977;
}
.v-autocomplete .v-autocomplete-list .v-autocomplete-list-item {
  cursor: pointer;
  background-color: #fff;
  padding: 10px;
  border-bottom: 1px solid #157977;
  border-left: 1px solid #157977;
  border-right: 1px solid #157977;
}
.v-autocomplete .v-autocomplete-list .v-autocomplete-list-item:last-child {
  border-bottom: none;
}
.v-autocomplete .v-autocomplete-list .v-autocomplete-list-item:hover {
  background-color: #eee;
}
.v-autocomplete .v-autocomplete-list .v-autocomplete-list-item abbr {
  opacity: 0.8;
  font-size: 0.8em;
  display: block;
  font-family: sans-serif;
}
</style>
