import { SET_PCR_NOK_ADDR_VALIDATED_ADDRESS } from 'src/store/actions/types';
import appService from 'src/pl.service';
import { statuses } from 'src/constants/statuses';

/**
 * commits the id to the store using the SET_PCR_NOK_ADDR_VALIDATED_ADDRESS action type
 */
export const setPcrNokAddrValidatedAddress = async ({ commit, state }) => {
  try {
    var validatedAddress = null;
    if (state.applicant.epcrJsonData && state.applicant.epcrJsonData.noK_addr) {
      validatedAddress = await appService.getValidatedAddress(
        state.applicant.epcrJsonData.noK_addr ?? '',
        state.applicant.epcrJsonData.noK_addr2 ?? '',
        state.applicant.epcrJsonData.noK_city ?? '',
        state.applicant.epcrJsonData.noK_state ?? '',
        state.applicant.epcrJsonData.noK_zip ?? ''
      );
    }
    if (validatedAddress) {
      commit(SET_PCR_NOK_ADDR_VALIDATED_ADDRESS, {
        validatedAddress: validatedAddress,
        status: 'success',
      });
    }
  } catch (error) {
    commit(SET_PCR_NOK_ADDR_VALIDATED_ADDRESS, {
      status: statuses.failure,
      error,
    });
  }
};
