import appService from 'src/pl.service';
import { SET_TRIP_DISTANCE } from 'src/store/actions/types';
import { statuses } from 'src/constants/statuses';

/**
 * Gets the distance traveled using our api, which uses googles api
 * and commits the data to
 */
export const setTripDistance = async (
  { commit },
  {
    pickupLatitude,
    pickupLongitude,
    destinationLatitude,
    destinationLongitude,
    asCrowFlies,
  }
) => {
  try {
    const data = await appService.getDistance(
      pickupLatitude,
      pickupLongitude,
      destinationLatitude,
      destinationLongitude,
      asCrowFlies
    );
    commit(SET_TRIP_DISTANCE, {
      status: statuses.success,
      data: data,
    });
  } catch (error) {
    commit(SET_TRIP_DISTANCE, {
      status: statuses.failure,
      error,
    });
  }
};
