// widgets
export const GET_WIDGET_VIEWS = 'getWidgetViews';

// als
export const GET_ALS_CONFIG = 'getAlsConfig';

// profiles
export const READ_PROFILES = 'readProfiles';
export const RESET_PROFILE = 'resetProfile';
export const CREATE_PROFILE = 'createProfile';
export const UPDATE_PROFILE = 'updateProfile';
export const UPDATE_ACTIVE_PROFILE = 'updateActiveProfile';
export const READ_ACTIVE_PROFILE = 'readActiveProfile';
export const SAVE_PROFILE_LAYOUT = 'saveProfileLayout';
export const CREATE_PROFILE_LAYOUT = 'createProfileLayout';
export const UPDATE_PROFILE_LAYOUT = 'updateProfileLayout';
export const DELETE_PROFILE_WIDGET = 'deleteProfileWidget';
export const ADD_PROFILE_WIDGET = 'addProfileWidget';
export const UPDATE_PROFILE_EXPAND_OPTION = 'updateProfileExpandOption';

// applicant
export const GET_APPLICANT = 'getApplicant';
export const GET_APPLICANT_VISITS = 'getApplicantVisits';
export const SET_APPLICANT_ID = 'setApplicantId';
export const GET_PAYOR_ELIGIBILITIES = 'getPayorEligibilities';
export const GET_CREDIT = 'getCredit'; // returns for both applicant and guarantor but it needs the applicant's id
export const SET_APPLICANT_CREDIT = 'setApplicantCredit';
export const SET_APPLICANT_HOME_VALIDATED_ADDRESS =
  'setApplicantHomeValidatedAddress';
export const SET_PCR_HOME_VALIDATED_ADDRESS = 'setPcrHomeValidatedAddress';
export const SET_PCR_PADDR_VALIDATED_ADDRESS = 'setPcrPaddrValidatedAddress';
export const SET_PCR_NOK_ADDR_VALIDATED_ADDRESS =
  'setPcrNokAddrValidatedAddress';
export const SET_BILLING_RESIDENTIAL_VALIDATED_ADDRESS =
  'setBillingResidentialValidatedAddress';
export const SET_BILLING_MAILING_VALIDATED_ADDRESS =
  'setBillingMailingValidatedAddress';
export const SET_BILLING_NEXTOFKIN_VALIDATED_ADDRESS =
  'setBillingNextOfKinValidatedAddress';
// trip
export const SET_TRIP_DISTANCE = 'setTripDistance';
export const GET_INSURANCE_FINDER = 'getInsuranceFinder';

//Insurance
export const VALIDATE_INSURANCE = 'validateInsurance';
export const CHECK_PAYOR_IS_MAPPED = 'checkPayorIsMapped';
export const SET_PAYOR_IS_MAPPED = 'setPayorIsMapped';
export const GET_ALL_PAYORS = 'getAllPayors';
export const GET_SERVICE_TYPES = 'getServiceTypes';
export const RUN_INSURANCE_DISCOVERY = 'runInsuranceDiscovery';
export const RUN_UPDATE_DEDUCTIBLE = 'runUpdateDeductible';
export const GET_HISTORY_INITIAL_DATA = 'getHistoryInitialData';
export const GET_HISTORY_ELIGIBILITY = 'getHistoryEligibility';
export const GET_HISTORY_PDF = 'getHistoryPdf';
export const FLAG_ELIGIBILITY_INCORRECT = 'flagEligibilityIncorrect';
// demographic
export const GET_DEMOGRAPHIC = 'getDemographic';
export const FLAG_DEMOGRAPHICS_INCORRECT = 'flagDemographicsIncorrect';
export const RUN_DEMOGRAPHICS_TEST = 'runDemographicsTest';
//location
export const SET_MAP_LOCATION = 'setMapLocation';

// guarantor
export const SET_GUARANTOR_CREDIT = 'setGuarantorCredit';
export const GET_GUARANTOR = 'getGuarantor';
export const FLAG_DEMOGRAPHICS_INCORRECT_GUARANTOR =
  'flagDemographicsIncorrectGuarantor';
export const SET_GUARANTOR_HOME_VALIDATED_ADDRESS =
  'setGuarantorHomeValidatedAddress';

// permissions
export const GET_MY_PERMISSIONS = 'getMyPermissions';
export const GET_RNC_PERMISSIONS = 'getRescueNetCompanyPermissions';
export const GET_MY_RNA_PERMISSIONS = 'getMyPermissionsForRescueNetApplicant';
export const GET_PRODUCT_PERMISSIONS = 'getProductPermissions';
