export default {
  props: {
    mountedCallback: {
      type: Function,
      required: true,
    },
    unmountedCallback: {
      type: Function,
      required: true,
    },
  },
  mounted() {
    this.mountedCallback(this);
  },
  beforeDestroy() {
    this.unmountedCallback(this);
  },
};
