import { UPDATE_ACTIVE_PROFILE } from 'src/store/actions/types';
import { statuses } from 'src/constants/statuses';
import { activeProfileKey } from 'src/constants/localStorage';

/**
 * stores the active profile id
 */
export const updateActiveProfile = async ({ commit }, profileId) => {
  commit(UPDATE_ACTIVE_PROFILE, { status: statuses.pending });
  try {
    localStorage.setItem(activeProfileKey, profileId);
    commit(UPDATE_ACTIVE_PROFILE, {
      id: profileId,
      status: statuses.success,
    });
  } catch (error) {
    commit(UPDATE_ACTIVE_PROFILE, {
      status: statuses.failure,
      error,
    });
  }
};
