<template>
  <div>
    <!-- <b>#{{item.id}}</b> -->
    <span>{{item.payorName}}</span>
    <!-- <abbr>{{item.payorName}}</abbr> -->
  </div>
</template>

<script>
export default {
  props: {
    item: { required: true }
  }
}
</script>
