import appService from 'src/pl.service';
// import { statuses } from 'src/constants/statuses';
// import { RUN_UPDATE_DEDUCTIBLE } from 'src/store/actions/types';

export const runUpdateDeductible = async ({ state }, { applicantId, payorName }) => {
  console.log('sjk', applicantId, payorName);
  try {
    // commit(RUN_UPDATE_DEDUCTIBLE, {
    //   status: statuses.pending,
    // });
    var success = await appService.runUpdateDeductible(
      applicantId ?? state.applicant.id,
      payorName
    );
    // commit(RUN_UPDATE_DEDUCTIBLE, {
    //   status: success ? statuses.success : statuses.failure,
    // });
    console.log('runUpdateDeductible', success);
  } catch (error) {
    console.error('runUpdateDeductible', error);
    // commit(RUN_UPDATE_DEDUCTIBLE, {
    //   status: statuses.failure,
    //   error,
    // });
  }
};
