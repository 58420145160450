import clone from 'clone';
/**
 * mutates the demographic property with the data given
 */
export const getDemographic = (state,  {data, status, loading, error}) => {
  state.applicant.demographic.data = clone(data);
  state.applicant.demographic.loading = loading;
  state.applicant.demographic.status = status;
  state.applicant.demographic.error = error;
  if (!loading) {
    state.applicant.demographic.retrieved = true;
  }
};
