import { statuses } from 'src/constants/statuses';
import { READ_PROFILES } from 'src/store/actions/types';
import { operations } from 'src/constants/operations';
import appService from 'src/pl.service';

/**
 * retrieves the user profiles from the API and
 * commits the data using READ_PROFILES action type.
 */
export const readProfiles = async ({ commit }) => {
  commit(READ_PROFILES, { status: statuses.pending });
  try {
    commit(READ_PROFILES, {
      data: (await appService.readProfiles()).map(profile => ({
        ...profile,
        layouts: profile.layouts.map(layout => ({
          ...layout,
          operations: {
            [operations.create]: {},
            [operations.update]: {},
            [operations.delete]: {},
          },
        })),
        operations: {
          reset: {},
          [operations.create]: {},
          [operations.update]: {},
          [operations.delete]: {},
        },
      })),
      status: statuses.success,
    });
  } catch (error) {
    commit(READ_PROFILES, { status: statuses.failure, error });
  }
};
