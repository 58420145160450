import { GET_HISTORY_INITIAL_DATA } from 'src/store/actions/types';
import { statuses } from 'src/constants/statuses';

/**
 * Gets the history information from the available eligiblities
 * and sets the data with the GET_HISTORY_INITIAL_DATA action type.
 */
export const getHistoryInitialData = async ({ commit, state }) => {
  commit(GET_HISTORY_INITIAL_DATA, { status: statuses.pending });
  var eligibilities = state.applicant.eligibilities.data;
  var data = [];
  if (eligibilities?.length) {
    for (var i = 0; i < eligibilities.length; i++) {
      const eligibility = eligibilities[i];
      if (eligibility?.eligibilityPdfs?.length) {
        for (var j = 0; j < eligibility.eligibilityPdfs.length; j++) {
          var additionalEligibility = eligibility.eligibilityPdfs[j];
          data.push({
            datetime: additionalEligibility.createdOn,
            createdBy: additionalEligibility.createdBy,
            createdByProduct: additionalEligibility.createdByProduct,
            payor: eligibility.payorName,
            eligibilityId: additionalEligibility.eligibilityId,
            detailsEligibility: {
              status: undefined,
            },
            pdfDownload: {
              status: undefined,
            },
          });
        }
      }
    }
  }
  commit(GET_HISTORY_INITIAL_DATA, { status: statuses.success, data: data });
};
