import { v4 as uuidv4 } from 'uuid';
import clone from 'clone';
import { statuses } from 'src/constants/statuses';
import {
  CREATE_PROFILE_LAYOUT,
  UPDATE_PROFILE_LAYOUT,
} from 'src/store/actions/types';
import { getProfileLayoutByColumnsLength } from 'src/store/getters/profiles/getProfileLayoutByColumnsLength';
import appService from 'src/pl.service';
import { parseLayoutColumns } from 'src/store/actions/profiles/layouts/parseLayoutColumns';

/**
 * creates or updates a profile layout
 */
export const saveProfileLayout = async ({ commit, state }, { columns }) => {
  // find if we need to create or update the layout
  let cuLayout = clone(getProfileLayoutByColumnsLength(state)(columns.length));
  // which API/mutation to call, create or update
  if (!cuLayout) {
    // create
    const id = uuidv4();
    cuLayout = {
      columns: [],
    };

    try {
      commit(CREATE_PROFILE_LAYOUT, {
        id,
        status: statuses.pending,
      });

      cuLayout.columns = parseLayoutColumns(columns);
      cuLayout.id = await appService.createProfileLayout(cuLayout);
      commit(CREATE_PROFILE_LAYOUT, {
        id,
        data: cuLayout,
        status: statuses.success,
      });
    } catch (error) {
      commit(CREATE_PROFILE_LAYOUT, {
        id,
        status: statuses.failure,
        error,
      });
    }
  } else {
    // update
    const id = cuLayout.id;
    cuLayout.columns = parseLayoutColumns(columns);

    try {
      commit(UPDATE_PROFILE_LAYOUT, {
        id,
        status: statuses.pending,
      });

      await appService.updateProfileLayout(cuLayout);
      commit(UPDATE_PROFILE_LAYOUT, {
        id,
        data: cuLayout,
        status: statuses.success,
      });
    } catch (error) {
      commit(UPDATE_PROFILE_LAYOUT, {
        id,
        status: statuses.failure,
        error,
      });
    }
  }
};
