<template>
    <b-modal id="modal-patientHistory" title="History" size="lg" hide-footer>
      <patient-history-table
        :isRescueNet="isRescueNet"
      ></patient-history-table>
    </b-modal>
</template>
<script>
import PatientHistoryTable from 'src/components/FullPatientData/PatientHistoryTable';

export default {
  components: {
    'patient-history-table': PatientHistoryTable,
  },
  props: {
    isRescueNet: {
      required: true,
    },
  },
  methods: {
    close() {
      this.$emit('close', null);
    },
  },
};
</script>
