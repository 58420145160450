import { throttle } from 'throttle-debounce';
import { mapActions } from 'vuex';
import { SAVE_PROFILE_LAYOUT } from 'src/store/actions/types';
import GridCell from './GridCell/GridCell';
import GridColumn from './GridColumn/GridColumn';
import { normalCellsMounted } from './computed/normalCellsMounted';
import { checkLayoutColumns } from './methods/checkLayoutColumns';
import { syncWidgets } from './methods/syncWidgets';
import { spreadCells } from './methods/spreadCells';
import { createMirrors } from './methods/createMirrors';
import { synchronizeMirrors } from './methods/synchronizeMirrors';
import { dropHandler } from './methods/dropHandler';
import { dragStartHandler } from './methods/dragStartHandler';
import { loadLayout } from './utils/loadLayout';

export default {
  props: {
    /**
     * a list of widget objects, that have the following structure:
     * {
     *    id: must be unique in the list,
     *    component: function that returns an imported vue component,
     *    colSpan: a number between 1-4, that defines the column span for this widget,
     *    meta: object with any meta info to attach to the cell
     * }
     * the order of the widgets will be used if there are no layout meta data provided.
     */
    widgets: {
      type: Array,
      required: true,
    },
    /**
     * list of user defined layouts, their columns and cells.
     * may be an empty array if nothing is saved yet
     */
    layouts: {
      type: Array,
      required: true,
    },
  },
  components: { GridCell, GridColumn },
  data() {
    return {
      /**
       * the current list of columns.
       * each column has its own list of cells
       */
      columns: [],
      /**
       * resize observer for the window,
       * so we can invoke the checkLayoutColumns
       */
      windowResizeObserver: null,
      /**
       * if true, the cells have been spread but
       * mirrors have not yet been created
       */
      hasSpreadCells: false,
      /**
       * if true, then the current layout has been defined by
       * the user can we should not try to spread the cells,
       * or perform any action that might shuffle the cells around.
       */
      userDefinedLayout: false,
    };
  },
  computed: {
    /**
     * true if all the widgets are loaded and rendered
     * @returns {boolean}
     */
    widgetsAreLoaded: function() {
      return this.columns.every(column =>
        column.cells.every(cell => cell.componentIsLoaded)
      );
    },
    /**
     * number of columns currently available
     * @returns
     */
    columnsLength() {
      return this.columns.length;
    },
    /**
     * true if there are columns and
     * all them have been mounted
     * @returns {boolean}
     */
    columnsMounted() {
      return (
        this.columns.length &&
        this.columns.every(column => column.componentInstance)
      );
    },
    /**
     * true if all the normal grid cells have been mounted
     * (it excludes mirrors)
     * @returns
     */
    normalCellsMounted() {
      return normalCellsMounted({
        columnsMounted: this.columnsMounted,
        columns: this.columns,
        widgets: this.widgets,
      });
    },
  },
  watch: {
    /**
     * this can get triggered when
     *  - user added a widget to the active profile
     *  - user removed a widget from the active profile
     */
    widgets: {
      handler() {
        this.syncWidgets();
      },
    },

    /**
     * when the layout changes, the columns decrease in number
     * we need to place the widgets, even if there is a (previously) user defined layout,
     * because when scaling down, widgets can get removed and we need to add them back.
     */
    columnsLength: {
      handler(current, previous) {
        if (current < previous) {
          this.syncWidgets();
        }
      },
    },

    /**
     * waits for when all the column component instances have been mounted and
     * starts the placing of the widgets in them.
     *
     * this should handle:
     *  - first run and place all widgets in the first column
     *  - a layout change with increase of columns and user defined layout
     */
    columnsMounted: {
      handler(current, previous) {
        if (previous !== current && current) {
          this.syncWidgets();
        }
      },
    },

    /**
     * once all the widgets have been placed, loaded and fully rendered,
     * this will attempt to spread the cells (and the widgets within them)
     * equally across all columns.
     *
     * in the case the cells have just been spread,
     * we will instead, create the mirrors.
     * this will get triggered because spreading causes the widgets the re-instantiate
     * hence, re-load...
     */
    widgetsAreLoaded: {
      handler(current, previous) {
        if (current !== previous && current) {
          if (!this.hasSpreadCells && !this.userDefinedLayout) {
            this.spreadCells();
          } else {
            this.createMirrors();
          }
        }
      },
    },
  },
  mounted: function() {
    // make sure we check the layout columns on window resize
    // but throttle for performance...
    this.windowResizeObserver = new ResizeObserver(
      throttle(100, this.checkLayoutColumns)
    );
    this.windowResizeObserver.observe(document.body);
  },
  beforeDestroy: function() {
    // cleanup
    if (this.windowResizeObserver) {
      this.windowResizeObserver.unobserve(document.body);
      this.windowResizeObserver = null;
    }
  },
  methods: {
    ...mapActions([SAVE_PROFILE_LAYOUT]),
   dragStartHandler ,
    dropHandler(e, column) {
      // in case the cell was indeed moved to a new location...
      if (dropHandler({ e, column, columns: this.columns })) {
        // make sure we know that the user modified the layout going forward
        this.userDefinedLayout = true;
        // save the active profile layout
        this[SAVE_PROFILE_LAYOUT]({
          columns: this.columns,
        });
      }
    },
    checkLayoutColumns() {
      // in case the layout changed
      if (
        checkLayoutColumns({
          columns: this.columns,
          handleColumnResizeObserver: this.handleColumnResizeObserver,
        })
      ) {
        // attempt to load the user defined layout
        loadLayout.call(this);
      }
    },
    syncWidgets() {
      syncWidgets({
        columnsMounted: this.columnsMounted,
        columns: this.columns,
        widgets: this.widgets,
      });
    },
    spreadCells() {
      spreadCells({
        columns: this.columns,
      });
      this.hasSpreadCells = true;
    },
    createMirrors() {
      createMirrors({
        normalCellsMounted: this.normalCellsMounted,
        columns: this.columns,
      });
    },
    /**
     * gets invoked every time a column resizes its height or width
     *
     * @param {object} column
     */
    handleColumnResizeObserver(column) {
      synchronizeMirrors({ column, columns: this.columns });
    },
  },
};
