import clone from 'clone';
import appService from 'src/pl.service';

export async function setApplicantVisitSignatures(applicantVisits) {
  if (applicantVisits[0]) {
    if (applicantVisits[0]?.epcrJsonData?.tripDisclaimers) {
      for (
        var p1 = 0;
        p1 < applicantVisits[0].epcrJsonData.tripDisclaimers.length;
        p1++
      ) {
        var disclaimer = applicantVisits[0].epcrJsonData.tripDisclaimers[p1];
        if (disclaimer) {
          if (disclaimer.whoname || disclaimer.signature) {
            var signaturePngData = await appService.convertHexTiffToPng(
              disclaimer.signature
            );
            disclaimer.signaturePngData = clone(signaturePngData);
          }
          if (disclaimer.witnessName1) {
            var witness1PngData = await appService.convertHexTiffToPng(
              disclaimer.witnessSignature1
            );
            disclaimer.witness1PngData = clone(witness1PngData);
          }
          if (disclaimer.witnessName2) {
            var witness2PngData = await appService.convertHexTiffToPng(
              disclaimer.witnessSignature2
            );
            disclaimer.witness2PngData = clone(witness2PngData);
          }
        }
      }
    }
    if (applicantVisits[0]?.epcrJsonData?.fdcShifts) {
      for (
        var p2 = 0;
        p2 < applicantVisits[0].epcrJsonData.fdcShifts.length;
        p2++
      ) {
        var shift = applicantVisits[0].epcrJsonData.fdcShifts[p2];
        if (shift) {
          if (shift.signature) {
            var shiftPngData = await appService.convertHexTiffToPng(
              shift.signature
            );
            shift.signaturePngData = clone(shiftPngData);
          }
        }
      }
    }
    if (applicantVisits[0]?.epcrJsonData?.fdcTripFormFieldBinary) {
      for (
        var i = 0;
        i < applicantVisits[0].epcrJsonData.fdcTripFormFieldBinary.length;
        i++
      ) {
        var formField =
          applicantVisits[0].epcrJsonData.fdcTripFormFieldBinary[i];
        if (formField && formField.binaryInfo) {
          var binaryInfo = formField.binaryInfo;
          if (binaryInfo.form_field_binary) {
            var formFieldPngData = await appService.convertHexTiffToPng(
              binaryInfo.form_field_binary
            );
            formField.signaturePngData = clone(formFieldPngData);
          }
        }
      }
    }
  }
}
