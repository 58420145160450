import Vue from 'vue';
import clone from 'clone';
export const setPcrPaddrValidatedAddress = (
  state,
  { validatedAddress, status, error }
) => {
  if (validatedAddress && state.applicant.epcrJsonData) {
    Vue.set(
      state.applicant.epcrJsonData,
      'paddrValidatedAddress',
      clone(validatedAddress)
    );
    if (state.applicant.epcrJsonData.paddrValidatedAddress) {
      state.applicant.epcrJsonData.paddrValidatedAddress.status = status;
      state.applicant.epcrJsonData.paddrValidatedAddress.error = error;
    }
  }
};
