import { widgets } from './widgets/index';
import { applicant } from './applicant/index';
import { permissions } from './permissions/index';
import { profiles } from './profiles/index';

export const getters = {
  ...applicant,
  ...widgets,
  ...permissions,
  ...profiles,
};
