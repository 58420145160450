import Vue from 'vue';
import clone from 'clone';
export const setPcrNokAddrValidatedAddress = (
  state,
  { validatedAddress, status, error }
) => {
  if (validatedAddress && state.applicant.epcrJsonData) {
    Vue.set(
      state.applicant.epcrJsonData,
      'nokAddrValidatedAddress',
      clone(validatedAddress)
    );
    if (state.applicant.epcrJsonData.nokAddrValidatedAddress) {
      state.applicant.epcrJsonData.nokAddrValidatedAddress.status = status;
      state.applicant.epcrJsonData.nokAddrValidatedAddress.error = error;
    }
  }
};
