/**
 * finds the cell's element "top" depending on other cells available
 *
 * @param {element} cellEl
 * @returns
 */
export const findElTop = cellEl => {
  if (cellEl.previousElementSibling) {
    return (
      cellEl.previousElementSibling.offsetTop +
      cellEl.previousElementSibling.clientHeight
    );
  }

  // when there is no other cell before the cellEl,
  // use the column's offsetTop (and not the .wrapper)
  // because that gets affected by the marginTop)
  return cellEl.parentElement.parentElement.offsetTop;
};
