import { operations } from 'src/constants/operations';

export const profiles = {
  data: [],
  active: {
    id: null,
    operations: {
      [operations.read]: {},
      [operations.update]: {},
    },
  },
  operations: {
    [operations.read]: {},
  },
};
