import clone from 'clone';
import appService from 'src/pl.service';
import { UPDATE_PROFILE } from 'src/store/actions/types';
import { getActiveProfile } from 'src/store/getters/profiles/getActiveProfile';
import { statuses } from 'src/constants/statuses';
import { expandOptions } from 'src/constants/expandOptions';

/**
 * toggle the option (using its name) to the active profile
 */
export const updateProfileExpandOption = async (
  { commit, state },
  { name }
) => {
  // find the profile we need to update in the state and
  // the option by name
  const uProfile = clone(getActiveProfile(state));
  if (uProfile && !uProfile?.expandOptions) {
    //we need to add default options
    var defaultOptions = expandOptions;
    uProfile.expandOptions = [];
    for (var i = 0; i < defaultOptions.length; i++) {
      var dOption = defaultOptions[i];
      uProfile.expandOptions.push({
        id: dOption.id,
        name: dOption.name,
        expandAll: dOption.expandAll,
      });
    }
  }
  var options = uProfile?.expandOptions;
  if (options) {
    // toggle option to the profile
    for (var j = 0; j < options.length; j++) {
      var option = options[j];
      if (option.name === name) {
        if (option.expandAll === 'true') {
          option.expandAll = 'false';
        } else if (option.expandAll === 'false') {
          option.expandAll = 'true';
        }
      }
    }
    try {
      // update
      commit(UPDATE_PROFILE, {
        id: uProfile.id,
        status: statuses.pending,
      });
      await appService.updateProfile(uProfile);
      commit(UPDATE_PROFILE, {
        id: uProfile.id,
        data: uProfile,
        status: statuses.success,
      });
    } catch (error) {
      commit(UPDATE_PROFILE, {
        id: uProfile.id,
        status: statuses.failure,
        error,
      });
    }
  }
};
