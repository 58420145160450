import appService from 'src/pl.service';
import { GET_ALL_PAYORS } from 'src/store/actions/types';

/**
 * retrieves all payors from the API and
 * commits the data to the store using the GET_ALL_PAYORS action type
 */
export const getAllPayors = async ({ commit }) => {
  var data = await appService.getAllPayors();
  const payors = data.map(payor => ({
    payorName: payor.payorName,
    payorId: payor.payorId,
  }));
  commit(GET_ALL_PAYORS, payors);
};
