import appService from 'src/pl.service';
const QuickSightEmbedding = require("amazon-quicksight-embedding-sdk");

export default {
    data() {
      return {
        companies: [],
        selectedCompanies: [],
        selectedStartDate: null,
        selectedEndDate: null,
        tags: [],
        selectedTags: [],
        dashboards: [],
        selectedDashboard: -1
      };
    },
    methods: {
      resetIframe() {
        let container = document.getElementById("product-dashboard");

        // replace children of the container so that we create new iframes, rather than adding many to the screen
        if(container !== null){
          container.replaceChildren();
        }
      },

      
      // Handle company settings result and set the model
      handleSettingsResult(settings) {

        this.companies = settings.companies.map(x => {
          return {
            value: x.companyId,
            text: x.companyName
          }
        });
        this.dashboards = settings.dashboards.map(x => {
            return {
                value: x.dashboardId,
                text: x.displayName
            }
        });
        this.tags = settings.tags.map(x => {
          return {
              value: x.tagId,
              text: x.tagName
          }
      })

        this.selectedCompanies = this.companies.find(x => {return x.value === settings.companyId});
        this.selectedDashboard = this.dashboards.find(x => {return x.value === settings.dashboardId});
        this.selectedStartDate = settings.startDate;
        this.selectedEndDate = settings.endDate;

        this.getDashboard();
      },
      handleMultiSelections(multi){
        let array = [];
        // Because the vue-multiselect is weird and returns an object if a single is selected
        if(typeof multi === 'object' && !Array.isArray(multi) && multi !== null) {
          array.push(multi.value);
        } else if(Array.isArray(multi)) {
            array = multi.map(x => x.value);
        }

        return array
      },
      async multiChange(codes){
        // Only run this check if more than one option is selected
        if(!codes || codes.length <= 1) return;

        // If all is selected with another option, remove it
        let index = codes.map(x => x.value).indexOf('All');
        if(index > -1){
          codes.splice(index, 1);
        }
      },

      async getCompanieswithTags(tagIds){
        let companies = await appService.getCompanyIdsByTagIds(tagIds);
        return companies;
      },
      async getDashboard() {
        let quickSightUrl = await appService.getQuickSightDashboardUrl(this.selectedDashboard.value);

        const embeddingContext = await QuickSightEmbedding.createEmbeddingContext();
        const {
          embedDashboard,
          // embedVisual
        } = embeddingContext;

        this.resetIframe();

        let frameOptions = {
          url: quickSightUrl.url,
          container: document.getElementById("product-dashboard"),
          height: "700px",
          scrolling: "no",
          resizeHeightOnSizeChangedEvent: true,
          iframeResizeOnSheetChange: true,
          onChange: (changeEvent, metadata) => {
            console.log('metadata',metadata,changeEvent)
              switch (changeEvent.eventName) {
                  case 'FRAME_MOUNTED': {
                      console.log("Quicksight frame mounted.");
                      break;
                  }
                  case 'FRAME_LOADED': {
                      console.log("QuickSight frame loaded.");
                      document.getElementById("product-dashboard").style.height = "85vh";
                      break;
                  }
              }
          }
        };

        if(this.selectedStartDate > this.selectedEndDate) {
            this.displayErrorToast('End Date must be after the Start Date');
            return;
          }

        let companies = this.handleMultiSelections(this.selectedCompanies);

        let tags = this.handleMultiSelections(this.selectedTags);
        if (tags.length > 0){
          let companiesWithTags = await this.getCompanieswithTags(tags);
          companies = companies.concat(companiesWithTags);
        }

        

        let contentOptions = {
          onMessage: async (messageEvent) => {
            console.log('a', messageEvent);
            switch (messageEvent.eventName) {
                case 'PARAMETERS_CHANGED': {
                    // messageEvent.message contains all parameters with active values
                    console.log(JSON.stringify(messageEvent.message));
                    break;
                }
            }
            },
            parameters: [
              {
                Name: 'CompanyId',
                Values: companies
              },
              {
                Name: 'StartDate',
                Values: this.selectedStartDate
              },
              {
                Name: 'EndDate',
                Values: this.selectedEndDate
              }
            ]
        };

        await embedDashboard(frameOptions, contentOptions);
      },
      async reloadCompaniesByDashboard(selectedDashboard){
      
        let settings = await appService.getProductDashboardSettings(selectedDashboard.value);
        this.companies = settings.companies.map(x => {
          return {
            value: x.companyId,
            text: x.companyName
          }
        });
        this.dashboards = settings.dashboards.map(x => {
            return {
                value: x.dashboardId,
                text: x.displayName
            }
        });
        this.tags = settings.tags.map(x => {
          return {
              value: x.tagId,
              text: x.tagName
          }
      });

        this.selectedCompanies = this.companies.find(x => {return x.value === settings.companyId});
        this.selectedDashboard = this.dashboards.find(x => {return x.value === settings.dashboardId});

      },
      async init() {
        this.resetIframe();
        // Load the companies and dashboards
        let settings = await appService.getProductDashboardSettings();
        this.handleSettingsResult(settings);
      } 
    },
    async created() {
        await this.init();
      },
}