import appService from 'src/pl.service';
import { GET_HISTORY_ELIGIBILITY } from 'src/store/actions/types';
import { statuses } from 'src/constants/statuses';

/**
 * retrieves the historic eligibility from the API for given eligibilityId and
 * commits the data to the store using the GET_HISTORY_ELIGIBILITY action type
 */
export const getHistoryEligibility = async (
  { commit, state },
  { eligibilityId, applicantId }
) => {
  commit(GET_HISTORY_ELIGIBILITY, {
    status: statuses.pending,
  });
  try {
    var eligibility = await appService.getPayorEligibility(
      eligibilityId,
      applicantId ?? state.applicant.id
    );
    commit(GET_HISTORY_ELIGIBILITY, {
      data: eligibility,
      eligibilityId,
      status: statuses.success,
    });
  } catch (error) {
    commit(GET_HISTORY_ELIGIBILITY, {
      status: statuses.failure,
    });
  }
};
