import { productNames } from 'src/constants/permissions';

/**
 * @return {boolean}
 */
export const hasDeductibleMonitoring = state => {
  if (state.permissions.products && state.permissions.products.length) {
    return state.permissions.products.includes(
      productNames.deductibleMonitoring
    );
  }
  return false;
};
