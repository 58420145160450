<template>
  <div>
    <b-navbar fixed="top" type="dark" variant="primary">
      <b-navbar-nav v-b-scrollspy:nav-scroller>
        <b-nav-item href="#top">BSQ</b-nav-item>
        <b-nav-item href="#missingBillingStatus">Missing Billing Status Report</b-nav-item>
        <b-nav-item href="#failedOrPending">Failed Or Pending Mapping Files</b-nav-item>
        <b-nav-item href="#currentlyRunning">Currently Running</b-nav-item>
        <b-nav-item href="#history">History</b-nav-item>
      </b-navbar-nav>
    </b-navbar>
    <b-jumbotron id="nav-scroller" style="position: relative; height: 100vh; overflow-y: scroll">
      <template #header>Bulk Submission Queue</template>
      <template #lead>As Of: {{ lastRunDate }}</template>
        <div id="top">
          <b-card title="In Progress" :sub-title="inProgressSubTitle">
            <div v-for="(bsq, index) in bsqInProgress" :key="`inProgress-${index}`">
              <div>
                <span style="color:#0603c5">{{ bsq.companyName }}</span> -
                <span style="color:#03c523" title="bulkSubmission created on">{{ bsq.fileName }} ({{ dateStr(bsq.createdOn) }})</span> -
                <span style="color:#7703c5">{{ bsq.product }}</span>
                <span style="font-size: 15px;" title="bulkSubmissionId/bulkSubmissionTestId">({{ bsq.bulkSubmissionId }}/{{bsq.bulkSubmissionTestId}})</span>
              </div>
              <b-progress v-if="bsq.completedCount != 0" class="mt-2" :max="bsq.pendingCount + bsq.completedCount">
                <b-progress-bar :value="bsq.completedCount" variant="success" animated><span>Progress: {{bsq.completedCount}} / {{bsq.pendingCount + bsq.completedCount}}</span></b-progress-bar>
              </b-progress>
              <b-progress v-if="bsq.completedCount == 0" class="mt-2" :max="bsq.pendingCount">
                <b-progress-bar :value="bsq.pendingCount" variant="dark"><span>Not Started: 0 / {{bsq.pendingCount + bsq.completedCount}}</span></b-progress-bar>
              </b-progress>
            </div>
          </b-card>
          <b-card title="In Progress" sub-title="Texas Medicaid Batch Not Complete">
            <b-table striped hover :items="bsqTxMedicaid" :fields="bsqTxMedicaidFields"></b-table>
          </b-card>
          <b-card title="Pending Retry">
            <div v-for="(bsq, index) in bsqRetry" :key="`retry-${index}`">
              <div>
                <span style="color:#0603c5">{{ bsq.companyName }}</span> -
                <span style="color:#03c523">{{ bsq.fileName }} ({{ dateStr(bsq.createdOn) }})</span> -
                <span style="color:#7703c5">{{ bsq.product }}</span>
                <span style="font-size: 15px;">({{ bsq.bulkSubmissionId }}/{{bsq.bulkSubmissionTestId}})</span>
              </div>
              <b-progress class="mt-2" :max="bsq.retryPendingCount + bsq.retryCompletedCount" show-value>
                <b-progress-bar :value="bsq.retryCompletedCount" variant="primary" animated></b-progress-bar>
                <b-progress-bar :value="bsq.retryPendingCount" variant="success"></b-progress-bar>
              </b-progress>
            </div>
          </b-card>
          <b-card title="Completed" sub-title="Completed not yet returned">
            <b-table striped hover :items="bsqComplete" :fields="fields"></b-table>
          </b-card>
        </div>
      <hr class="my-4" />
      <b-button squared block v-b-toggle.collapse-1 variant="primary" class="mb-2" id="missingBillingStatus">Missing Billing Status Report
        <span v-if="missingBillingStatus.lenght != 0">({{ missingBillingStatus.length }})</span>
      </b-button>
      <b-collapse id="collapse-1">
        <b-table striped hover :items="missingBillingStatus" :fields="missingBillingFields"></b-table>
      </b-collapse>
      <b-button squared block v-b-toggle.collapse-2 variant="primary" class="mb-2" id="failedOrPending">Failed Or Pending Mapping Files
        <span v-if="failedOrPendingMappingFiles.lenght != 0">({{ failedOrPendingMappingFiles.length }})</span>
      </b-button>
      <b-collapse squared id="collapse-2">
        <b-table striped hover :items="failedOrPendingMappingFiles" :fields="failedOrPendingFields"></b-table>
      </b-collapse>
      <b-button squared block v-b-toggle.collapse-3 variant="primary" class="mb-2" id="currentlyRunning">Currently Running
        <span v-if="runningLog.lenght != 0">({{ runningLog.length }})</span>
      </b-button>
      <b-collapse id="collapse-3">
        <b-table striped hover :items="runningLog"></b-table>
      </b-collapse>
      <b-button squared block v-b-toggle.collapse-4 variant="primary" id="history">History
        <span v-if="allLog.lenght != 0">({{ allLog.length }})</span>
      </b-button>
      <b-collapse id="collapse-4">
        <b-table striped hover :items="allLog"></b-table>
      </b-collapse>
    </b-jumbotron>
  </div>
</template>

<script>
import appService from 'src/pl.service';

export default {
  data() {
    return {
      runningLog: [],
      allLog: [],
      missingBillingStatus: [],
      failedOrPendingMappingFiles: [],
      bsqInProgress: [],
      bsqComplete: [],
      bsqTxMedicaid: [],
      bsqRetry: [],
      lastRunDate: null,
      timer: null,
      inProgressSubTitle: null,
      totalRemainingOverTime: 0,
      fields: ['companyName', 'fileName', 'product', 'completedCount'],
      bsqTxMedicaidFields: ['fileName', 'status', 'payorName', 'batchCount', 'bulkSubmissionId'],
      missingBillingFields: [
        'bulkSubmissionId',
        'fileName',
        'companyName',
        'dateImported',
        'numberOfRecords',
      ],
      failedOrPendingFields: [
        'bulkSubmissionId',
        'fileName',
        'companyName',
        'dateImported',
        'status',
      ],
    };
  },
  methods: {
    async doStuff() {
      await this.loadBsqData();
    },
    async loadBsqData() {
      var i;
      var inProgress = [];
      var inRetry = [];
      var inComplete = [];
      var totalComplete = 0;
      var totalPending = 0;
      this.runningLog = await appService.getBulkSubmissionRunningLog();
      this.allLog = await appService.getAllBulkSubmissionLog();
      this.missingBillingStatus = await appService.getBulkSubmissionMissingBillingStatuses();
      this.bsqTxMedicaid = await appService.getNonCompleteVendorBatchSubmission();
      this.failedOrPendingMappingFiles = await appService.getBulkSubmissionFailedOrPendingMappingFiles();
      var allPendingBsq = await appService.getBulkSubmissionPendingWork();
      for (i = 0; i < allPendingBsq.length; i++) {
        if (allPendingBsq[i].pendingCount > 0) {
          totalComplete += allPendingBsq[i].completedCount;
          totalPending += allPendingBsq[i].pendingCount;
          inProgress.push(allPendingBsq[i]);
        } else {
          if (allPendingBsq[i].retryPendingCount > 0) {
            inRetry.push(allPendingBsq[i]);
          } else {
            inComplete.push(allPendingBsq[i]);
          }
        }
      }
      console.log(inProgress, inRetry, inComplete);
      this.lastRunDate = new Date().toLocaleString();
      var sinceLast = 0;
      if (this.totalRemainingOverTime != 0) {
        sinceLast = this.totalRemainingOverTime - totalPending
      }
      this.totalRemainingOverTime = totalPending

      this.inProgressSubTitle = 'Total Remaining: ' + totalPending + ', Total Complete: ' + totalComplete + ', Over Last 30s: ' + sinceLast;

      this.bsqInProgress = inProgress;
      this.bsqRetry = inRetry;
      this.bsqComplete = inComplete;

      // for (i = 0; i < inComplete.length; i++) {
      //   matched = false;
      //   for (k = 0; k < this.bsqComplete.length; k++) {
      //     if (inComplete[i].bulkSubmissionTestId === this.bsqComplete[k].bulkSubmissionTestId) {
      //       matched = true;
      //       break;
      //     }
      //   }
      //   if (!matched) {
      //     this.bsqComplete.push(inComplete[i]);
      //   }
      // }

      // for (i = 0; i < this.bsqComplete.length; i++) {
      //   matched = false;
      //   for (k = 0; k < inComplete.length; k++) {
      //     if (inComplete[k].bulkSubmissionTestId === this.bsqComplete[i].bulkSubmissionTestId) {
      //       matched = true;
      //       break;
      //     }
      //   }
      //   if (!matched) {
      //     this.bsqComplete.splice(i, 1);
      //   }
      // }

      // for (i = 0; i < inRetry.length; i++) {
      //   matched = false;
      //   for (k = 0; k < this.bsqRetry.length; k++) {
      //     if (inRetry[i].bulkSubmissionTestId === this.bsqRetry[k].bulkSubmissionTestId) {
      //       matched = true;
      //       this.bsqRetry[k].retryCompletedCount = inRetry[i].retryCompletedCount;
      //       this.bsqRetry[k].retryPendingCount = inRetry[i].retryPendingCount;
      //       break;
      //     }
      //   }
      //   if (!matched) {
      //     this.bsqRetry.push(inRetry[i]);
      //   }
      // }

      // for (i = 0; i < this.bsqRetry.length; i++) {
      //   matched = false;
      //   for (k = 0; k < inRetry.length; k++) {
      //     if (inRetry[k].bulkSubmissionTestId === this.bsqRetry[i].bulkSubmissionTestId) {
      //       matched = true;
      //       break;
      //     }
      //   }
      //   if (!matched) {
      //     this.bsqRetry.splice(i, 1);
      //   }
      // }

      // for (i = 0; i < inProgress.length; i++) {
      //   matched = false;
      //   for (k = 0; k < this.bsqInProgress.length; k++) {
      //     if (inProgress[i].bulkSubmissionTestId === this.bsqInProgress[k].bulkSubmissionTestId) {
      //       matched = true;
      //       this.bsqInProgress[k].completedCount = inProgress[i].completedCount;
      //       this.bsqInProgress[k].pendingCount = inProgress[i].pendingCount;
      //       break;
      //     }
      //   }
      //   if (!matched) {
      //     this.bsqInProgress.push(inProgress[i]);
      //   }
      // }

      // for (i = 0; i < this.bsqInProgress.length; i++) {
      //   matched = false;
      //   for (k = 0; k < inProgress.length; k++) {
      //     if (inProgress[k].bulkSubmissionTestId === this.bsqInProgress[i].bulkSubmissionTestId) {
      //       matched = true;
      //       break;
      //     }
      //   }
      //   if (!matched) {
      //     this.bsqInProgress.splice(i, 1);
      //   }
      // }

    },
  },
  async mounted() {
    console.log('mounted');
    this.timer = setInterval(() => {
      this.doStuff();
    }, 30000);
    this.doStuff();
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  },
};
</script>
