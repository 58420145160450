/**
 * the list of widget view names as returned by the API (ie. getWidgetViews)
 */
export const widgetViewNames = {
  triTech: 'TriTech',
  rescueNetNoEPCR: 'RescueNet Widget View no ePCR',
  rescueNet: 'RescueNet Widget View',
};

/**
 * this type is used by some widget APIs (ie. getWidgetViews)
 * to map the widget.id that the API expects,
 * to the widget type, that the front-end application understands.
 */
export const widgets = {
  patientInformationCard: {
    type: 0,
    text: 'Patient Information',
    icon: 'user-injured',
    component: () => ({
      component: import(
        'src/components/FullPatientData/PatientWidgetBody/PatientInformationCard/PatientInformationCard'
      ),
    }),
    colSpan: 1,
  },
  selfPay: {
    type: 2,
    text: 'Self Pay',
    icon: 'user',
    component: () => ({
      component: import(
        'src/components/FullPatientData/PatientWidgetBody/SelfPay/SelfPay'
      ),
    }),
    colSpan: 1,
  },
  insurance: {
    type: 3,
    text: 'Insurance',
    icon: 'file-invoice',
    component: () => ({
      component: import(
        'src/components/FullPatientData/PatientWidgetBody/InsuranceWidgetNew/InsuranceWidget'
      ),
    }),
    colSpan: 1,
  },
  trip: {
    type: 4,
    text: 'Trip',
    icon: 'ambulance',
    component: () => ({
      component: import(
        'src/components/FullPatientData/PatientWidgetBody/TripWidget/TripWidget'
      ),
    }),
    colSpan: 1,
  },
  attachments: {
    type: 9,
    text: 'Attachments',
    icon: 'paperclip',
    component: () => ({
      component: import(
        'src/components/FullPatientData/PatientWidgetBody/AttachmentWidget/Attachments'
      ),
    }),
    colSpan: 1,
  },
  pcrData: {
    type: 6,
    text: 'PCR Data',
    icon: 'user-injured',
    component: () => ({
      component: import(
        'src/components/FullPatientData/PatientWidgetBody/PCRData/PCRData'
      ),
    }),
    colSpan: 2,
  },
};

/**
 * the list of default widgets for each widget view.
 * this is useful to create or reset the widget view of a user.
 * (ie. API call to deleteWidgets and then addWidget for each widget in the view)
 */
export const widgetViewDefaultWidgets = {
  [widgetViewNames.triTech]: [
    widgets.patientInformationCard,
    widgets.insurance,
    widgets.selfPay,
  ],

  [widgetViewNames.rescueNetNoEPCR]: [
    widgets.patientInformationCard,
    widgets.insurance,
    widgets.trip,
    widgets.selfPay,
    widgets.attachments,
  ],

  [widgetViewNames.rescueNet]: [
    widgets.patientInformationCard,
    widgets.insurance,
    widgets.trip,
    widgets.pcrData,
    widgets.attachments,
    widgets.selfPay,
  ],
};
