import clone from 'clone';

/**
 * mutates the applicant property with the data given, as-is
 */
export const getApplicant = (state, data) => {
  const clonedData = clone(data);
  clonedData.data = {
    ...clonedData.data,
    insuranceStatus: undefined,
  };

  if (clonedData.data?.insurance?.length) {
    clonedData.data.insurance = clonedData.data.insurance.map(ins => ({
      ...ins,
      isMapped: true,
      mappedStatus: undefined,
      verifiedStatus: undefined,
      payorName: ins.payor,
      error: {},
    }));
  }

  if (clonedData.epcrJsonData?.insurance_pcrpayors?.length) {
    clonedData.epcrJsonData.insurance_pcrpayors = clonedData.epcrJsonData.insurance_pcrpayors.map(
      ins => ({
        ...ins,
        isMapped: true,
        mappedStatus: undefined,
        verifiedStatus: undefined,
        error: {},
      })
    );
  }

  state.applicant = {
    ...clone(state.applicant),
    ...clonedData,
  };
};
