import axios from 'axios';
import { zLogRocket } from '@zoll/logrocket-vue2-lib';
import { customizeSanitizers } from './customizeSanitizers';

zLogRocket.initialize({
  appId: 'zoll-data/zoll-ar-boost-vue-3',
  readyCallback: ({ sessionURL }) => {
     axios.defaults.headers.common['X-LogRocket-URL'] = sessionURL;
  },
  sanitizers: customizeSanitizers(),
});
