<template>
  <b-container fluid>
    <b-row>
      <b-col lg="6" class="my-1">
        <b-form-group
          label="Vendor"
          label-for="vendor-select"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <b-form-select
            id="vendor-select"
            v-model="selectedVendor"
            :options="vendorOptions"
            size="sm"
          ></b-form-select>
        </b-form-group>
      </b-col>

      <b-col lg="6" class="my-1">
        <!-- <b-form-group
          v-model="sortDirection"
          label="Has Value"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <b-form-checkbox-group v-model="hasValue" class="mt-1">
            <b-form-checkbox value="hasSSN">SSN</b-form-checkbox>
            <b-form-checkbox value="hasDOB">DOB</b-form-checkbox>
            <b-form-checkbox value="hasPhone">Phone</b-form-checkbox>
            <b-form-checkbox value="isNotHit">NoHit</b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group> -->
        <b-row>
          <b-col>
            <b-form-group label="NoHit" v-slot="{ ariaDescribedby }">
              <b-form-radio-group
                size="sm"
                button-variant="outline-primary"
                id="btn-radios-1"
                v-model="selectedNoHit"
                :options="options"
                :aria-describedby="ariaDescribedby"
                name="radios-btn-default"
                buttons
              >
              </b-form-radio-group>
            </b-form-group>
          </b-col>
          <b-col
            ><b-form-group label="HasSSN" v-slot="{ ariaDescribedby }">
              <b-form-radio-group
                size="sm"
                button-variant="outline-primary"
                id="btn-radios-1"
                v-model="selectedHasSSN"
                :options="options"
                :aria-describedby="ariaDescribedby"
                name="radios-btn-default"
                buttons
              >
              </b-form-radio-group> </b-form-group
          ></b-col>
          <b-col
            ><b-form-group label="HasDOB" v-slot="{ ariaDescribedby }">
              <b-form-radio-group
                size="sm"
                button-variant="outline-primary"
                id="btn-radios-1"
                v-model="selectedHasDOB"
                :options="options"
                :aria-describedby="ariaDescribedby"
                name="radios-btn-default"
                buttons
              >
              </b-form-radio-group> </b-form-group
          ></b-col>
          <b-col
            ><b-form-group label="HasPhone" v-slot="{ ariaDescribedby }">
              <b-form-radio-group
                size="sm"
                button-variant="outline-primary"
                id="btn-radios-1"
                v-model="selectedHasPhone"
                :options="options"
                :aria-describedby="ariaDescribedby"
                name="radios-btn-default"
                buttons
              >
              </b-form-radio-group> </b-form-group
          ></b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="6" class="my-1">
        <b-form-group
          label="Filter"
          label-for="filter-input"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            ></b-form-input>

            <b-input-group-append>
              <b-button variant="primary" :disabled="!filter" @click="filter = ''"
                >Clear</b-button
              >
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col lg="6" class="my-1">
        <b-form-group
          label="Confidence Score"
          label-for="confidence-score"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <vue-slider
            :enable-cross="false"
            v-model="sliderValue"
            id="confidence-score"
          ></vue-slider>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="6" class="my-1">
        <b-form-group
          v-model="sortDirection"
          label="Group By"
          description="Group By determines columns shown in Aggregate Data table.  Group by 0-1 items to view Data Over Time."
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <b-form-checkbox-group v-model="groupBy" class="mt-1">
            <b-form-checkbox value="gbCompany">Company</b-form-checkbox>
            <b-form-checkbox value="gbVendor">Vendor</b-form-checkbox>
            <b-form-checkbox value="gbNoHit">NoHit</b-form-checkbox>
            <b-form-checkbox value="gbSSN">Has SSN</b-form-checkbox>
            <b-form-checkbox value="gbDOB">Has DOB</b-form-checkbox>
            <b-form-checkbox value="gbPhone">Has Phone</b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>
      </b-col>

      <b-col sm="5" md="6" class="my-1">
        <b-form-group
          label="Per page"
          label-for="per-page-select"
          label-cols-sm="6"
          label-cols-md="4"
          label-cols-lg="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <b-form-select
            id="per-page-select"
            v-model="perPage"
            :options="pageOptions"
            size="sm"
          ></b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="justify-content-center my-1">
      <b-col md="auto">
        <b-button class="mx-1" variant="success" @click="getData" :disabled="loadingData">
          {{ loadButton }}
          <b-spinner small v-if="loadingData"></b-spinner>
        </b-button>
        <b-button
          variant="success"
          @click="getDataOverTime"
          :disabled="loadingDataOverTime"
        >
          {{ loadButtonOverTime }}
          <b-spinner small v-if="loadingDataOverTime"></b-spinner>
        </b-button>
      </b-col>
    </b-row>

    <!-- Main table element -->
    <b-row>
      <b-table
        :items="items"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        :filter="filter"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        stacked="md"
        show-empty
        small
        @filtered="onFiltered"
      >
        <template #cell(name)="row">
          {{ row.value.first }} {{ row.value.last }}
        </template>

        <template #cell(actions)="row">
          <b-button
            size="sm"
            @click="info(row.item, row.index, $event.target)"
            class="mr-1"
          >
            Info modal
          </b-button>
          <b-button size="sm" @click="row.toggleDetails">
            {{ row.detailsShowing ? "Hide" : "Show" }} Details
          </b-button>
        </template>

        <template #row-details="row">
          <b-card>
            <ul>
              <li v-for="(value, key) in row.item" :key="key">
                {{ key }}: {{ value }}
              </li>
            </ul>
          </b-card>
        </template>
      </b-table>
    </b-row>
    <b-row>
      <b-col>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0"
        >
        </b-pagination>
      </b-col>
    </b-row>

    <br />
    <div v-show="showApexChart" id="collapse-3">
      <div id="chart">
        <apexchart
          type="area"
          height="350"
          :options="chartOptions"
          :series="series"
        ></apexchart>
      </div>
    </div>
  </b-container>
</template>

<script>
import reportingService from "../reporting.service.js";
import VueApexCharts from "vue-apexcharts";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";

export default {
  props: {
    companyIds: {
      required: true,
    },
    start: {
      required: true,
    },
    end: {
      required: true,
    },
  },
  components: {
    apexchart: VueApexCharts,
    "vue-slider": VueSlider,
  },
  data() {
    return {
      sliderValue: [0, 100],
      showApexChart: false,
      loadButton: "Load Aggregate Data",
      loadingData: false,
      loadButtonOverTime: "Load Data Over Time",
      loadingDataOverTime: false,
      items: [],
      selectedNoHit: null,
      selectedHasSSN: null,
      selectedHasDOB: null,
      selectedHasPhone: null,
      options: [
        { text: "True", value: true },
        { text: "False", value: false },
        { text: "Null", value: null },
      ],
      fields: [
        {
          key: "companyId",
          label: "Company",
        },
        {
          key: "noHit",
          label: "NoHit",
        },
        {
          key: "hasSSN",
          label: "Has SSN",
        },
        {
          key: "hasDOB",
          label: "Has DOB",
        },
        {
          key: "hasPhone",
          label: "Has Phone",
        },
        {
          key: "hasSSN",
          label: "Has SSN",
        },
        {
          key: "vendor",
          label: "Vendor",
        },
        { key: "count", label: "Count", sortable: true, class: "text-center" },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 50, 100, 500],
      vendorOptions: [
        { value: 1, text: "TransUnion" },
        { value: 5, text: "TransUnionTLO" },
        { value: 6, text: "Experian" },
        { value: 9, text: "Waystar" },
      ],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      selectedVendor: null,
      filter: null,
      groupBy: [],
      hasValue: [],

      series: [],
      chartOptions: {
        chart: {
          type: "area",
          stacked: false,
          height: 350,
          zoom: {
            type: "x",
            enabled: true,
            autoScaleYaxis: true,
          },
          toolbar: {
            autoSelected: "zoom",
          },
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 0,
        },
        title: {
          text: "Demographic Movement",
          align: "left",
        },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.5,
            opacityTo: 0,
            stops: [0, 90, 100],
          },
        },
        yaxis: {
          title: {
            text: "Demographic Count",
          },
        },
        xaxis: {
          type: "datetime",
        },
        tooltip: {
          shared: true,
        },
      },
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  async mounted() {
    // Set the initial number of items
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async getData() {
      this.loadButton = "Loading...";
      this.loadingData = true;
      try {
        this.fields = [];

        if (this.groupBy.includes("gbCompany")) {
          this.fields.push({
            key: "companyId",
            label: "Company",
            sortable: true,
            class: "text-center",
          });
        }
        if (this.groupBy.includes("gbNoHit")) {
          this.fields.push({
            key: "noHit",
            label: "NoHit",
            sortable: true,
            class: "text-center",
          });
        }
        if (this.groupBy.includes("gbSSN")) {
          this.fields.push({
            key: "hasSSN",
            label: "Has SSN",
            sortable: true,
            class: "text-center",
          });
        }
        if (this.groupBy.includes("gbDOB")) {
          this.fields.push({
            key: "hasDOB",
            label: "Has DOB",
            sortable: true,
            class: "text-center",
          });
        }
        if (this.groupBy.includes("gbPhone")) {
          this.fields.push({
            key: "hasPhone",
            label: "Has Phone",
            sortable: true,
            class: "text-center",
          });
        }
        if (this.groupBy.includes("gbVendor")) {
          this.fields.push({
            key: "vendor",
            label: "Vendor",
            sortable: true,
            class: "text-center",
          });
        }
        this.fields.push({
          key: "count",
          label: "Count",
          sortable: true,
          class: "text-center",
        });
        this.items = await reportingService.getDemographicAggregateData(
          this.companyIds,
          this.start,
          this.end,
          this.selectedVendor,
          this.selectedNoHit,
          this.selectedHasSSN,
          this.selectedHasDOB,
          this.selectedHasPhone,
          this.sliderValue[0],
          this.sliderValue[1],
          this.groupBy.includes("gbCompany"),
          this.groupBy.includes("gbNoHit"),
          this.groupBy.includes("gbSSN"),
          this.groupBy.includes("gbDOB"),
          this.groupBy.includes("gbPhone"),
          this.groupBy.includes("gbVendor"),
          false
        );
        this.totalRows = this.items.length;
      } catch (error) {
        console.error(error);
        this.displayErrorToast("Error Loading Data: " + error.message);
      }
      this.loadButton = "Load Aggregate Data";
      this.loadingData = false;
    },
    async getDataOverTime() {
      this.loadButtonOverTime = "Loading...";
      this.loadingDataOverTime = true;
      try {
        var chartData1 = await reportingService.getDemographicAggregateData(
          this.companyIds,
          this.start,
          this.end,
          this.selectedVendor,
          this.selectedNoHit,
          this.selectedHasSSN,
          this.selectedHasDOB,
          this.selectedHasPhone,
          this.sliderValue[0],
          this.sliderValue[1],
          this.groupBy.includes("gbCompany"),
          this.groupBy.includes("gbNoHit"),
          this.groupBy.includes("gbSSN"),
          this.groupBy.includes("gbDOB"),
          this.groupBy.includes("gbPhone"),
          this.groupBy.includes("gbVendor"),
          true
        );
        let seriesMap = new Map();
        for (var i = 0; i < chartData1.length; i++) {
          var label = "Total";
          if (this.groupBy.includes("gbCompany")) {
            label = chartData1[i].companyId;
          }
          if (this.groupBy.includes("gbNoHit")) {
            label = chartData1[i].noHit;
          }
          if (this.groupBy.includes("gbSSN")) {
            label = chartData1[i].hasSSN;
          }
          if (this.groupBy.includes("gbDOB")) {
            label = chartData1[i].hasSSN;
          }
          if (this.groupBy.includes("gbPhone")) {
            label = chartData1[i].hasPhone;
          }
          if (this.groupBy.includes("gbVendor")) {
            label = chartData1[i].vendor;
          }
          if (!seriesMap.has(label)) {
            seriesMap.set(label, []);
          }
          seriesMap
            .get(label)
            .push({ x: chartData1[i].requestDate, y: chartData1[i].count });
        }
        this.series = [];
        for (let [key, value] of seriesMap) {
          console.log(key + " = " + value);
          this.series.push({ name: key, data: value });
        }
        console.log("series", this.series);
      } catch (error) {
        console.error(error);
        this.displayErrorToast("Error Loading Data: " + error.message);
      }
      this.loadButtonOverTime = "Load Data Over Time";
      this.loadingDataOverTime = false;
      this.showApexChart = true;
    },
  },
};
</script>
