/**
 * Sets pdfDownload status and, if it exists,
 * appends the pdf object to
 * the data object in the history array
 * @param {*} state
 * @param {*} param1
 */
export const getHistoryPdf = (state, { status, eligibilityId, data = {} }) => {
  if (state.applicant.history.data?.length) {
    var historyItem = state.applicant.history.data.find(
      item => item.eligibilityId === eligibilityId
    );
    if (historyItem) {
      historyItem.pdfDownload.status = status;
      historyItem.pdf = data;
    }
  }
};
