import clone from 'clone';
import { operations } from 'src/constants/operations';

/**
 * mutates the profiles .data and .status property with the data given
 */
export const readProfiles = (state, { data, status, error }) => {
  if (data) {
    state.profiles.data = clone(data);
  }
  state.profiles.operations[operations.read] = {
    status,
    error,
  };
};
