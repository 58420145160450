import { v4 as uuidv4 } from 'uuid';
import { widgetViewDefaultWidgets } from 'src/constants/widgets';

/**
 * builds and returns the list of default widgets,
 * for the current widget view name.
 *
 * if the current widget view name is not provided or
 * does not match the defined ones, an empty list will be returned instead.
 * @param {string} widgetViewName
 * @returns {array}
 */
export const getDefaultWidgets = widgetViewName =>
  widgetViewDefaultWidgets[widgetViewName]?.map(widget => ({
    id: uuidv4(),
    colSpan: widget.colSpan,
    meta: {
      type: widget.type,
    },
  })) ?? [];
