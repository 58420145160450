export const guarantor = {
  id: null,
  credit: {
    data: [],
    loading: false,
    retrieved: false,
  },
  loading: false,
  retrieved: false,
  data: {
    loading: false,
    retrieved: false,
  },
  demographic: {}
};
