/**
 * finds an returns the initial state of widget by its type
 *
 * @param {string} type the id of the widget type
 * @returns {object}
 *
 * TODO: cleanup
 */
export const getWidgetInitialState = () => {
  const initialState = {
    // it should be true when all its initial data have been loaded and rendered
    // this is useful for calculating the layout in relation to each widget's true dimensions
    // which, requires the "initial" data loaded and rendered...
    isLoaded: false,
  };
  return initialState;
};
