<template>
  <b-modal id="modal-als" title="Edit ALS Triggers" size="xl" ok-only v-on:show="alsMounted">
    <div class="top-row">
      <div>
        <div class="label">Treatments</div>
        <div class="field">
          <div class="dropdownxx">
            <select v-model="selectedTreatment">
              <option :value="-1">*</option>
              <option
                v-for="(treatment, index) in treatments"
                v-bind:value="index"
                v-bind:key="treatment.id"
              >
                {{ treatment.d }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div>
        <div class="label">Qualifiers</div>
        <div class="field">
          <div class="dropdownxx">
            <select v-model="selectedQualifier">
              <option :value="-1">*</option>
              <option
                v-for="(qualifier, index) in qualifiers"
                v-bind:value="index"
                v-bind:key="qualifier.id"
              >
                {{ qualifier.d }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div>
        <div class="label">Values</div>
        <div class="field">
          <div class="dropdownxx">
            <select v-model="selectedValue">
              <option :value="-1">*</option>
              <option
                v-for="(value, index) in values"
                v-bind:value="index"
                v-bind:key="value.id"
              >
                {{ value.d }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div>
        <div class="label">Call Type</div>
        <div class="field">
          <div class="dropdownxx">
            <select v-model="selectedCallType">
              <option
                v-for="callType in callTypes"
                v-bind:value="callType.id"
                v-bind:key="callType.id"
              >
                {{ callType.desc }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div >
        <b-button size="sm" variant="success" @click="ok">Add</b-button>
      </div>
    </div>
    <b-table class="mt-3" striped :fields="fields" :items="existingTriggers">
      <template #head(callTypeDesc)="data">
        {{data.emptyText}}
        Call Type
      </template>
      <template #head(delete)="data">
        {{data.emptyText}}
      </template>

      <template #cell(delete)="data">
        <font-awesome-icon class="delete-icon" icon="times" @click="deleteTrigger(data.item.id)"/>
      </template>
    </b-table>

    <div class="bottom-row" style="margin-left: 320px">
      <verte
        v-model="colorALS1"
        picker="wheel"
        model="hex"
        menuPosition="bottom"
        >
        <b-button size="sm" style="width:150px;" :style="{backgroundColor: colorALS1}">ALS, Level 1 Color</b-button>
      </verte>
      <verte
        v-model="colorALS2"
        picker="wheel"
        model="hex"
        menuPosition="bottom"
        style=" margin-left: 135px;"
        >
        <b-button size="sm" style="width:150px;" :style="{backgroundColor: colorALS2}">ALS, Level 2 Color</b-button>
      </verte>
      <verte
        v-model="colorSCT"
        picker="wheel"
        model="hex"
        menuPosition="right"
        style="margin-left: 135px"
        >
        <b-button size="sm" style="width:150px;" :style="{backgroundColor: colorSCT}">SCT Color</b-button>
      </verte>
    </div>
    <div class="save-colors mt-4">
      <b-button size="sm" variant="success" @click="saveColors">Save Colors</b-button>
    </div>
  </b-modal>
</template>
<script>
import appService from 'src/pl.service';
import Verte from 'verte';
import 'verte/dist/verte.css';

export default {
  props: {},
  components: {
    Verte,
  },
  data: function() {
    return {
      treatments: null,
      allQualifiers: null,
      qualifiers: null,
      allValues: null,
      values: null,
      callTypes: [
        { id: 1, desc: 'ALS, Level 1' },
        { id: 2, desc: 'ALS Level 2' },
        { id: 3, desc: 'SCT' },
      ],
      selectedTreatment: -1,
      selectedQualifier: -1,
      selectedValue: -1,
      selectedCallType: 1,
      existingTriggers: [],
      colorALS1: '',
      colorALS2: '',
      colorSCT: '',
      fields: ['treatment', 'qualifier', 'value', 'callTypeDesc', 'delete'],
    };
  },
  watch: {
    selectedTreatment: function(newVal) {
      console.log('selectedTreatment.watch', newVal);
      var selectedQualifiers = [];
      var i = 0;
      if (newVal === -1) {
        this.loadAllQualifiers();
      } else {
        var iId = this.treatments[newVal].id;
        for (i = 0; i < this.allQualifiers.length; i++) {
          if (this.allQualifiers[i].iId === iId) {
            selectedQualifiers.push(this.allQualifiers[i]);
          }
        }
        this.qualifiers = selectedQualifiers.sort(this.deductibleSort('s'));
      }
      this.values = [];
      this.selectedQualifier = -1;
      this.selectedValue = -1;
      this.selectedCallType = 1;
      console.log('qualifiers', this.qualifiers);
    },
    selectedQualifier: function(newVal) {
      console.log('selectedTreatment.watch', newVal);
      if (newVal !== -1) {
        var ivId = this.qualifiers[newVal].ivId;
        var selectedValues = [];
        for (var i = 0; i < this.allValues.length; i++) {
          if (this.allValues[i].ivId === ivId) {
            selectedValues.push(this.allValues[i]);
          }
        }
        this.values = selectedValues.sort(this.deductibleSort('s'));
        console.log('values', this.values);
      }
    },
  },
  computed: {},
  methods: {
    loadAllQualifiers() {
      var selectedQualifiers = [];
      let uniqueQualifiers = new Set();
      for (var i = 0; i < this.allQualifiers.length; i++) {
        var qualifier = this.allQualifiers[i];
        if (!uniqueQualifiers.has(qualifier.d)) {
          uniqueQualifiers.add(qualifier.d);
          selectedQualifiers.push(qualifier);
        }
      }
      this.qualifiers = selectedQualifiers.sort(this.deductibleSort('d'));
    },
    close() {
      this.$emit('close', null);
    },
    async deleteTrigger(id) {
      console.log('delete', id);
      await appService.deleteALSTrigger(id);
      await this.loadExistingTriggers();
    },
    async saveColors() {
      console.log('saveColors', this.colorALS1, this.colorALS2, this.colorSCT);
      await appService.setALSColors(
        this.colorALS1,
        this.colorALS2,
        this.colorSCT
      );
    },
    async ok() {
      console.log(
        this.selectedTreatment,
        this.selectedQualifier,
        this.selectedValue,
        this.selectedCallType
      );
      var treatment = '*';
      if (this.selectedTreatment !== -1) {
        treatment = this.treatments[this.selectedTreatment].d;
      }
      var qualifier = '*';
      if (this.selectedQualifier !== -1) {
        qualifier = this.qualifiers[this.selectedQualifier].d;
      }
      var value = '*';
      if (this.selectedValue !== -1) {
        value = this.values[this.selectedValue].d;
      }
      await appService.addALSTrigger(
        treatment,
        qualifier,
        value,
        this.selectedCallType
      );
      await this.loadExistingTriggers();
    },
    deductibleSort(property) {
      return function(obj1, obj2) {
        return obj1[property] > obj2[property]
          ? 1
          : obj1[property] < obj2[property]
          ? -1
          : 0;
      };
    },
    async loadExistingTriggers() {
      this.existingTriggers = await appService.getALSTriggers();
      for (var i = 0; i < this.existingTriggers.length; i++) {
        if (this.existingTriggers[i].callType === 1) {
          this.existingTriggers[i].callTypeDesc = 'ALS, Level 1';
        } else if (this.existingTriggers[i].callType === 2) {
          this.existingTriggers[i].callTypeDesc = 'ALS, Level 2';
        } else if (this.existingTriggers[i].callType === 3) {
          this.existingTriggers[i].callTypeDesc = 'SCT';
        }
      }
      // console.log('existingTriggers', this.existingTriggers);
    },
    async alsMounted() {
    await this.loadExistingTriggers();
    var colors = await appService.getALSColors();
    this.colorALS1 = colors.alS1Color;
    this.colorALS2 = colors.alS2Color;
    this.colorSCT = colors.sctColor;
    try {
      console.log('ALSTriggersModal.mounted');
      var dataFromPLRNApp = null;
      try {
        // eslint-disable-next-line
        await CefSharp.BindObjectAsync('cefSharpBoundAsyncALS', 'bound');
        // console.log('cefSharpBoundAsyncALS.bound');
        // eslint-disable-next-line
        dataFromPLRNApp = await cefSharpBoundAsyncALS.getALSData();
      } catch (error) {
        console.log(
          'CefSharpLaunch Error: (most likely not running within RN->PL app)',
          error
        );
      }
      if (dataFromPLRNApp) {
        var alsData = JSON.parse(dataFromPLRNApp);
        console.log('alsData', alsData);

        this.treatments = alsData.treatments.sort(this.deductibleSort('d'));
        this.allQualifiers = alsData.qualifiers;
        this.loadAllQualifiers();
        this.allValues = alsData.values;
      }
    } catch (error) {
      console.log('ALSTriggersModal.mounted', error);
    }
  },
  },
};
</script>

<style scoped>
  .top-row {
    display: flex;
    justify-content: space-between;
    align-items:flex-end;
  }
  .bottom-row {
    display: flex;
    justify-content: flex-start;
  }
  .save-colors {
    display: flex;
    justify-content: flex-end;
  }
  .delete-icon {
    color: #e30531;
  }

  .delete-icon:hover {
    color: #9c253d;
    cursor: pointer;
  }

</style>
