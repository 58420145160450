import PatientWidgetBody from 'src/components/FullPatientData/PatientWidgetBody/PatientWidgetBody';
import appService from 'src/pl.service';
import UserPermissionsModalRescueNet from 'src/components/FullPatientData/UserPermissionsModal';
import UserPermissionsModal from 'src/components/FullPatientData/UserPermissionsModal.1';
import DownPayorListModal from 'src/components/FullPatientData/DownPayorListModal';
import ALSTriggersModalVue from 'src/components/FullPatientData/ALSTriggersModal';
import PatientHistoryVue from 'src/components/FullPatientData/PatientHistory';
import { mapActions, mapState, mapGetters } from 'vuex';
import {
  GET_WIDGET_VIEWS,
  GET_APPLICANT,
  GET_APPLICANT_VISITS,
  GET_MY_PERMISSIONS,
  GET_RNC_PERMISSIONS,
  GET_MY_RNA_PERMISSIONS,
  GET_PRODUCT_PERMISSIONS,
  GET_DEMOGRAPHIC,
  GET_GUARANTOR,
  SET_APPLICANT_HOME_VALIDATED_ADDRESS,
  SET_GUARANTOR_HOME_VALIDATED_ADDRESS,
  SET_PCR_HOME_VALIDATED_ADDRESS,
} from 'src/store/actions/types';
import 'src/assets/style.css';
import footer_view from '../Footer.vue';

export default {
  name: 'FullPatientData',
  components: {
    'patient-body': PatientWidgetBody,
    'user-permissions-rn': UserPermissionsModalRescueNet,
    'user-permissions': UserPermissionsModal,
    'als-trigger': ALSTriggersModalVue,
    'patient-history': PatientHistoryVue,
    'down-payor-list': DownPayorListModal,
    footer_view
  },
  data() {
    return {
      isMapOverviewModalVisible: false,
      areDirectionsVisible: false,
      areFlightDirectionsVisible: false,
      isAddressModalVisible: false,
      isDownPayorListModalModal: false,
      insuranceFinderData: null,
      permissions: {},
      isRN2PLApp: false,
      patientAddresses: [],
    };
  },
  async created() {
    await this.loadApplicantData();
    // TODO: refactor when profiles API are done, we only need the widget layout view name...
    this[GET_WIDGET_VIEWS]();
    this[GET_PRODUCT_PERMISSIONS]();
    await appService.rescueNetToPlTripHistory(this.applicant.id);
  },
  async mounted() {
    await this.determineRN2PLAppStatus();
  },
  computed: {
    ...mapGetters([
      'isRescueNetNoPcr',
      'isRescueNet',
      'isTriTech',
      'applicant',
    ]),
    ...mapState({
      widgetViewName: state => state.widgetView.name,
      applicantVisits: state => state.applicant.visits.data,
      applicantVisitsRetrieved: state => state.applicant.visits.retrieved,
      demographic: state => state.demographic,
    }),
  },
  watch: {
    applicantVisitsRetrieved(retrieved) {
      // once we get the applicant visits,
      // retrieve the relative permissions
      if (retrieved) {
        if (this.applicantVisits.length && this.applicantVisits[0].data) {
          //this[GET_RNC_PERMISSIONS]();
          this[GET_MY_RNA_PERMISSIONS]();
        } else {
          this[GET_MY_RNA_PERMISSIONS]();
        }
      }
    },
  },
  methods: {
    ...mapActions([
      GET_WIDGET_VIEWS,
      GET_APPLICANT,
      GET_APPLICANT_VISITS,
      GET_MY_PERMISSIONS,
      GET_RNC_PERMISSIONS,
      GET_MY_RNA_PERMISSIONS,
      GET_DEMOGRAPHIC,
      GET_GUARANTOR,
      GET_PRODUCT_PERMISSIONS,
      SET_APPLICANT_HOME_VALIDATED_ADDRESS,
      SET_GUARANTOR_HOME_VALIDATED_ADDRESS,
      SET_PCR_HOME_VALIDATED_ADDRESS,
    ]),
    /**
     * for partial updates from the windows app...
     * @see template.html
     */
    async doStuff() {
      await this.loadApplicantData();
    },
    async loadApplicantData() {
      this[GET_APPLICANT]();
      this[GET_APPLICANT_VISITS]();
      this[GET_DEMOGRAPHIC](this.applicant.applicantId);
    },
    closeDirections() {
      this.areDirectionsVisible = false;
    },
    closeFlightDirections() {
      this.areFlightDirectionsVisible = false;
    },
    closeMap() {
      this.isMapOverviewModalVisible = false;
    },
    closeAddressModal() {
      this.isAddressModalVisible = false;
    },
    closeDownPayorListModal() {
      this.isDownPayorListModalModal = false;
    },
    async determineRN2PLAppStatus() {
      try {
        // eslint-disable-next-line
        await CefSharp.BindObjectAsync('CefSharpMessageBox', 'bound');
        this.isRN2PLApp = true;
      } catch (error) {
        console.log(
          'testCefMessageBox.CefSharpLaunch Error: (most likely not running within RN->PL app)',
          error
        );
      }
      // this.isRN2PLApp = true
    },
  },
};
