<template>
  <div class="content">
    <div v-if="isAuthenticated">
      Hello authenticated user!
      <p>Name: {{ profile.firstName }}</p>
    </div>
    <div v-if="!isClaimsClarity" class="load-message">Billing Pro will populate when a valid record is created by entering the patient’s name.</div>
  </div>
</template>

<script>
import { bearerTokenKey } from 'src/constants/localStorage';

export default {
  data() {
    return {
      isAuthenticated: false,
      profile: {},
      isClaimsClarity: false,
    };
  },
  methods: {},
  created() {
    console.log('QuickLogin.created', this.$router.currentRoute.query);
    if (this.$router.currentRoute.query.token) {
      window.localStorage.setItem(
        bearerTokenKey,
        this.$router.currentRoute.query.token
      );
      if (this.$router.currentRoute.query.portalBaseUrl) {
        window.localStorage.setItem('portalUrl', this.$router.currentRoute.query.portalBaseUrl);
        this.isClaimsClarity = this.$router.currentRoute.query.route.includes('ClaimsClarity');
      }
      this.isAuthenticated = true;
      if (this.$router.currentRoute.query.applicantId) {
        window.location = `fullpatientData/${this.$router.currentRoute.query.applicantId}`;
      }
      if (this.$router.currentRoute.query.route) {
        window.location = this.$router.currentRoute.query.route;
      }
    }
  },
};
</script>
