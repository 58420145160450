/**
 * creates and returns a new column object for the grid
 */
export const createColumn = ({ id, handleResizeObserver }) => {
  const column = {
    id,
    cells: [],
    componentInstance: null,
    resizeObserver: null,
    handleResizeObserver,
  };

  // bind methods
  column.mountedCallback = function(componentInstance) {
    // keep the component instance
    this.componentInstance = componentInstance;
    // set a resize observer, so we know when the cells/widgets change in height
    this.resizeObserver = new ResizeObserver(() => {
      // delegate the event
      this.handleResizeObserver(this);
    });
    this.resizeObserver.observe(this.componentInstance.wrapperEl);
  }.bind(column);

  column.unmountedCallback = function() {
    // cleanup
    if (this.resizeObserver) {
      this.resizeObserver.unobserve(this.componentInstance.wrapperEl);
      this.resizeObserver = null;
    }
    this.componentInstance = null;
  }.bind(column);

  return column;
};
