import { mapGetters, mapActions, mapState } from 'vuex';
import Grid from 'src/components/FullPatientData/PatientWidgetBody/Grid/Grid';
import { getWidgetByType } from 'src/utils/getWidgetByType';
import {
  READ_PROFILES,
  CREATE_PROFILE,
  UPDATE_ACTIVE_PROFILE,
  READ_ACTIVE_PROFILE,
  RESET_PROFILE,
  ADD_PROFILE_WIDGET,
  DELETE_PROFILE_WIDGET,
  UPDATE_PROFILE_EXPAND_OPTION,
} from 'src/store/actions/types';
import { operations } from 'src/constants/operations';
import { statuses } from 'src/constants/statuses';
import { getDefaultWidgets } from 'src/utils/getDefaultWidgets';
import { getDefaultExpandOptions } from 'src/utils/getDefaultExpandOptions';
import { widgetViewDefaultWidgets } from 'src/constants/widgets';
import { expandOptions } from 'src/constants/expandOptions';
import plService from 'src/pl.service.js';

export default {
  components: { Grid },
  computed: {
    ...mapGetters([
      'isTriTech',
      'isRescueNetNoPcr',
      'isRescueNet',
      'isAdmin',
      'hasDeductibleMonitoring',
    ]),
    ...mapState({
      widgetViewName: state => state.widgetView.name,
      profiles: state => state.profiles.data,
      profilesReadStatus: state =>
        state.profiles.operations[operations.read].status,
      activeProfileReadStatus: state =>
        state.profiles.active.operations[operations.read].status,
      profilesCreateStatus: state =>
        state.profiles.data.find(
          profile => profile.operations[operations.create]?.status
        )?.operations[operations.create]?.status,
    }),
    data() {
      return {
        isRN2PLApp: false,
      };
    },
    ...mapGetters(['getActiveProfile']),
    /**
     * augments the widgets in the store with any extra data needed
     * only for in-memory usage (ie. the component import)
     * since that cannot be serialized and should not be included in the store.
     * @returns {array}
     */
    widgets: function() {
      return (
        this.getActiveProfile?.widgets.map(widget => ({
          ...widget,
          component: getWidgetByType(widget.meta.type)?.component,
        })) ?? []
      );
    },
    /**
     * retrieve any user defined layouts or default to an empty array
     * @returns {array}
     */
    layouts: function() {
      return this.getActiveProfile?.layouts ?? [];
    },
    /**
     * returns true only if there are profiles (loaded) and the active profile is not currently resetting
     * @returns {boolean}
     */
    isGridVisible: function() {
      return (
        this.profiles.length &&
        this.getActiveProfile?.operations.reset.status !== statuses.pending
      );
    },
    /**
     * options for the widgets dropdown list
     * @returns {object}
     */
    widgetsDropdownOptions: function() {
      return (
        widgetViewDefaultWidgets[this.widgetViewName]?.map(widget => ({
          text: widget.text,
          value: widget.type,
          icon: widget.icon,
        })) ?? []
      );
    },
    /**
     * options for the expand options
     * @returns {object}
     */
    expandOptions: function() {
      return (
        expandOptions?.map(option => ({
          name: option.name,
          expandAll: option.expandAll,
        })) ?? []
      );
    },
    /**
     * getter and setter for the list of selected widget types
     */
    widgetsDropdownSelected: {
      /**
       * gets the list of widgets in the active profile
       * @returns {array}
       */
      get: function() {
        return (
          this.getActiveProfile?.widgets.map(widget => widget.meta.type) ?? []
        );
      },
      /**
       * adds or removes any widgets that just changed
       * @param {array} selectedTypes
       */
      set: function(selectedTypes) {
        // find out which types changed
        widgetViewDefaultWidgets[this.widgetViewName]?.forEach(dw => {
          const selected = selectedTypes.includes(dw.type);
          const wasSelected = this.widgetsDropdownSelected.includes(dw.type);
          if (selected !== wasSelected) {
            if (selected) {
              // add the widget
              this[ADD_PROFILE_WIDGET]({ type: dw.type });
            } else {
              // remove the widget
              this[DELETE_PROFILE_WIDGET]({ type: dw.type });
            }
          }
        });
      },
    },
    /**
     * getter and setter for the list of expand all settings
     */
    expandOptionsSelected: {
      /**
       * gets the expand setting in the active profile
       * @returns {array}
       */
      get: function() {
        return (
          this.getActiveProfile?.expandOptions
            ?.filter(op => op.expandAll === 'true')
            .map(option => option.name) ?? []
        );
      },
      /**
       * adds or removes any options that just changed
       * @param {array} selectedOptions
       */
      set: function(selectedOptions) {
        console.log('expandOptionsSelected selectedOptions', selectedOptions);
        expandOptions?.forEach(dw => {
          const selected = selectedOptions.includes(dw.name);
          const wasSelected = this.expandOptionsSelected.includes(dw.name);
          if (selected !== wasSelected) {
            this[UPDATE_PROFILE_EXPAND_OPTION]({ name: dw.name });
          }
        });
      },
    },
  },
  watch: {
    widgetViewName: {
      immediate: true,
      handler(current, previous) {
        // make sure we have the widget view name before
        // attempting to retrieve the profiles
        if (previous !== current && current) {
          plService.setDefaultProfile(this.widgetViewName);
          this[READ_PROFILES]();
        }
      },
    },
    /**
     * if this user does not have any profiles stored
     * create the default one or load the active profile
     *
     * @see profilesCreateStatus for how the active profile is set
     *      upon creation of the default profile
     */
    profilesReadStatus: {
      immediate: true,
      handler(current, previous) {
        // TODO: handle failure
        if (previous !== current && current === statuses.success) {
          if (!this.profiles.length) {
            // create the default profile
            var defaultWidgets = getDefaultWidgets(this.widgetViewName);
            var defaultExpandOptions = getDefaultExpandOptions();
            this[CREATE_PROFILE]({
              name: 'default',
              widgets: defaultWidgets,
              expandOptions: defaultExpandOptions,
              layouts: [],
            });
          } else {
            // load the active profile
            this[READ_ACTIVE_PROFILE]();
          }
        }
      },
    },
    /**
     * upon creating the first profile
     * set it as the active profile
     */
    profilesCreateStatus: {
      immediate: true,
      handler(current, previous) {
        // TODO: handle failure
        if (previous !== current && current === statuses.success) {
          this[UPDATE_ACTIVE_PROFILE](this.profiles[0].id);
        }
      },
    },
    /**
     * upon reading the active profile
     * make sure it matches one of the stored ones,
     * otherwise, default to the first profile
     */
    activeProfileReadStatus: {
      immediate: true,
      handler(current, previous) {
        // TODO: handle failure
        if (
          previous !== current &&
          current === statuses.success &&
          !this.getActiveProfile
        ) {
          this[UPDATE_ACTIVE_PROFILE](this.profiles[0].id);
        }
      },
    },
  },
  methods: {
    ...mapActions([
      READ_PROFILES,
      CREATE_PROFILE,
      UPDATE_ACTIVE_PROFILE,
      READ_ACTIVE_PROFILE,
      RESET_PROFILE,
      ADD_PROFILE_WIDGET,
      DELETE_PROFILE_WIDGET,
      UPDATE_PROFILE_EXPAND_OPTION,
    ]),
    /**
     * resets the active's profile (layouts and widgets) to their defaults
     */
    resetProfileHandler() {
      this[RESET_PROFILE]();
    },
    async defaultLayout() {
      await plService.deleteWidgets();
      plService.clearProfile();
      this.$router.go();
    },
    showModal() {
      if (this.isRescueNet || this.isRescueNetNoPcr) {
        this.$bvModal.show('modal-userPermissionsRn');
      } else if (this.isTriTech) {
        this.$bvModal.show('modal-userPermissions');
      }
    },
    showPatientHistoryModal() {
      this.$bvModal.show('modal-patientHistory');
    },
    showALSModal() {
      if (this.isRN2PLApp) {
        this.$bvModal.show('modal-als');
      }
    },
    showDownPayorListModal() {
      this.$bvModal.show('modal-downPayorList');
    },
    //for the ALS modal
    async determineRN2PLAppStatus() {
      try {
        // eslint-disable-next-line
        await CefSharp.BindObjectAsync('CefSharpMessageBox', 'bound');
        this.isRN2PLApp = true;
      } catch (error) {
        console.log(
          'testCefMessageBox.CefSharpLaunch Error: (most likely not running within RN->PL app)',
          error
        );
      }
      // this.isRN2PLApp = true
    },
  },
  async created() {
    await this.determineRN2PLAppStatus();
  },
};
