import appService from 'src/pl.service';
import {
  SET_APPLICANT_CREDIT,
  SET_GUARANTOR_CREDIT,
} from 'src/store/actions/types';

/**
 * retrieves the applicant's and guarantor's credit from the API and
 * commits the data to the store using the
 * SET_APPLICANT_CREDIT action type and
 * SET_GUARANTOR_CREDIT if there is such data...
 *
 * additionally, if runSelfPayAnalyzer:true,
 * it will first invoke the runSelfPayAnalyzer API and then get the credit.
 * those two operations MUST happen sequentially because the credit
 * we get, may get updated from the runSelfPayAnalyzer API...
 */
export const getCredit = async (
  { commit, state },
  { applicantId = undefined, runSelfPayAnalyzer = false } = {
    applicantId: undefined,
    runSelfPayAnalyzer: false,
  }
) => {
  const id = String(applicantId ?? state.applicant.id);

  // reset data and set loading state
  commit(SET_APPLICANT_CREDIT, {
    loading: true,
    data: [],
  });
  commit(SET_GUARANTOR_CREDIT, { loading: true, data: [] });

  // optionally run self pay
  if (runSelfPayAnalyzer) {
    await appService.runSelfPayAnalyzer(id);
  }

  // get the credit report or
  // assign an empty array in case the search has not been performed yet (the API returns no data in that case)
  const data = (await appService.getCredit(id)) || [];

  // retrieve in parallel extra credit information,
  // using the returned credit report id of each credit report
  // (we can have multiple reports)
  (
    await Promise.all(
      data.map(creditReport => {
        const { creditId } = creditReport;
        return Promise.all([
          appService.getCreditTradeAccount(creditId),
          appService.getCreditEmployment(creditId),
          appService.getCreditCollectionAccount(creditId),
        ]);
      })
    )
  ).forEach((extraData, index) => {
    data[index].tradeAccounts = extraData[0] || [];
    data[index].employment = extraData[1] || [];
    data[index].collectionAccounts = extraData[2] || [];
  });

  // commit to mutations
  commit(SET_APPLICANT_CREDIT, {
    loading: false,
    selfPayAnalyzer: runSelfPayAnalyzer,
    data: data.filter(creditRecord => String(creditRecord.applicantId) === id),
  });
  commit(SET_GUARANTOR_CREDIT, {
    loading: false,
    data: data.filter(creditRecord => String(creditRecord.applicantId) !== id),
  });
};
