<template>
  <b-modal body-class="permissions-modal-body" id="modal-userPermissionsRn" size="sm" hide-header v-on:show="permissionsMounted">
    <b-tabs nav-class="tablist-text" v-model="tabIndex" pills card align="center">
      <b-tab title="User Permissions" variant="primary" active>
        <b-row>
          <b-col>
            <div class="label">User</div>
            <b-form-select
              v-model="selectedEmployee"
              :options="optionEmployees"
              value-field="code"
              text-field="username"
              size="sm"
            ></b-form-select>
          </b-col>
          <b-col>
            <div class="label">Company</div>
            <b-form-select
              v-model="selectedCompany"
              :options="optionCompanies"
              value-field="code"
              text-field="name"
              size="sm"
            ></b-form-select>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group class="mt-3" label="Select Permissions" v-slot="{ ariaDescribedby }">
              <b-form-checkbox-group
                id="permissions-checkbox-group"
                :options="optionPermissions"
                :aria-describedby="ariaDescribedby"
                v-model="selectedPermissions"
                stacked
              ></b-form-checkbox-group>
            </b-form-group>
          </b-col>
        </b-row>
      </b-tab>
      <b-tab title="Enabled Companies" variant="primary">
        <b-form-group
          class="company-form"
          label="Select Companies to Enable"
          v-slot="{ ariaDescribedby }"
        >
          <b-form-checkbox
            v-for="option in companiesToEnable"
            v-model="selectedCompaniesToEnable"
            :key="option.code"
            :value="option.code"
            :aria-describedby="ariaDescribedby"
            name="flavour-2a"
          >
            {{ option.name }}
          </b-form-checkbox>
        </b-form-group>
      </b-tab>
    </b-tabs>
      <template #modal-footer="{ close }">
        <b-button size="sm" variant="secondary" @click="close()">
          Close
        </b-button>
        <b-button size="sm" variant="primary" @click="save()">
          <span v-if="!saving">Save
            <span v-if="tabIndex === 0">User Permissions</span>
            <span v-else>Enabled Companies</span>
          </span>
          <span v-else>
            Saving <b-spinner small variant="light"></b-spinner>
          </span>
        </b-button>
      </template>
  </b-modal>
</template>
<script>
  import appService from 'src/pl.service';

  export default {
    props: {},
    components: {},
    data: function () {
      return {
        selectedCompany: -1,
        optionCompanies: [],
        selectedCompaniesToEnable: [],
        companiesToEnable: [],
        selectedPermissions: [],
        optionPermissions: [
        { text: 'Run Demographics', value: 0 },
        { text: 'Update from Demographics', value: 1 },
        { text: 'Run Self Pay', value: 2 },
        { text: 'Run Insurance Verifier', value: 3 },
        { text: 'Run Insurance Discovery', value: 4 },
        { text: 'View Applicant', value: 5 },
        { text: 'View PCR', value: 6 },
        { text: 'Flag As Incorrect', value: 9 },
        { text: 'View Demographics', value: 10 },
        { text: 'View SelfPay', value: 11 },
        { text: 'View Insurance Widget', value: 12 },
        { text: 'View Trip Details', value: 13 },
        { text: 'View Attachments', value: 14 },
        { text: 'Admin', value: 7 },
      ],
        tabIndex: 0,
        selectedEmployee: -1,
        optionEmployees: [],
        saving: false,
      };
    },
    watch: {
      selectedCompany: function () {
        console.log(
          'selectedCompany',
          this.selectedCompany,
          this.selectedEmployee
        );
        this.getPermissions(this.selectedEmployee, this.selectedCompany);
      },
      selectedEmployee: function () {
        console.log(
          'selectedEmployee',
          this.selectedCompany,
          this.selectedEmployee
        );
        this.getPermissions(this.selectedEmployee, this.selectedCompany);
      },
    },
    computed: {},
    methods: {
      getPermissions(rnEmployeeId, rnCompanyId) {
        var userId = -1;
        for (var i = 0; i < this.optionEmployees.length; i++) {
          console.log(
            rnEmployeeId,
            this.optionEmployees[i].code,
            this.optionEmployees[i].id
          );
          if (this.optionEmployees[i].code === rnEmployeeId) {
            if (this.optionEmployees[i].id) {
              userId = this.optionEmployees[i].id;
              break;
            }
          }
        }
        this.selectedPermissions = [];
        appService
          .getRescueNetPermissionsForUser(userId, rnCompanyId)
          .then(data => {
            this.checkedNames = [];
            for (var i = 0; i < data.length; i++) {
              this.checkedNames[i] = data[i].permission + '';
              this.selectedPermissions.push(data[i].permission);
            }
            return data;
          })
          .catch(function (error) {
            console.error(error);
          });
      },
      close() {
        this.$emit('close', null);
      },
      async save() {
        //If we are in user permissions, call save user
        this.saving = true;

        if(this.tabIndex === 0) {
          this.saveUser();
        }
        //else save the company settings
        else {
          this.saveCompanies();
        }
        this.saving = false;
      },
      async saveUser() {
        var userId = -1;
        var username = null;
        if (this.selectedEmployee !== -1) {
          for (var i = 0; i < this.optionEmployees.length; i++) {
            if (this.optionEmployees[i].code === this.selectedEmployee) {
              username = this.optionEmployees[i].username;
              if (this.optionEmployees[i].id) {
                console.log(
                  'Employee Exists UserPermissionsModal.saveUser',
                  this.selectedCompany,
                  this.optionEmployees[i].id,
                  this.selectedPermissions
                );
                userId = this.optionEmployees[i].id;
              } else {
                console.log('create new employee');
                var data = await appService.addUser(
                  this.optionEmployees[i].username,
                  this.optionEmployees[i].first,
                  this.optionEmployees[i].last
                );
                this.optionEmployees[i].id = data.userId;
                userId = data.userId;
              }
            }
          }
        }

        try {
          appService.updateRescueNetPermissions(
            this.selectedCompany,
            userId,
            this.selectedPermissions
          );
          this.displaySuccessToast(username + ' Saved');
        } catch (error) {
          console.error(error);
          this.displayErrorToast(
            'UserPermissionsModal.saveUser: ' + error.message
          );
        }
      },
      saveCompanies() {
        console.log(
          'UserPermissionsModal.saveCompanies',
          this.selectedCompaniesToEnable
        );

        try {
          appService.updateRescueNetCompanyPermissions(
            this.selectedCompaniesToEnable
          );
          this.displaySuccessToast('Enabled Companies Saved');

        } catch (error) {
          console.error(error);
          this.displayErrorToast(
            'UserPermissionsModal.saveCompanies: ' + error.message
          );
        }
      },
      async permissionsMounted() {
        this.selectedEmployee = -1;
        this.selectedCompany = -1;
      try {
        var enabledCompanies = await appService.getRescueNetCompanyPermissions();
        this.selectedCompaniesToEnable = [];
        for (var l = 0; l < enabledCompanies.length; l++) {
          this.selectedCompaniesToEnable.push(enabledCompanies[l]);
        }
        var dataFromPLRNApp = null;
        try {
          // eslint-disable-next-line
          await CefSharp.BindObjectAsync('cefSharpBoundAsync4', 'bound');
          console.log('UserPermissionsModal.bound');
          // eslint-disable-next-line
          dataFromPLRNApp = await cefSharpBoundAsync4.showMessage();
        } catch (error) {
          console.log(
            'CefSharpLaunch Error: (most likely not running within RN->PL app)',
            error
          );
        }
        if (dataFromPLRNApp) {
          var usersAndCompanies = JSON.parse(dataFromPLRNApp);
          console.log(
            'UserPermissionsModal.usersAndCompanies',
            usersAndCompanies
          );
          var allUsers = await appService.getAllUsersForCompany();
          console.log('UserPermissionsModal.allUsers', allUsers);
          this.optionEmployees = usersAndCompanies.employees;
          this.optionEmployees.unshift({
            code: -1,
            username: 'Default'
          });
          var encodedUsernames = {};
          var companyIdExtension = '_' + allUsers[0].companyId;
          for (var j = 0; j < this.optionEmployees.length; j++) {
            var encodedName =
              this.optionEmployees[j].username + companyIdExtension;
            encodedUsernames[encodedName] = this.optionEmployees[j];
          }
          console.log('UserPermissionsModal.encodedUsernames', encodedUsernames);

          for (var i = 0; i < allUsers.length; i++) {
            var rnUser = encodedUsernames[allUsers[i].username];
            if (rnUser) {
              rnUser.id = allUsers[i].userId;
            }
          }
          console.log(
            'UserPermissionsModal.this.optionEmployees',
            this.optionEmployees
          );

          this.optionCompanies = usersAndCompanies.companies;
          this.companiesToEnable = [];
          for (var k = 0; k < usersAndCompanies.companies.length; k++) {
            this.companiesToEnable.push(usersAndCompanies.companies[k]);
          }
          this.optionCompanies.unshift({
            code: -1,
            name: 'Default'
          });
          this.getPermissions(-1, -1);
        }
      } catch (error) {
        console.log('UserPermissionsModal.mounted', error);
      }
      },
    },
  };
</script>

<style scoped>
  ::v-deep .permissions-modal-body {
    padding: 0px;
  }
  ::v-deep .tablist-text {
  font-size: 0.8rem;
}

.company-form {
  max-height: 19.9rem;
  overflow-y: auto;
}
</style>
