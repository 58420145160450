/**
 *
 * @param {*} property
 * @returns
 */
export const deductibleSort = property => (obj1, obj2) =>
  obj1[property] < obj2[property]
    ? 1
    : obj1[property] > obj2[property]
    ? -1
    : 0;
