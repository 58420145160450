import axios from "axios";

const appService = {
  async getEligibilityAggregateData(
    companyIds,
    start,
    end,
    selectedStatus,
    selectedPayors,
    selectedVendor,
    confidenceScoreMin,
    confidenceScoreMax,
    groupByCompany,
    groupByPayorName,
    groupByStatus,
    groupByNpiCode,
    groupByVendor,
    groupBySearchOptions,
    groupByTime
  ) {
    console.log(
      "getEligibilityAggregateData",
      companyIds,
      start,
      end,
      selectedStatus,
      selectedPayors,
      selectedVendor,
      groupByCompany,
      groupByCompany,
      groupByPayorName,
      groupByStatus,
      groupByNpiCode,
      groupByVendor,
      groupBySearchOptions,
      groupByTime,
      confidenceScoreMin,
      confidenceScoreMax,
    );
    var i;
    var payorUrl = "";
    if (selectedPayors && selectedPayors.length > 0) {
      for (i = 0; i < selectedPayors.length; i++) {
        payorUrl += `&p=${selectedPayors[i]}`;
      }
    }
    var statusUrl = "";
    if (selectedStatus && selectedStatus.length > 0) {
      for (i = 0; i < selectedStatus.length; i++) {
        statusUrl += `&s=${selectedStatus[i]}`;
      }
    }
    var vendorUrl = "";
    if (selectedVendor) {
      vendorUrl = `&vendorEnum=${selectedVendor}`;
    }
    var companyUrl = "";
    for (i = 0; i < companyIds.length; i++) {
      companyUrl += `&c=${companyIds[i]}`;
    }

    var confidenceScoreMinUrl = "";
    if (confidenceScoreMin != 0) {
      confidenceScoreMinUrl = `&confidenceScoreMin=${confidenceScoreMin}`;
    }
    var confidenceScoreMaxUrl = "";
    if (confidenceScoreMax != 100) {
      confidenceScoreMaxUrl = `&confidenceScoreMax=${confidenceScoreMax}`;
    }

    const { data } = await axios.get(
      `/api/v1.0/reporting/eligibilityAggregate/grouped?start=${start}&end=${end}&groupByCompany=${groupByCompany}&groupByPayorName=${groupByPayorName}&groupByStatus=${groupByStatus}&groupByNpiCode=${groupByNpiCode}&groupByVendor=${groupByVendor}&groupBySearchOptions=${groupBySearchOptions}&groupByTime=${groupByTime}${companyUrl}${payorUrl}${statusUrl}${vendorUrl}${confidenceScoreMinUrl}${confidenceScoreMaxUrl}`
    );
    console.log("billingService.getEligibilityAggregateData:", data);
    return data;
  },
  async getDemographicAggregateData(
    companyIds,
    start,
    end,
    selectedVendor,
    isNoHit,
    hasSsn,
    hasDob,
    hasPhone,
    confidenceScoreMin,
    confidenceScoreMax,
    groupByCompany,
    groupByNotHit,
    groupByHasSsn,
    groupByHasDob,
    groupByHasPhone,
    groupByVendor,
    groupByTime
  ) {
    console.log(
      "getDemographicAggregateData",
      companyIds,
      start,
      end,
      selectedVendor,
      isNoHit,
      hasSsn,
      hasDob,
      hasPhone,
      confidenceScoreMin,
      confidenceScoreMax,
      groupByCompany,
      groupByNotHit,
      groupByHasSsn,
      groupByHasDob,
      groupByHasPhone,
      groupByVendor,
      groupByTime
    );
    var i;
    var vendorUrl = "";
    if (selectedVendor) {
      vendorUrl = `&vendorEnum=${selectedVendor}`;
    }
    var isNoHitUrl = "";
    if (isNoHit != null) {
      isNoHitUrl = `&isNoHit=${isNoHit}`;
    }
    var hasDobUrl = "";
    if (hasDob != null) {
      hasDobUrl = `&hasDob=${hasDob}`;
    }
    var hasSsnUrl = "";
    if (hasSsn != null) {
      hasSsnUrl = `&hasSsn=${hasSsn}`;
    }
    var hasPhoneUrl = "";
    if (hasPhone != null) {
      hasPhoneUrl = `&hasPhone=${hasPhone}`;
    }
    var companyUrl = "";
    for (i = 0; i < companyIds.length; i++) {
      companyUrl += `&c=${companyIds[i]}`;
    }
    var confidenceScoreMinUrl = "";
    if (confidenceScoreMin != 0) {
      confidenceScoreMinUrl = `&confidenceScoreMin=${confidenceScoreMin}`;
    }
    var confidenceScoreMaxUrl = "";
    if (confidenceScoreMax != 100) {
      confidenceScoreMaxUrl = `&confidenceScoreMax=${confidenceScoreMax}`;
    }
    const { data } = await axios.get(
      `/api/v1.0/reporting/demographicAggregate/grouped?start=${start}&end=${end}&groupByCompany=${groupByCompany}&groupByNotHit=${groupByNotHit}&groupByHasSsn=${groupByHasSsn}&groupByHasDob=${groupByHasDob}&groupByHasPhone=${groupByHasPhone}&groupByVendor=${groupByVendor}&groupByTime=${groupByTime}${companyUrl}${vendorUrl}${isNoHitUrl}${hasSsnUrl}${hasDobUrl}${hasPhoneUrl}${confidenceScoreMinUrl}${confidenceScoreMaxUrl}`
    );
    console.log("billingService.getDemographicAggregateData:", data);
    return data;
  },
};

export default appService;
