import appService from 'src/pl.service';
import { mapState } from 'vuex';

export default {
  data() {
    return {
      dmqData: [],
      displayedDmqItem: null,
      totalItems: 0,
      selected: -1,
      statusDetails: [],
      displayedDmqItems: [],
      perPage: 7,
      currentPage: 1,
      totalRows: 1,
      activeRow: 'Total',
      fields: ['name', 'count', 'clear_transactions'],
      isBusy: false,
      removeHovered: false,
      pendingDmqId: -1,
      selectedPayor: 'All',
      undoArray: [],
      filter: null,
      searchFilter: null,
      clearModal: {name : ""},
      companies: [],
      selectedCompany: -1
    };
  },
  watch: {
    selectedCompany: function(newVal){
      this.resetData();
      this.loadData(newVal);
    }
  },
  computed: {
    ...mapState({
      loggedInApplicantId: state => state.applicant.applicantId,
    }),
    itemsForList: {
      get: function() {
        if (this.displayedDmqItems.length) {
          var items = this.displayedDmqItems;
          if (this.selectedPayor != 'All') {
            items = items.filter(x => x.payorName === this.selectedPayor);
          }
          if (this.searchFilter) {
            var spaceIndex = this.searchFilter.indexOf(' ');
            //if it contains two words, we treat it as firstName lastName
            if (spaceIndex > 0) {
              var tempFilter = this.searchFilter;
              var firstName = tempFilter.substring(0, spaceIndex);
              var lastName = tempFilter.substring(spaceIndex + 1);
              items = items.filter(
                item =>
                  item.firstname?.toLowerCase().startsWith(firstName) &&
                  item.lastname?.toLowerCase().startsWith(lastName)
              );
            }
            //if there is no space, we do search as a whole word for firstname, last name and visitnumber, runnumber, applicantId
            else {
              items = items.filter(
                item =>
                  item.firstname?.toLowerCase().startsWith(this.filter) ||
                  item.lastname?.toLowerCase().startsWith(this.filter) ||
                  item.visitNumber?.toLowerCase().startsWith(this.filter) ||
                  item.runNumber?.toString().startsWith(this.filter) ||
                  item.applicantId?.toString().startsWith(this.filter)
              );
            }
          }
          this.totalRows = items.length;
          console.log('Total rows after filter', items.length);
          return items.slice(
            (this.currentPage - 1) * this.perPage,
            this.currentPage * this.perPage
          );
        }
        return [];
      },
    },
    payorOptions: {
      get: function() {
        var payorList = [];
        if (this.displayedDmqItems) {
          payorList = [
            ...new Set(this.displayedDmqItems.map(d => d.payorName)),
          ];
        }
        payorList = payorList.sort();
        payorList.unshift('All');
        return payorList;
      },
    },
  },

  methods: {
    async loadData(companyId) {

      this.isBusy = true;

      this.dmqData = await appService.getDeductibleMonitoringItems(companyId);

      this.totalRows = this.dmqData.length;
      this.displayedDmqItems = this.dmqData;

      console.log('loadData', this.displayedDmqItems);

      let distinctReasons = Array.from(new Set(this.dmqData.map((item) => item.completedStatus)));
      let distinctPayors = Array.from(new Set(this.dmqData.map((item) => item.payorName)));

      // Set up statusDetails based on the list of distinct reasons we found in our return
      for(let i = 0; i < distinctReasons.length; i++){
        // Initialize if needed
        if(!this.statusDetails) this.statusDetails = [];

        this.statusDetails.push({
          name: distinctReasons[i],
          clear_transactions: distinctReasons[i] !== 'Deductible Met',
          count: this.dmqData.filter(dd => dd.completedStatus === distinctReasons[i]).length
        
        });
      }

      // Set up the payorOptions based on the distinct Payors that we found in the return
      for(let p = 0; p < distinctPayors.length; p++){
        // Initialize if needed
        if(!this.payorOptions) this.payorOptions = [];

        if (this.payorOptions.findIndex(x => x === distinctPayors[p]) < 0){
          this.payorOptions.push(distinctPayors[p]);
        }

      }

      this.statusDetails.push({
        name: 'Total',
        count: this.totalRows,
      });

      // Navigate to the applicant based on loggedInApplicantId, OR go to the first item in the list
      await this.selectLoggedInOrFirst();

      this.isBusy = false;
    },
    resetData(){
      this.totalRows = 1;
      this.dmqData = [];
      this.displayedDmqItems = [];
      this.displayedDmqItem = null;
      this.statusDetails = [];
    },
    updateDisplay(dmqItem) {
      // If no applicant data was found for this record, remove it from the view
      if(!dmqItem){
        this.selected = -1
        this.displayedDmqItem = null;
      }

      if (!this.removeHovered) {
        this.selected = dmqItem.deductibleManagementQueueId;
        this.displayedDmqItem = dmqItem;
      }
    },
    get30Url(patientId) {
      return window.location.origin + '/fullpatientData/' + patientId;
    },
    confirmPendingReview() {
      this.removeFromView(this.pendingDmqId, 'Pending');
      this.$root.$emit('bv::hide::modal', 'pending-review-modal');
    },
    undoFinishReview(undoDmqItem = null) {
      //adjust the counts on the overview board
      //Undo will send a message with id and reviewed set to false
      if (this.undoArray.length) {
        var dmqItem = null;
        if (undoDmqItem) {
          dmqItem = undoDmqItem;
          this.undoArray = this.undoArray.filter(
            d =>
              d.deductibleManagementQueueId !=
              undoDmqItem.deductibleManagementQueueId
          );
        } else {
          dmqItem = this.undoArray.pop();
        }
        var id = dmqItem.deductibleManagementQueueId;
        appService.setDMQItemAsReviewed(id, false);
        console.log(this.undoArray.length);
        var status = dmqItem.completedStatus;
        this.dmqData.push(dmqItem);
        //Will add it back to dmqData (and need to sort)
        this.dmqData.sort(function(a, b) {
          var n1 = a.firstname + '_' + a.lastname;
          var n2 = b.firstname + '_' + b.lastname;
          if (n1 > n2) return 1;
          else if (n1 < n2) return -1;
          return 0;
        });

        if (status === this.activeRow) {
          this.displayedDmqItems.push(dmqItem);
          this.displayedDmqItems.sort(function(a, b) {
            var n1 = a.firstname + '_' + a.lastname;
            var n2 = b.firstname + '_' + b.lastname;
            if (n1 > n2) return 1;
            else if (n1 < n2) return -1;
            return 0;
          });
        } else if (this.activeRow === 'Total') {
          //If it was on total add it to the displayedItems will
          //add a duplicate because the array is assigned via reference
          this.displayedDmqItems = this.dmqData;
        }
        this.statusDetails.find(x => x.name === status).count++;
        this.statusDetails.find(x => x.name === 'Total').count++;

        this.displaySuccessToast('Undo Successful');
      }
    },
    async removeFromView(dmqItem) {
      var id = dmqItem.deductibleManagementQueueId;
      var status = dmqItem.completedStatus;
      appService.setDMQItemAsReviewed(id, true);
      this.undoArray.push(dmqItem);
      //clear the currently selected and remove the item from all saved arrays
      if (
        this.displayedDmqItem &&
        this.displayedDmqItem.deductibleManagementQueueId === id
      ) {
        this.displayedDmqItem = null;
        this.selected = -1;
      }

      this.dmqData = this.dmqData.filter(
        d => d.deductibleManagementQueueId != id
      );
      this.displayedDmqItems = this.displayedDmqItems.filter(
        d => d.deductibleManagementQueueId != id
      );
      //remove 1 from the count of the items status
      this.statusDetails.find(x => x.name === status).count--;
      this.statusDetails.find(x => x.name === 'Total').count--;
    },
    isSelected(i) {
      return i === this.selected;
    },
    getMoneyValue(moneyDecimal) {
      return (Math.round(moneyDecimal * 100) / 100).toFixed(2);
    },
    getDate(dateVal) {
      if (dateVal) {
        if (dateVal.indexOf('T') === -1) {
          dateVal = dateVal + 'T00:00:00.000';
        }
        var date = new Date(dateVal);
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var day = date.getDate();
        return zeroPad(month) + '/' + zeroPad(day) + '/' + year;
      }
      return '';

      function zeroPad(numVal) {
        if (numVal < 10) {
          return '0' + numVal;
        }
        return numVal;
      }
    },
    statusDetailsClicked(item) {
      var name = item.name;
      if (name != this.activeRow) {
        if (name === 'Total') {
          this.displayedDmqItems = this.dmqData;
        } else {
          this.displayedDmqItems = this.dmqData.filter(
            x => x.completedStatus === name
          );
        }

        this.selectedPayor = 'All';
        this.displayedDmqItem = null;
        this.selected = -1;
        this.currentPage = 1;
        this.activeRow = name;
        this.totalRows = this.displayedDmqItems.length;
      }
    },
    detailsIsActive(item, type) {
      if (!item || type !== 'row') return;
      if (item.name === this.activeRow)
        return 'overview-currently-selected table-success details-row-class';
      else return 'details-row-class';
    },
    filterItems() {
      this.searchFilter = this.filter.trim();
    },
    async selectItem(deductibleManagementQueueId){
      let item = await appService.getDeductibleMonitoringItem(deductibleManagementQueueId, this.selectedCompany);
      this.updateDisplay(item);
    },
    async selectLoggedInOrFirst() {
      if (this.displayedDmqItems.length > 0) {
        let loggedInApplicant = this.displayedDmqItems.find(
          item => item.applicantId === this.loggedInApplicantId
        );
        
        // If the logged in applicant is in the list, go get the data from the server for the display item
        if (loggedInApplicant) {
          
          // Get the applicant data and update the display
          await this.selectItem(loggedInApplicant.deductibleManagementQueueId);
          
          // Get the index from the list and set the current page within the pagination control
          let loggedInIndex = this.displayedDmqItems.indexOf(loggedInApplicant);
          this.currentPage = parseInt(
            (loggedInIndex + 1) / this.perPage + 1,
            10
          );
          console.log('selectLoggedInOrFirst current page:', this.currentPage);
        } else {
          var firstId = this.displayedDmqItems[0].deductibleManagementQueueId;
          await this.selectItem(firstId);
        }
      }
    },
    clearAllTransactions() {
      var items = this.dmqData.filter(
        x => x.completedStatus === this.clearModal.name
      );
      var ids = items.map(x => x.deductibleManagementQueueId);
      appService.setDMQItemsAsReviewed(ids);
      this.displayedDmqItem = null;
      this.selected = -1;
      this.dmqData = this.dmqData.filter(
        d => ids.indexOf(d.deductibleManagementQueueId) === -1
      );
      this.displayedDmqItems = this.displayedDmqItems.filter(
        d => ids.indexOf(d.deductibleManagementQueueId) === -1      
      );
      this.statusDetails.find(x => x.name === this.clearModal.name).count = this.statusDetails.find(x => x.name === this.clearModal.name).count - ids.length;
      this.statusDetails.find(x => x.name === 'Total').count = this.statusDetails.find(x => x.name === 'Total').count - ids.length;
      this.statusDetails = this.statusDetails.filter(x => x.name != this.clearModal.name);
      this.statusDetailsClicked({ name:'Total' });
    },
    clearAllModal(name, button) {
      this.clearModal.name = name;
      this.$root.$emit('bv::show::modal', 'clear-all-modal', button);
    },
    async init() {
      
      // Load the companies and map to something the ui can understand
      let companySelector = await appService.getCompanyPicker();

      this.companies = companySelector.companies.map(company => {
        return {
          value: company.companyId,
          text: company.companyName
        }
      });

      // Set the selectedCompany and let the watcher load the data
      this.selectedCompany = companySelector.defaultCompanyId;
    }
  },

  async created() {
    await this.init();
  },
};
