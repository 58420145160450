import { READ_ACTIVE_PROFILE } from 'src/store/actions/types';
import { statuses } from 'src/constants/statuses';
import { activeProfileKey } from 'src/constants/localStorage';

/**
 * retrieves the active profile id
 */
export const readActiveProfile = async ({ commit }) => {
  commit(READ_ACTIVE_PROFILE, { status: statuses.pending });
  try {
    commit(READ_ACTIVE_PROFILE, {
      data: localStorage.getItem(activeProfileKey) ?? null,
      status: statuses.success,
    });
  } catch (error) {
    commit(READ_ACTIVE_PROFILE, {
      status: statuses.failure,
      error,
    });
  }
};
