import clone from 'clone';
import { statuses } from 'src/constants/statuses';
import { UPDATE_PROFILE } from 'src/store/actions/types';
import { getProfile } from 'src/store/getters/profiles/getProfile';
import appService from 'src/pl.service';

/**
 * updates a profile's name, layouts and widgets
 */
export const updateProfile = async ({ commit, state }, profile) => {
  // find the profile we need to update in the state
  const uProfile = clone(getProfile(state)(profile.id));
  if (uProfile) {
    uProfile.id = profile.id;
    uProfile.name = profile.name;
    uProfile.layouts = clone(profile.layouts);
    uProfile.widgets = clone(profile.widgets);
    uProfile.expandOptions = clone(profile.expandOptions);
    try {
      // update
      commit(UPDATE_PROFILE, {
        id: profile.id,
        status: statuses.pending,
      });
      await appService.updateProfile(uProfile);
      commit(UPDATE_PROFILE, {
        id: profile.id,
        data: uProfile,
        status: statuses.success,
      });
    } catch (error) {
      commit(UPDATE_PROFILE, {
        id: profile.id,
        status: statuses.failure,
        error,
      });
    }
  }
};
