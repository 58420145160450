/**
 * the list of expand options
 */
export const options = {
  insurance: {
    id: 'A462D0A3-A704-478E-9C31-D4DD880F8479',
    name: 'Insurance',
    expandAll: 'true',
  },
  insuranceDetail: {
    id: 'CCBC2FEE-58C5-460A-B98F-D27787685F74',
    name: 'Insurance detail',
    expandAll: 'true',
  },
  treatment: {
    id: 'C460359B-6B33-42A2-B754-F128B7A8CD22',
    name: 'Treatment',
    expandAll: 'true',
  },
  vitals: {
    id: 'AB765AD1-B6EC-4A62-8F11-48C210422A30',
    name: 'Vitals',
    expandAll: 'true',
  },
};

export const expandOptions = [
  options.insurance,
  options.insuranceDetail,
  options.treatment,
  options.vitals,
];
