import appService from 'src/pl.service';
import footer_view from '../Footer.vue';
export default {

    data(){
        
        return {
            releaseNotes: [],
            visibleSections: []
        };
    }, 
    components:{
      footer_view
    },
    async created() {
        this.releaseNotes = await appService.getReleaseNotes();
      },
      methods: {
        toggle(versionId) {
          const index = this.visibleSections.indexOf(versionId);
          if (index > -1) {
            // If the section is already visible, hide it
            this.visibleSections.splice(index, 1);
          } else {
            // If the section is not visible, show it
            this.visibleSections.push(versionId);
          }
        }
      }
    
}