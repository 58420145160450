import { findCellById } from 'src/components/FullPatientData/PatientWidgetBody/Grid/utils/findCellById';
import { removeCellById } from 'src/components/FullPatientData/PatientWidgetBody/Grid/utils/removeCellById';
import { createCell } from 'src/components/FullPatientData/PatientWidgetBody/Grid/utils/createCell';
import { removeMirrors } from 'src/components/FullPatientData/PatientWidgetBody/Grid/utils/removeMirrors';

/**
 * handles the drop event of a cell
 *
 * @returns {boolean} true when the cell has moved
 */
export const dropHandler = ({ e, column, columns }) => {
  // find the cell that is being dropped
  const { cell: droppedCell, cellIndex, colIndex } = findCellById({
    columns: columns,
    cellId: e.dataTransfer.getData('cellId'),
  });

  // make sure we found the cell
  if (droppedCell) {
    // find where this cell should be moved to and
    // if the column is empty just add it to the top...
    let moveCellIndex = column.cells.length
      ? column.cells.findIndex(
          cell =>
            // the Y is before this cell's top, so add it in its place
            e.pageY < cell.componentInstance?.$el?.offsetTop ||
            // the Y is between this cell's top and mid height
            // so add it in its place
            (e.pageY > cell.componentInstance?.$el?.offsetTop &&
              e.pageY <
                cell.componentInstance?.$el?.offsetTop +
                  cell.componentInstance?.$el?.clientHeight / 2)
        )
      : 0;

    // in case no index was found,
    // it means the user dropped it at the bottom
    if (moveCellIndex === -1) {
      moveCellIndex = column.cells.length;
    }

    // make sure that it actually got moved and not dropped back to where it was
    if (moveCellIndex !== cellIndex || column.id !== colIndex) {
      // remove it from current location
      removeCellById({ columns: columns, cellId: droppedCell.id });

      // also remove its mirrors
      removeMirrors({ columns: columns, cellId: droppedCell.id });

      // move it to the new one
      column.cells.splice(
        moveCellIndex,
        0,
        createCell({
          type: droppedCell.type,
          widget: {
            component: droppedCell.component,
            colSpan: droppedCell.colSpan,
            meta: droppedCell.meta,
          },
        })
      );

      return true;
    }
  }

  return false;
};
