import { decode } from 'jsonwebtoken';
import { bearerTokenKey } from 'src/constants/localStorage';
import { zLogRocket } from '@zoll/logrocket-vue2-lib';

/**
 * if the bearerToken is available,
 * it sets the logRocket identifier
 * so that we can find this session using the user's id
 *
 * @see https://docs.logrocket.com/reference#identify
 */
export const identify = () => {
  const bearerToken = window.localStorage.getItem(bearerTokenKey);
  if (bearerToken) {
    // make sure we don't fail in case the JWT has changed or is invalid
    try {
      const decoded = decode(bearerToken);
      zLogRocket.instance.identify(decoded.userId, {
        name: decoded.username,
        roleId: decoded.roleId,
        companyId: decoded.companyId,
      });
    } catch (e) {
      // just leave this session as anonymous
      console.warn(e, 'could not identify user in LogRocket');
    }
  }
};
