import appService from 'src/pl.service';
import { statuses } from 'src/constants/statuses';
import { RUN_INSURANCE_DISCOVERY } from 'src/store/actions/types';

/**
 * Runs insurane discovery and commits the results to the state
 * using the RUN_INSURANCE_DISCOVERY action type.
 */
export const runInsuranceDiscovery = async ({ commit, state }, applicantId) => {
  try {
    commit(RUN_INSURANCE_DISCOVERY, {
      status: statuses.pending,
    });
    const applicantVisit = state.applicant.visits?.data[0];
    var npi = undefined;
    if (applicantVisit.data && applicantVisit.data.npi) {
      npi = applicantVisit.data.npi;
    }
    var success = await appService.runInsuranceDiscovery(
      applicantId ?? state.applicant.id,
      npi
    );
    commit(RUN_INSURANCE_DISCOVERY, {
      status: success ? statuses.success : statuses.failure,
    });
  } catch (error) {
    commit(RUN_INSURANCE_DISCOVERY, {
      status: statuses.failure,
      error,
    });
  }
};
