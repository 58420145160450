import 'src/utils/zLogRocket/patch';
import 'src/utils/zLogRocket/initialize';
import { zLogRocket } from '@zoll/logrocket-vue2-lib';
import Vue from 'vue';
import App from './App.vue';
import router from 'src/router';
import store from 'src/store';
import VueSwal from 'vue-swal';
import VTooltip from 'v-tooltip';
import VueClipboard from 'vue-clipboard2';
import Snotify from 'vue-snotify';
import 'vue-snotify/styles/material.css';
import BootstrapVue from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Multiselect from 'vue-multiselect';
import * as VueGoogleMaps from 'vue2-google-maps';
import { identify } from 'src/utils/zLogRocket/identify';

// start logging vue specific warnings and errors to LogRocket
// @see https://vuejs.org/v2/api/#errorHandler
zLogRocket.utilities.logWarnError(Vue);

// identify the user in LogRocket
identify();

Vue.mixin({
  methods: {
    dateStr: function(dateVal) {
      if (dateVal) {
        if (dateVal.indexOf('T') === -1) {
          dateVal = dateVal + 'T00:00:00.000';
        }
        var date = new Date(dateVal);
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var day = date.getDate();
        return zeroPad(month) + '/' + zeroPad(day) + '/' + year;
      }
      return '';

      function zeroPad(numVal) {
        if (numVal < 10) {
          return '0' + numVal;
        }
        return numVal;
      }
    },
    dateTimeStr: function(dateVal) {
      if (dateVal) {
        var date = new Date(dateVal);
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var day = date.getDate();
        var hour = date.getHours();
        var minute = date.getMinutes();
        return (
          zeroPad(month) +
          '/' +
          zeroPad(day) +
          '/' +
          year +
          ' ' +
          zeroPad(hour) +
          ':' +
          zeroPad(minute)
        );
      }
      return '';

      function zeroPad(numVal) {
        if (numVal < 10) {
          return '0' + numVal;
        }
        return numVal;
      }
    },
    currencyStr: function(currencyVal) {
      if (currencyVal || currencyVal === 0) {
        return '$' + currencyVal.toFixed(2);
      }
      return '';
    },
    phoneStr: function(phoneNum) {
      if (phoneNum && phoneNum.length > 0) {
        return phoneNum
          .replace(/[^0-9]/g, '')
          .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
      }
      return phoneNum;
    },
    ssnWithDashesStr: function(ssn) {
      if (ssn && ssn.length === 9) {
        return (
          ssn.substring(0, 3) +
          '-' +
          ssn.substring(3, 5) +
          '-' +
          ssn.substring(5, 9)
        );
      }
      return ssn;
    },
    getParentByName(name) {
      let p = this.$parent;
      while (typeof p !== 'undefined') {
        if (p.$options.name === name) {
          return p;
        } else {
          p = p.$parent;
        }
      }
      return null;
    },
    displayErrorToast(message) {
      console.error('displayErrorToast', message);
      this.$snotify.error(message, 'Error', {
        timeout: 5000,
        showProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        position: 'rightTop',
      });
    },
    displaySuccessToast(message) {
      console.log('displaySuccessToast', message);
      this.$snotify.success(message, 'Success', {
        timeout: 5000,
        showProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        position: 'rightTop',
      });
    },
  },
});

Vue.config.productionTip = false;
Vue.use(VueSwal);
Vue.use(VTooltip);
//Needed to allow copying inside of modals
VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);
Vue.use(Snotify);
Vue.use(BootstrapVue);
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBpNpKMfjULLT_OFokGYPGFFsgV-xjH51o',
    libraries: 'places,drawing,visualization', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    //// If you want to set the version, you can do so:
    // v: '3.26',
  }, //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,

  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then set installComponents to 'false'.
  //// If you want to automatically install all the components this property must be set to 'true':
  installComponents: true,
});

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('multiselect', Multiselect);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
