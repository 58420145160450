<template>
  <div>
    <router-view />
    <vue-snotify />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { SET_APPLICANT_ID } from 'src/store/actions/types';

export default {
  name: 'App',
  methods: {
    ...mapActions([SET_APPLICANT_ID])
  },
  async created() {
    // update the applicant id in the store
    this[SET_APPLICANT_ID](this.$route.params.id);

    // if the "getDsConfig" fails (ie. the URL "/ds.config.json" is not reachable, not found, etc.)
    // the returned dsConfig object will be empty and the configuration will default to the values
    // that are already defined. Those values should be for production, so that in case of a failure,
    // it always defaults to using production.
    // apply configuration overrides


  },
};
</script>
