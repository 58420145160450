/**
 * mutates the applicant.eligibilities.data property with the data given, as-is
 */
export const getPayorEligibilities = (
  state,
  { data = [], errors = [], loading, serviceTypes = [], retrieved = false }
) => {
  state.applicant.eligibilities.loading = loading;
  state.applicant.eligibilities.retrieved = retrieved;
  state.applicant.eligibilities.data = data;
  state.applicant.eligibilities.serviceTypes = serviceTypes;
  state.applicant.eligibilities.errors = errors;
};
