import { widgets } from 'src/constants/widgets';

/**
 * finds and returns the constant widget object,
 * that has the widget type provided.
 *
 * @param {string} type
 * @returns {object | undefined} object if found, undefined otherwise
 */
export const getWidgetByType = type => {
  const widget = Object.entries(widgets).find(
    keyValue => keyValue[1].type === type
  );
  if (widget) {
    return widget[1];
  }

  return undefined;
};
