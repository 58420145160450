import { SET_PCR_PADDR_VALIDATED_ADDRESS } from 'src/store/actions/types';
import appService from 'src/pl.service';
import { statuses } from 'src/constants/statuses';

/**
 * commits the id to the store using the SET_PCR_PADDR_VALIDATED_ADDRESS action type
 */
export const setPcrPaddrValidatedAddress = async ({ commit, state }) => {
  try {
    var validatedAddress = null;
    if (state.applicant.epcrJsonData && state.applicant.epcrJsonData.paddr) {
      validatedAddress = await appService.getValidatedAddress(
        state.applicant.epcrJsonData.paddr ?? '',
        state.applicant.epcrJsonData.paddr2 ?? '',
        state.applicant.epcrJsonData.pcity ?? '',
        state.applicant.epcrJsonData.pstate ?? '',
        state.applicant.epcrJsonData.pzip ?? ''
      );
    }
    if (validatedAddress) {
      commit(SET_PCR_PADDR_VALIDATED_ADDRESS, {
        validatedAddress: validatedAddress,
        status: 'success',
      });
    }
  } catch (error) {
    commit(SET_PCR_PADDR_VALIDATED_ADDRESS, {
      status: statuses.failure,
      error,
    });
  }
};
