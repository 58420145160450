import clone from 'clone';
import { getActiveProfile } from 'src/store/getters/profiles/getActiveProfile';

/**
 * resets the active profile's widgets/layouts
 */
export const resetProfile = (state, { data, status, error }) => {
  const profile = getActiveProfile(state);
  // make sure the profile exists
  if (profile) {
    if (data) {
      profile.widgets = clone(data.widgets);
      profile.layouts = clone(data.layouts);
      profile.expandOptions = clone(data.expandOptions);
    }
    profile.operations.reset = {
      status,
      error,
    };
  }
};
