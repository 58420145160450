<template>
  <div class="bootstrap">
    <b-card no-body>
      <b-container fluid>
        <!-- User Interface controls -->
        <b-row>
          <b-col>
            <b-form-group label-cols-sm="2" label="Filter" class="mb-0">
              <b-input-group>
                <b-form-input
                  v-model="filter"
                  placeholder="Type to Search"
                ></b-form-input>
                <b-input-group-append>
                  <b-button variant="primary" :disabled="!filter" @click="filter = ''"
                    >Clear</b-button
                  >
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label-cols-sm="2" label="Per page" class="mb-0">
              <b-form-select
                v-model="perPage"
                :options="pageOptions"
              ></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <br />

        <!-- Main table element -->
        <b-table
          show-empty
          stacked="md"
          :items="items"
          :fields="fields"
          :current-page="currentPage"
          :per-page="perPage"
          :filter="filter"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          @filtered="onFiltered"
        >
          <template #cell(actions)="row">
            <b-button
              size="sm"
              @click="test(row.item, row.index, $event.target)"
            >
              <b-spinner v-if="row.item.testing" small></b-spinner>Test
            </b-button>
          </template>
        </b-table>

        <b-row>
          <b-col md="6" class="my-1">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              class="my-0"
            ></b-pagination>
          </b-col>
        </b-row>
      </b-container>
    </b-card>
  </div>
</template>

<script>
import appService from 'src/pl.service';

export default {
  data() {
    return {
      items: null,
      fields: [
        {
          key: 'companyPayorId',
          label: 'CompanyPayorId',
          sortable: true,
          sortDirection: 'desc',
        },
        { key: 'companyPayorName', label: 'CompanyPayorName', sortable: true },
        { key: 'companyId', label: 'CompanyId', sortable: true },
        { key: 'payorId', label: 'PayorId', sortable: true },
        { key: 'actions', label: 'Actions' },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 100],
      sortBy: null,
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key };
        });
    },
  },
  async mounted() {
    console.log('mounted');
    //his.fields = this.getFieldList();

    var plCompanyPayors = await appService.getListOfCompanyPayorsWithScreenScrapeEnabled();
    for (var j = 0; j < plCompanyPayors.length; j++) {
      plCompanyPayors[j]._rowVariant = 'warning';
      plCompanyPayors[j].testing = false;
    }
    this.items = plCompanyPayors;
    this.totalRows = this.items.length;
  },
  methods: {
    getFieldList() {
      return [
        {
          key: 'companyPayorId',
          label: 'CompanyPayorId',
          sortable: true,
          sortDirection: 'desc',
        },
        { key: 'companyPayorName', label: 'CompanyPayorName', sortable: true },
        { key: 'companyId', label: 'CompanyId', sortable: true },
        { key: 'payorId', label: 'PayorId', sortable: true },
        { key: 'actions', label: 'Actions' },
      ];
    },
    async test(item, index) {
      console.log('test', item, index);
      item.testing = true;
      var result = await appService.testScreenScrapeForCompanyPayor(
        item.companyPayorId
      );
      if (result.loginSuccess) {
        item._rowVariant = 'success';
      } else {
        item._rowVariant = 'danger';
      }
      item.testing = false;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
