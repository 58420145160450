import { zLogRocket } from '@zoll/logrocket-vue2-lib';

/**
 * In this file, we can customize the sanitizers.
 *
 * Meaning, which property strings should get sanitized,
 * which should not and even for which type of data,
 * (ie. network request, vuex mutation, etc.).
 *
 * @returns customized sanitizers
 */
export const customizeSanitizers = () => {
  // create a fresh object of sanitizers for customization
  const sanitizers = zLogRocket.utilities.createSanitizers();

  /**
   * Starting with the catch-all sanitizer.
   * sanitizers.anywhere - sanitizes properties, that may be located anywhere.
   *
   * This includes but not limited to the:
   *  - parsed json body of network requests/responses
   *  - headers of network requests/responses
   *  - url query parameters of network requests/responses
   *  - vuex mutation payloads
   *  - redux action payloads
   *  - console log/warn/info/debug/error
   */

  /**
   * list of properties to sanitize,
   * when they have an exact and case sensitive match
   */
  sanitizers.anywhere.sanitizeExactMatch = ['Authorization'];

  /**
   * list of properties to sanitize,
   * when they have a partial and case insensitive match
   */
  sanitizers.anywhere.sanitizeFuzzyMatch = [
    'ssn',
    'name',
    'middle',
    'phone',
    'birth',
    'dob',
    'addr',
    'zip',
    'city',
    'state',
    'email',
    'address',
    'race',
    'gender',
    'employer',
  ];

  /**
   * list of properties to ignore and not sanitize,
   * when they are recursively, exact and case sensitive matched
   */
  sanitizers.anywhere.ignoreExactMatch = [];

  return sanitizers;
};
