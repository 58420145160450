import { SET_BILLING_MAILING_VALIDATED_ADDRESS } from 'src/store/actions/types';
import appService from 'src/pl.service';
import { statuses } from 'src/constants/statuses';

/**
 * commits the id to the store using the SET_BILLING_MAILING_VALIDATED_ADDRESS action type
 */
export const setBillingMailingValidatedAddress = async ({ commit, state }) => {
  try {
    var validatedAddress = null;
    if (state.applicant.data && state.applicant.data.mailingAddress) {
      validatedAddress = await appService.getValidatedAddress(
        state.applicant.data.mailingAddress.address1 ?? '',
        state.applicant.data.mailingAddress.address2 ?? '',
        state.applicant.data.mailingAddress.city ?? '',
        state.applicant.data.mailingAddress.state ?? '',
        state.applicant.data.mailingAddress.zip ?? ''
      );
    }
    if (validatedAddress) {
      commit(SET_BILLING_MAILING_VALIDATED_ADDRESS, {
        validatedAddress: validatedAddress,
        status: 'success',
      });
    }
  } catch (error) {
    commit(SET_BILLING_MAILING_VALIDATED_ADDRESS, {
      status: statuses.failure,
      error,
    });
  }
};
