/**
 * mutates the guarantor.data property with the data given
 */
import clone from 'clone';
import { statuses } from 'src/constants/statuses';
export const getGuarantor = (state, {data, demographic, status}) => {
    if(status === statuses.success)
    {
      if(data !== 'undefined' &&  data !== null)
      {
        state.guarantor.data = clone(data);
      }
      if(demographic !== 'undefined' &&  demographic !== null)
      {
        state.guarantor.demographic.data = clone(demographic);
      }
  }
};