import clone from 'clone';
import { operations } from 'src/constants/operations';
import { getProfile } from 'src/store/getters/profiles/getProfile';

/**
 * pushes to the profiles.data
 */
export const createProfile = (state, { data, id, status, error }) => {
  // do we need to add this profile to the store or
  // just update its id/status
  let profile = getProfile(state)(id);
  if (!profile) {
    profile = {
      id,
      name: '',
      layouts: [],
      widgets: [],
      expandOptions: [],
      operations: {
        reset: {},
        [operations.create]: {},
        [operations.update]: {},
        [operations.delete]: {},
      },
    };
    state.profiles.data.push(profile);
  }

  // when data is provided (should be upon success)
  // add that to the profile
  if (data) {
    profile.id = data.id;
    profile.name = data.name;
    profile.layouts = clone(data.layouts);
    profile.widgets = clone(data.widgets);
    profile.expandOptions = clone(data.expandOptions);
  }

  // always update status/error
  profile.operations[operations.create] = {
    status,
    error,
  };
};
