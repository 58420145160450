import { cellTypes } from 'src/constants/grid';
import { createCell } from 'src/components/FullPatientData/PatientWidgetBody/Grid/utils/createCell';

/**
 * parses the widgets in each column and creates the widget mirrors required
 */
export const createMirrors = ({ normalCellsMounted, columns }) => {
  // make sure all the cells have been mounted before proceeding
  if (!normalCellsMounted) {
    return;
  }

  columns.forEach((column, columnIndex) => {
    column.cells
      .filter(cell => cell.type === cellTypes.normal.type)
      .forEach(cell => {
        const cellEl = cell.componentInstance.$el;
        // if this widget requires a column span of 2 and
        // there is another column available to the right and
        // there is not another mirror for this cell already there,
        // then add it...
        if (
          cell.colSpan === 2 &&
          columns.length > columnIndex + 1 &&
          !columns[columnIndex + 1].cells.find(c => c.meta.sourceId === cell.id)
        ) {
          // grab the next column cell's elements
          const nextColumnCellEls = document.querySelectorAll(
            `.column[data-index="${columnIndex + 1}"] > .wrapper > .cell`
          );

          // find the proper index to push the widget to,
          // depending on the round of the height > < 50% of the next column widget intersection
          const addCellIndex = Array.from(nextColumnCellEls).findIndex(
            nextColWidgetEl =>
              nextColWidgetEl.offsetTop + nextColWidgetEl.clientHeight / 2 >
              cellEl.offsetTop
          );

          // add the mirror cell to the next column, at the right cell index
          columns[columnIndex + 1].cells.splice(
            addCellIndex !== -1 ? addCellIndex : nextColumnCellEls.length,
            0,
            createCell({
              type: cellTypes.mirror.type,
              meta: { sourceId: cell.id },
            })
          );
        }
      });
  });
};
