import { widgets } from './widgets/index';
import { applicant } from './applicant/index';
import { permissions } from './permissions/index';
import { profiles } from './profiles/index';
import { alsConfig } from './alsConfig/index';

export const actions = {
  ...applicant,
  ...widgets,
  ...permissions,
  ...profiles,
  ...alsConfig,
};
