import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from 'src/components/Login';
import QuickLogin from 'src/components/QuickLogin';
import Directions from 'src/components/Directions';
import FullPatientData from 'src/components/FullPatientData/FullPatientData';
import Admin from 'src/components/Admin';
import ScreenScrapeAdmin from 'src/components/ScreenScrapeAdmin';
import BulkSubmissionQueueDashboard from 'src/components/BulkSubmissionQueueDashboard';
import TransactionReconciliationReport from 'src/components/TransactionReconciliationReport';
import ClaimsClarity from 'src/components/ClaimsClarity/ClaimsClarity';
import CacheDashboard from 'src/components/CacheDashboard/CacheDashboard';
import DeductibleMonitoringDashboard from 'src/components/DeductibleMonitoringDashboard/DeductibleMonitoringDashboard';
import ProductDashboard from 'src/components/ProductDashboard/ProductDashboard';
import ReleaseNotes from 'src/components/ReleaseNotes/ReleaseNotes';

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/quickLogin',
    name: 'QuickLogin',
    component: QuickLogin,
  },
  {
    path: '/directions',
    name: 'Directions',
    component: Directions,
  },
  {
    path: '/FullPatientData/:id',
    name: 'FullPatientData',
    component: FullPatientData,
  },
  {
    path: '/admin',
    name: 'Admin',
    component: Admin,
  },
  {
    path: '/ScreenScrapeAdmin',
    name: 'ScreenScrapeAdmin',
    component: ScreenScrapeAdmin,
  },
  {
    path: '/BulkSubmissionQueueDashboard',
    name: 'BulkSubmissionQueueDashboard',
    component: BulkSubmissionQueueDashboard,
  },
  {
    path: '/TransactionReconciliationReport',
    name: 'TransactionReconciliationReport',
    component: TransactionReconciliationReport,
  },
  {
    path: '/ClaimsClarity',
    name: 'ClaimsClarity',
    component: ClaimsClarity,
  },
  {
    path: '/CacheDashboard',
    name: 'CacheDashboard',
    component: CacheDashboard,
  },
  {
    path: '/DeductibleMonitoringDashboard',
    name: 'DeductibleMonitoringDashboard',
    component: DeductibleMonitoringDashboard,
  },
  {
    path: '/ProductDashboard',
    name: 'ProductDashboard',
    component: ProductDashboard,
  },
  {
    path: '/ReleaseNotes',
    name: 'ReleaseNotes',
    component: ReleaseNotes,
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
